import { Theme } from '@emotion/react';
import { Interpolation } from '@emotion/serialize';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
interface LoaderProps {
  $css?: Interpolation<Theme>;
  style?: React.CSSProperties;
}
const Loader = ({
  $css,
  style
}: LoaderProps): JSX.Element => {
  return _jsx("div", {
    css: ["margin-top:1.25rem;height:2.5rem;width:2.5rem;@keyframes spin{to{transform:rotate(360deg);}}animation:spin 1s linear infinite;border-radius:9999px;border-width:5px;--tw-border-opacity:1;border-color:rgb(128 128 128 / var(--tw-border-opacity));border-top-color:rgb(0 0 0 / var(--tw-border-opacity));", $css, process.env.NODE_ENV === "production" ? "" : ";label:Loader;", process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92YXIvbG9jby9sb2NvL2FwcHMvbG9jb2dnL3NyYy9jb21wb25lbnRzL2xvYWRlci9pbmRleC50c3giXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBWU0iLCJmaWxlIjoiL3Zhci9sb2NvL2xvY28vYXBwcy9sb2NvZ2cvc3JjL2NvbXBvbmVudHMvbG9hZGVyL2luZGV4LnRzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCB7IFRoZW1lIH0gZnJvbSAnQGVtb3Rpb24vcmVhY3QnXG5pbXBvcnQgeyBJbnRlcnBvbGF0aW9uIH0gZnJvbSAnQGVtb3Rpb24vc2VyaWFsaXplJ1xuaW1wb3J0IHR3IGZyb20gJ3R3aW4ubWFjcm8nXG5cbmludGVyZmFjZSBMb2FkZXJQcm9wcyB7XG4gICRjc3M/OiBJbnRlcnBvbGF0aW9uPFRoZW1lPlxuICBzdHlsZT86IFJlYWN0LkNTU1Byb3BlcnRpZXNcbn1cblxuY29uc3QgTG9hZGVyID0gKHsgJGNzcywgc3R5bGUgfTogTG9hZGVyUHJvcHMpOiBKU1guRWxlbWVudCA9PiB7XG4gIHJldHVybiAoXG4gICAgPGRpdlxuICAgICAgY3NzPXtbXG4gICAgICAgIHR3YGFuaW1hdGUtc3BpbiB3LTEwIGgtMTAgbXQtNSByb3VuZGVkLWZ1bGwgYm9yZGVyLVs1cHhdIGJvcmRlci1bIzgwODA4MF0gYm9yZGVyLXQtYmxhY2tgLFxuICAgICAgICAkY3NzLFxuICAgICAgXX1cbiAgICAgIHN0eWxlPXtzdHlsZX1cbiAgICA+PC9kaXY+XG4gIClcbn1cblxuZXhwb3J0IGRlZmF1bHQgTG9hZGVyXG4iXX0= */"],
    style: style
  });
};
export default Loader;