function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { useDeviceInfo } from '@hooks/react-use';
import { useStreamStore } from '@stores/streamStore';
import React from 'react';
import { useControlDisplayStore } from '../store/useControlDisplayStore';
import { usePlayerStore } from '../store/usePlayerStore';
import StreamEnded from './Common/StreamEnded';
import FullScreenElement from './ControlSheetSection/FullScreenElement';
import MiniPlayerToogle from './ControlSheetSection/MiniPlayerToggle';
import MoveMiniPlayer from './ControlSheetSection/MoveMiniPlayer';
import SeekbarControls from './ControlSheetSection/SeekbarControls';
import ShowStreamTime from './ControlSheetSection/ShowStreamTime';
import TheatreMode from './ControlSheetSection/TheatreMode';
import PlayerSettingComp from './PlayerSettingComp';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const {
  setIsHoverOnContainer,
  resetControlSectionVisiblity
} = useControlDisplayStore.getState();
const {
  setIsPortalOpen
} = usePlayerStore.getState();
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1vro5xw",
  styles: "position:absolute;z-index:10;display:flex;height:100%;width:100%;align-items:center;justify-content:center;padding-left:1rem;padding-right:1rem;transition-property:all;transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transition-duration:150ms"
} : {
  name: "11g78me-StreamEndedOutside",
  styles: "position:absolute;z-index:10;display:flex;height:100%;width:100%;align-items:center;justify-content:center;padding-left:1rem;padding-right:1rem;transition-property:all;transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transition-duration:150ms;label:StreamEndedOutside;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "nrwq21",
  styles: "position:absolute;z-index:-1;height:100%;width:100%;object-fit:cover"
} : {
  name: "2k5pp3-StreamEndedOutside",
  styles: "position:absolute;z-index:-1;height:100%;width:100%;object-fit:cover;label:StreamEndedOutside;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "yoovvv",
  styles: "position:absolute;left:0.75rem;right:0.75rem;top:0.75rem;z-index:100;display:flex;align-items:center;justify-content:space-between;border-radius:0.375rem"
} : {
  name: "160os51-StreamEndedOutside",
  styles: "position:absolute;left:0.75rem;right:0.75rem;top:0.75rem;z-index:100;display:flex;align-items:center;justify-content:space-between;border-radius:0.375rem;label:StreamEndedOutside;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "1wnowod",
  styles: "display:flex;align-items:center;justify-content:center"
} : {
  name: "us8fqc-StreamEndedOutside",
  styles: "display:flex;align-items:center;justify-content:center;label:StreamEndedOutside;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "dopfyw",
  styles: "padding-left:0.5rem;padding-bottom:0.25rem;padding-top:0.125rem;padding-right:0.25rem"
} : {
  name: "16fn66y-StreamEndedOutside",
  styles: "padding-left:0.5rem;padding-bottom:0.25rem;padding-top:0.125rem;padding-right:0.25rem;label:StreamEndedOutside;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "66jz0d",
  styles: "height:1.25rem;width:1.25rem;min-width:20px"
} : {
  name: "1pj0uqb-StreamEndedOutside",
  styles: "height:1.25rem;width:1.25rem;min-width:20px;label:StreamEndedOutside;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const StreamEndedOutside = () => {
  const {
    screen_isDesktop
  } = useDeviceInfo();
  const isPortalOpen = usePlayerStore(state => state.isPortalOpen);
  const {
    theatreMode,
    isFullScreen
  } = useStreamStore(state => ({
    isFullScreen: state.isFullScreen,
    theatreMode: state.theatreMode
  }));
  const stream = useStreamStore(state => state.stream);
  const thumbnail_url_small = stream?.thumbnail_url_small || '';
  const isVisible = true;
  return _jsx("div", {
    className: "player-section",
    children: _jsxs("div", {
      className: "video-container",
      onMouseMove: () => {
        if (!theatreMode && !isFullScreen) {
          if (screen_isDesktop) {
            setIsHoverOnContainer(true);
          } else {
            resetControlSectionVisiblity();
          }
        } else {
          // For theatre mode & full screen mode
          resetControlSectionVisiblity();
        }
      },
      onMouseLeave: () => setIsHoverOnContainer(false),
      children: [_jsxs("div", {
        css: _ref,
        style: {
          opacity: isVisible ? 1 : 0
        },
        children: [thumbnail_url_small ? _jsx("img", {
          css: _ref2,
          src: thumbnail_url_small,
          alt: "Stream has Ended"
        }) : _jsx(_Fragment, {}), _jsx(StreamEnded, {})]
      }), isPortalOpen ? _jsxs(_Fragment, {
        children: [_jsx(MoveMiniPlayer, {}), _jsxs("div", {
          css: _ref3,
          children: [_jsx(MiniPlayerToogle, {}), _jsx("div", {
            css: _ref4,
            children: _jsx("button", {
              className: "mini-player-exit",
              onPointerDown: () => {
                setIsPortalOpen(false, true);
              },
              css: _ref5,
              children: _jsx("img", {
                src: "/new_close_icon.png",
                alt: "close",
                css: _ref6
              })
            })
          })]
        })]
      }) : _jsxs("div", {
        className: "player-controls-container",
        style: {
          transform: isVisible ? 'translateY(0)' : 'translateY(50%)',
          opacity: !!isVisible ? 1 : 0,
          visibility: !!isVisible ? 'visible' : 'hidden'
        },
        children: [_jsxs("div", {
          className: "player-controls-wrapper player-setting-wrapper",
          children: [_jsxs("div", {
            className: "player-controls-btn-container player-main-control",
            children: [_jsx("button", {
              className: "ivs-replay-button",
              onClick: () => {
                useStreamStore.getState().updatePlaybackUrl(true);
              },
              children: _jsx("svg", {
                width: "20",
                height: "20",
                viewBox: "0 0 24 24",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
                children: _jsx("path", {
                  d: "M18.2577 3.50828C18.538 3.62437 18.7207 3.89785 18.7207 4.20119V8.44383C18.7207 8.85805 18.3849 9.19383 17.9707 9.19383H13.728C13.4247 9.19383 13.1512 9.0111 13.0351 8.73085C12.9191 8.45059 12.9832 8.128 13.1977 7.9135L14.8007 6.3105C12.1674 5.20912 9.01606 5.7309 6.87348 7.87348C4.04217 10.7048 4.04217 15.2952 6.87348 18.1265C9.70478 20.9578 14.2952 20.9578 17.1265 18.1265C18.7727 16.4803 19.4622 14.2401 19.1935 12.0937C19.142 11.6827 19.4335 11.3078 19.8445 11.2563C20.2555 11.2049 20.6304 11.4963 20.6819 11.9073C21.0057 14.4934 20.1746 17.1997 18.1872 19.1872C14.7701 22.6043 9.2299 22.6043 5.81282 19.1872C2.39573 15.7701 2.39573 10.2299 5.81282 6.81282C8.55119 4.07444 12.6515 3.5312 15.9309 5.18028L17.4404 3.67086C17.6549 3.45637 17.9774 3.3922 18.2577 3.50828Z",
                  fill: "white"
                })
              })
            }), _jsx(ShowStreamTime, {})]
          }), _jsxs("div", {
            className: "player-controls-btn-container player-setting",
            children: [_jsx(PlayerSettingComp, {
              disabled: true
            }), _jsx(MiniPlayerToogle, {
              disabled: true
            }), _jsx(TheatreMode, {}), _jsx(FullScreenElement, {})]
          })]
        }), _jsx(SeekbarControls, {
          disabled: true
        })]
      })]
    })
  });
};
export default StreamEndedOutside;