import localFont from '@next/font/local'

// Doc: https://docs.google.com/document/d/185xhOhpjumsIYtjxjLDWl5I5riXnlOJOE7YurHJyP_4/edit
// const ALL_FONT_PREFIXES = [
//   { name: 'SuisseIntl-Ultralight', weight: '100' },
//   { name: 'SuisseIntl-Light', weight: '200' },
//   { name: 'SuisseIntl-Book', weight: '300' },
//   { name: 'SuisseIntl-Regular', weight: '400' },
//   { name: 'SuisseIntl-Medium', weight: '500' },
//   { name: 'SuisseIntl-SemiBold', weight: '600' },
//   { name: 'SuisseIntl-Bold', weight: '700' },
//   { name: 'SuisseIntl-Black', weight: '900' },

//   // { name: 'SuisseIntl-Thin', weight: '' },
// ]
// const FONT_SIZES = ['WebS', 'WebM', 'WebXL']

// Class Properties
// font-thin	font-weight: 100;
// font-extralight	font-weight: 200;
// font-light	font-weight: 300;
// font-normal	font-weight: 400;
// font-medium	font-weight: 500;
// font-semibold	font-weight: 600;
// font-bold	font-weight: 700;
// font-extrabold	font-weight: 800;
// font-black	font-weight: 900;

const SuisseeIntlFont = localFont({
  src: [
    {
      path: './SuisseIntl/SuisseIntl-Ultralight-WebM.woff2',
      weight: '100',
      style: 'normal',
    },
    {
      path: './SuisseIntl/SuisseIntl-UltralightItalic-WebM.woff2',
      weight: '100',
      style: 'italic',
    },
    {
      path: './SuisseIntl/SuisseIntl-Light-WebM.woff2',
      weight: '200',
      style: 'normal',
    },
    {
      path: './SuisseIntl/SuisseIntl-LightItalic-WebM.woff2',
      weight: '200',
      style: 'italic',
    },
    {
      path: './SuisseIntl/SuisseIntl-Book-WebM.woff2',
      weight: '300',
      style: 'normal',
    },
    {
      path: './SuisseIntl/SuisseIntl-BookItalic-WebM.woff2',
      weight: '300',
      style: 'italic',
    },
    {
      path: './SuisseIntl/SuisseIntl-Regular-WebM.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: './SuisseIntl/SuisseIntl-RegularItalic-WebM.woff2',
      weight: '400',
      style: 'italic',
    },
    {
      path: './SuisseIntl/SuisseIntl-Medium-WebM.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: './SuisseIntl/SuisseIntl-MediumItalic-WebM.woff2',
      weight: '500',
      style: 'italic',
    },
    {
      path: './SuisseIntl/SuisseIntl-SemiBold-WebM.woff2',
      weight: '600',
      style: 'normal',
    },
    {
      path: './SuisseIntl/SuisseIntl-SemiBoldItalic-WebM.woff2',
      weight: '600',
      style: 'italic',
    },
    {
      path: './SuisseIntl/SuisseIntl-Bold-WebM.woff2',
      weight: '700',
      style: 'normal',
    },
    {
      path: './SuisseIntl/SuisseIntl-BoldItalic-WebM.woff2',
      weight: '700',
      style: 'italic',
    },
    {
      path: './SuisseIntl/SuisseIntl-Black-WebM.woff2',
      weight: '900',
      style: 'normal',
    },
    {
      path: './SuisseIntl/SuisseIntl-BlackItalic-WebM.woff2',
      weight: '900',
      style: 'italic',
    },
  ],
  preload: true,
  display: 'swap',
  variable: '--font-suisse',
})

export default SuisseeIntlFont
