function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { usePlayerStore } from '@components/VideoPlayer/store/usePlayerStore';
import { formatTime } from '@components/VideoPlayer/Utils/utils';
import React from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1am8rud",
  styles: "display:flex;align-items:center;justify-content:center;gap:0.25rem;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity))"
} : {
  name: "1ef1l0z-ShowStreamTime",
  styles: "display:flex;align-items:center;justify-content:center;gap:0.25rem;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));label:ShowStreamTime;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const ShowStreamTime = () => {
  const playerDuration = usePlayerStore(state => state.playerDuration);
  const playerPosition = usePlayerStore(state => state.playerPosition);
  const stream = usePlayerStore(state => state.playerProps.stream);
  const isChannelLive = stream?.status === 20;
  return _jsx(_Fragment, {
    children: _jsxs("div", {
      css: _ref,
      children: [_jsx("p", {
        className: "time-since-live player-position",
        children: formatTime(playerPosition)
      }), isChannelLive ? _jsx(_Fragment, {}) : _jsxs(_Fragment, {
        children: [_jsx("p", {
          className: "time-since-live",
          children: "/"
        }), _jsx("p", {
          className: "time-since-live player-duration",
          children: formatTime(playerDuration)
        })]
      })]
    })
  });
};
export default ShowStreamTime;