export interface StreamData {
  stream_uid: string;
  has_mature_content: boolean | null;
  has_mature_content_updated_at: string | null;
  user_consent: boolean;
  last_updated: number;
  last_stream_config_change_time: string | null; // New property
}

type Listener = (streamData: StreamData | null) => void;
class StreamStorage {
  private storageKey = 'stream_mature_content_data';
  private maxEntries = 50;
  private listeners: Map<string, Set<Listener>> = new Map();
  private notifyListeners(stream_uid: string, data: StreamData | null): void {
    const streamListeners = this.listeners.get(stream_uid);
    if (streamListeners) {
      streamListeners.forEach(listener => listener(data));
    }
  }
  getStoredData(): StreamData[] {
    const storedData = localStorage.getItem(this.storageKey);
    return storedData ? JSON.parse(storedData) : [];
  }
  addListener(stream_uid: string, listener: Listener): void {
    if (!this.listeners.has(stream_uid)) {
      this.listeners.set(stream_uid, new Set());
    }
    this.listeners.get(stream_uid)!.add(listener);
  }
  removeListener(stream_uid: string, listener: Listener): void {
    const streamListeners = this.listeners.get(stream_uid);
    if (streamListeners) {
      streamListeners.delete(listener);
      if (streamListeners.size === 0) {
        this.listeners.delete(stream_uid);
      }
    }
  }
  private setStoredData(data: StreamData[]): void {
    localStorage.setItem(this.storageKey, JSON.stringify(data));
  }
  updateStreamData(newData: Partial<StreamData>): void {
    let storedData = this.getStoredData();
    const existingIndex = storedData.findIndex(item => item.stream_uid === newData.stream_uid);
    const updatedData: StreamData = ({
      ...newData,
      stream_uid: newData.stream_uid!,
      last_updated: Date.now()
    } as StreamData);
    if (existingIndex !== -1) {
      const existingData = storedData[existingIndex];
      // Update existing data, preserving user_consent and only updating mature content if necessary
      storedData[existingIndex] = {
        ...existingData,
        ...updatedData,
        user_consent: existingData.user_consent,
        has_mature_content: newData.has_mature_content === true,
        has_mature_content_updated_at: newData.has_mature_content_updated_at || existingData.has_mature_content_updated_at,
        last_stream_config_change_time: newData.last_stream_config_change_time || existingData.last_stream_config_change_time
      };
    } else {
      // Add new data
      updatedData.user_consent = false;
      storedData.push(updatedData);
    }

    // Sort by last_updated (most recent first) and limit to maxEntries
    storedData = storedData.sort((a, b) => b.last_updated - a.last_updated).slice(0, this.maxEntries);
    this.setStoredData(storedData);
    this.notifyListeners(newData.stream_uid!, this.getStreamData(newData.stream_uid!));
  }
  getUserConsent(stream_uid: string): boolean {
    const storedData = this.getStoredData();
    const streamData = storedData.find(item => item.stream_uid === stream_uid);
    return streamData ? streamData.user_consent : false;
  }
  setUserConsent(stream_uid: string, consent: boolean): void {
    const storedData = this.getStoredData();
    const streamIndex = storedData.findIndex(item => item.stream_uid === stream_uid);
    if (streamIndex !== -1) {
      storedData[streamIndex].user_consent = consent;
      storedData[streamIndex].last_updated = Date.now();
      this.setStoredData(storedData);
      this.notifyListeners(stream_uid, this.getStreamData(stream_uid));
    }
  }
  getStreamData(stream_uid: string): StreamData | null {
    const storedData = this.getStoredData();
    return storedData.find(item => item.stream_uid === stream_uid) || null;
  }
  clearStoredData(): void {
    localStorage.removeItem(this.storageKey);
    this.listeners.forEach((listeners, stream_uid) => {
      this.notifyListeners(stream_uid, null);
    });
    this.listeners.clear();
  }
}
export const streamStorage = new StreamStorage();