function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { enterFullScreen, exitFullScreen, getFullScreen } from '@components/VideoPlayer/Utils/utils';
import { streamAgeVerification } from '@functions/stream';
import { useDeviceInfo } from '@hooks/react-use';
import { getSourceEventProperties } from '@layouts/stream/helpers';
import LogEvents from '@layouts/stream/LogEvents';
import { Amplitude } from '@modules/analytics';
import { PAGES_ROUTE } from '@modules/analytics/types/constants';
import { useAppStore } from '@stores/appStore';
import { useAuthStore } from '@stores/authStore';
import { streamStorage } from '@stores/localStreamStorage';
import { useStreamStore } from '@stores/streamStore';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { usePrevious } from 'react-use';
import * as workerTimers from 'worker-timers';
import { useStreamStorage } from '../../stores/useStreamStorage';
import { ControlSheetSection, InitializeVideoPlayer, KeyboardShortcuts, PlayerSpinner, VideoElements } from './Components';
import MatureContentBanner from './Components/Common/MatureContentBanner';
import PlayerEventsComp from './Components/PlayerEventsComp';
import StreamDetailsForFullScreen from './Components/StreamDetailsForFullScreen';
import StreamEndedOutside from './Components/StreamEndedOutside';
import { VIDEO_PLAYER_ID } from './constants';
import { useControlDisplayStore } from './store/useControlDisplayStore';
import { usePlayerStore } from './store/usePlayerStore';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const {
  setIsHoverOnContainer,
  resetControlSectionVisiblity
} = useControlDisplayStore.getState();
const {
  setIsPortalOpen,
  setPlayerProps,
  setIsMiniPlayerEnabled
} = usePlayerStore.getState();
const {
  setIsFullScreen,
  setStream
} = useStreamStore.getState();
var _ref = process.env.NODE_ENV === "production" ? {
  name: "eivff4",
  styles: "display:none"
} : {
  name: "1ir7tb7-IVSVideoPlayer",
  styles: "display:none;label:IVSVideoPlayer;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const IVSVideoPlayer = () => {
  const router = useRouter();
  const {
    screen_isMobile,
    screen_isDesktop
  } = useDeviceInfo();
  const currentPathname = router.pathname;
  const {
    theatreMode,
    isFullScreen
  } = useStreamStore(state => ({
    isFullScreen: state.isFullScreen,
    theatreMode: state.theatreMode
  }));
  const isEnded = usePlayerStore(state => state.isEnded);
  const stream = useStreamStore(state => state.stream);
  const streamUid = usePlayerStore(state => state.playerProps.stream?.uid || null);
  const [bannerShownTime, setBannerShownTime] = useState<number | null>(null);
  const streamData = useStreamStorage();
  const previousPathname = usePrevious(currentPathname);
  const previousStreamUid = usePrevious(streamUid);
  const isPathChanged = currentPathname !== previousPathname;
  const isStreamChanged = previousStreamUid !== streamUid;
  const isPortalOpen = usePlayerStore(state => state.isPortalOpen);
  const thumbnail_url_small = usePlayerStore(state => state.playerProps.stream?.thumbnail_url_small || null);
  const has_mature_content = useStreamStore(state => state.stream?.has_mature_content);
  const me = useAuthStore(state => state.me);
  const {
    pageInfo,
    sourceTrackingInfo
  } = useAppStore(state => state);
  useEffect(() => {
    const isPlayerStreamPresent = !!streamUid;
    if (has_mature_content) {
      setIsMiniPlayerEnabled(false);
    } else {
      setIsMiniPlayerEnabled(true);
    }
    if (!isPlayerStreamPresent || !previousPathname || !(isPathChanged || isStreamChanged)) return;
    const isStreamPage = currentPathname === PAGES_ROUTE.STREAM_PAGE;
    const isClipPage = currentPathname === PAGES_ROUTE.CLIPS_PAGE || currentPathname === '/clips';
    const isStreamerProgilePage = currentPathname === PAGES_ROUTE.STREAMER_PAGE;

    //@ts-ignore
    if (isClipPage && isPortalOpen) {
      setStream(undefined);
      setIsPortalOpen(false);
      setPlayerProps(null);
      return;
    }
    if (isPathChanged && has_mature_content) {
      setStream(undefined);
      setIsPortalOpen(false);
      setPlayerProps(null);
      return;
    }
    if (isPathChanged && !isClipPage && !isStreamPage) {
      setIsPortalOpen(true);
    } else if (!isPortalOpen && !(isStreamPage || isStreamerProgilePage)) {
      setPlayerProps(null);
      setStream(undefined);
    }
  }, [screen_isMobile, currentPathname, isPathChanged, isPortalOpen, isStreamChanged, previousPathname, streamUid, has_mature_content]);
  const handleAgeVerification = async () => {
    if (!streamUid) return;
    const res = await streamAgeVerification(streamUid, 7);
    if (res.message) {
      streamStorage.setUserConsent(streamUid, true);
      fireMatureContentEvent('accept');
    }
  };
  const fireMatureContentEvent = (response: 'accept' | 'reject') => {
    if (!bannerShownTime || !streamData) return;
    const sourceEventProperties = getSourceEventProperties(sourceTrackingInfo);
    const popupTimeMs = Date.now() - bannerShownTime;
    Amplitude.logEvent('mature_content_popup', {
      ...sourceEventProperties,
      web_page_type: pageInfo.page,
      popup_time_ms: popupTimeMs,
      response,
      streamer_name: stream?.streamer?.username,
      stream_status: stream?.status,
      video_id: stream?.uid,
      streamer_type: pageInfo.streamerType,
      streamer_category: pageInfo.streamerCategory
    });
  };
  const handleAccept = () => {
    handleAgeVerification();
  };
  const handleReject = () => {
    fireMatureContentEvent('reject');
    router.back();
  };
  const showMatureContentBanner = (() => {
    if (!streamUid || !streamData) return false;

    //@ts-ignore
    const hasMatureContent = streamData.has_mature_content === true ||
    //@ts-ignore
    streamData?.has_mature_content === 'true';
    const isStreamOwner = me?.user_uid ? stream?.streamer.user_uid === me.user_uid : false;
    const hasUserConsent = streamData.user_consent;
    return hasMatureContent && !isStreamOwner && !hasUserConsent;
  })();
  useEffect(() => {
    if (showMatureContentBanner) {
      setBannerShownTime(Date.now());
    }
  }, [showMatureContentBanner]);
  useEffect(() => {
    // const isCurrentyInFullScreen = getFullScreen()
    const videoPlayer = document.getElementById(VIDEO_PLAYER_ID);
    if (!videoPlayer) {
      return;
    }
    if (isFullScreen) {
      enterFullScreen(videoPlayer);
      // Checking if user exit fullscreen by pressing ESC
      const timer = workerTimers.setInterval(() => {
        const isStillFullScreen = getFullScreen();
        if (!isStillFullScreen) {
          // Timer removed when isFullScreen is true
          setIsFullScreen(false);
        }
      }, 1000);
      return () => {
        workerTimers.clearInterval(timer);
      };
    } else {
      exitFullScreen();
    }
  }, [isFullScreen]);
  if (showMatureContentBanner) {
    return _jsx(MatureContentBanner, {
      thumbnailUrl: thumbnail_url_small,
      onAccept: handleAccept,
      onReject: handleReject
    });
  }
  if (!streamUid) {
    return _jsxs(_Fragment, {
      children: [_jsx(PlayerSpinner, {}), _jsx("p", {
        css: _ref,
        children: "Something went wrong 00123"
      })]
    });
  }
  if (isEnded) {
    return _jsx(_Fragment, {
      children: _jsx(StreamEndedOutside, {})
    });
  }
  return _jsx(React.Fragment, {
    children: _jsx("div", {
      className: "player-section",
      children: _jsx("div", {
        className: "video-container",
        onMouseMove: () => {
          if (!theatreMode && !isFullScreen) {
            if (screen_isDesktop) {
              setIsHoverOnContainer(true);
            } else {
              resetControlSectionVisiblity();
            }
          } else {
            // For theatre mode & full screen mode
            resetControlSectionVisiblity();
          }
        },
        onMouseLeave: () => setIsHoverOnContainer(false),
        children: _jsx(InitializeVideoPlayer, {
          children: _jsxs(ControlSheetSection, {
            children: [!isPortalOpen && _jsx(StreamDetailsForFullScreen, {}), _jsx(KeyboardShortcuts, {}), _jsx(VideoElements, {}), _jsxs(_Fragment, {
              children: [_jsx(PlayerEventsComp, {}), _jsx(LogEvents, {})]
            })]
          })
        })
      })
    })
  });
};
export default IVSVideoPlayer;