export type SearchSuggestionResType = {
  content: {
    component: {
      id: number;
      analytics: {
        _dt_: string;
        type?: string;
      };
      data: {
        title: {
          data: string;
          is_verified?: boolean;
          is_live?: boolean;
        };
        sub_title?: {
          data: string;
        };
        thumbnail?: {
          data: string;
        };
        click_action: {
          deep_link: string;
        };
      };
    };
  }[];
}[];
export type TSearchTrending = {
  row_type: number;
  title: string;
  content: {
    component: {
      id: number;
      component_uid: string;
      analytics: {
        _dt_: string;
        section_title?: string;
        type?: string;
      };
      data: {
        title: {
          data: string;
          is_verified?: boolean;
          is_live?: boolean;
        };
        start_image?: {
          is_circular: boolean;
          data: string;
        };
        bg_color?: {
          data: string;
        };
        rim_color?: {
          data: string;
        };
        thumbnail?: {
          data: string;
        };
        end_image?: {
          data: string;
        };
        click_action: {
          deep_link: string;
        };
      };
    };
  }[];
};
export enum LocoDeepLinkPage {
  HOME_PAGE = 1,
  WALLET = 2,
  GAME_DETAIL = 3,
  // requires game_uid and to auto play requires play_latest = true
  STREAMER_PROFILE = 4,
  // requires streamer_uid
  PLAYER = 5,
  //requires stream_uid and reply_parent_uid
  TRIVIA_LANDING = 6,
  TRIVIA_LIVE = 7,
  REFERRAL = 8,
  ARENA_LANDING = 9,
  ARENA_GAME = 10,
  ARENA_TOURNAMENT = 11,
  SEARCH = 12,
  //requires search_query
  LOGIN = 13,
  HELP_FEEDBACK = 14,
  WEB = 15,
  //General webview without scope token
  STREAM_NOW = 16,
  SUBRECIPE = 17,
  WEBPAGE = 18,
  //Webiew which works with scope token, show native navigation bar
  EVENT_DETAILS = 19,
  PWM_PROMO_VIDEO = 20,
  REDEEM_BUNDLE = 21,
  REDEEM_REWARDS = 22,
  DIAMOND_STORE = 23,
  DYNAMIC_TABS = 24,
  INTERNAL_WEB_PAGE = 25,
  //Webview with scope token but dont show navigation bar, if the web_url is white listed one
  REWARDS = 26,
  QUEST = 27,
}
export enum HOME_ROW_TYPE {
  FULL_WIDTH = 10,
  FEATURED = 20,
  SMALL_WIDTH = 30,
  // Section type
  FULL_WIDTH_AD = 40,
  FULL_WIDTH_LARGE_AD = 50,
  FEATURED_VIEW = 60,
  TEST = 70,
}
export enum HOME_COMPONENT_ID {
  TOP_NAV_BAR = 1,
  SMALL_WIDTH_VOD = 2,
  MEDIUM_WIDTH_VOD = 3,
  FULL_WIDTH_VOD_OR_LIVE = 4,
  CIRCULAR_IMAGE = 5,
  STREAMERS = 6,
  ANNOUNCEMENT_BIG = 7,
  ANNOUNCEMENT_SMALL = 8,
  PORTRAIT_IMAGE_TITLE_SUBTITLE_CTA = 9,
  CATEGORY_PORTRAIT_FOLLOW = 10,
  SQUARE_IMAGE_TITLE_SUBTITLE_CTA = 11,
  // we are no using this, as this is used by row_type = 20
  FEATURED_CARD = 12,
  SWIPE_ABLE_IMAGES = 13,
  FULL_WIDTH_AD = 14,
  MEDIUM_WIDTH_AD = 15,
  SMALL_WIDTH_AD = 16,
  FULL_WIDTH_LARGE_AD = 17,
  STREAMER_PROFILE_FULL = 18,
  SMALL_WIDTH_VIDEO_WITH_SIDEWAYS_DATA = 19,
  CATEGORY_FULL_WIDTH = 20,
  LABEL_FULL_WIDTH = 21,
  THUMBNAIL_TITLE_SUBTITLE_END_IMAGE = 23,
}