import Modal from '@components/core/modal/Modal';
import { useDeviceInfo } from '@hooks/react-use';
import dynamic from 'next/dynamic';
import React from 'react';
import { openLoginLinkingModal, useOtplessStore } from './store/otplessSlice2';
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const OtplessV3 = dynamic(() => import('./Component/OtplessV3'), {
  ssr: false
});
const LazyComponentOtpless = () => {
  const {
    screen_isMobile
  } = useDeviceInfo();
  const {
    modal_type
  } = useOtplessStore();
  if (!modal_type) return _jsx(_Fragment, {});
  return _jsx(Modal, {
    visible: !!modal_type,
    onClose: () => {
      openLoginLinkingModal({
        modal_type: null
      });
    },
    customStyles: {
      width: screen_isMobile ? '100%' : '400px',
      height: 'fit-content'
    },
    closeOnEsc: true,
    children: _jsx(OtplessV3, {})
  });
};
export default LazyComponentOtpless;