import { useDeviceInfo } from '@hooks/react-use';
import { useStreamStore } from '@stores/streamStore';
import { useEffect } from 'react';
import { TransI18 } from 'src/i18n';
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const {
  toggleTheatreMode
} = useStreamStore.getState();
const TheatreMode = ({
  disabled: isElementDisabled
}: {
  disabled?: boolean;
}) => {
  const {
    screen_isMobile
  } = useDeviceInfo();
  const {
    isFullScreen,
    theatreMode
  } = useStreamStore(state => ({
    isFullScreen: state.isFullScreen,
    theatreMode: state.theatreMode
  }));
  const isDisableTheatre = isFullScreen || screen_isMobile;
  useEffect(() => {
    if (isDisableTheatre) {
      toggleTheatreMode(false);
    }
  }, [isDisableTheatre]);
  if (isDisableTheatre) return _jsx(_Fragment, {});
  return _jsxs("button", {
    onClick: () => {
      toggleTheatreMode(!theatreMode);
    },
    disabled: isDisableTheatre || isElementDisabled,
    "aria-disabled": isDisableTheatre || isElementDisabled,
    className: `control-tooltip-parent theatre-toggle ` + (theatreMode ? 'theatre-toggle-on' : 'theatre-toggle-off'),
    children: [_jsx("span", {
      className: "control-tooltip theatre_mode",
      style: {
        left: '-30%'
      },
      children: _jsx(TransI18, {
        i18nKey: theatreMode ? 'video_player.player_controls.theater_mode_actions.exit' : 'video_player.player_controls.theater_mode_actions.enter'
      })
    }), _jsx("svg", {
      style: {
        opacity: isDisableTheatre || isElementDisabled ? 0.5 : 1
      },
      width: "20",
      height: "20",
      viewBox: "0 0 20 20",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      children: _jsx("path", {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M5.83335 2.5C3.53217 2.5 1.66669 4.36548 1.66669 6.66667V13.3333C1.66669 15.6345 3.53217 17.5 5.83335 17.5H14.1667C16.4679 17.5 18.3334 15.6345 18.3334 13.3333V6.66667C18.3334 4.36548 16.4679 2.5 14.1667 2.5H5.83335ZM16.6668 10.8333H3.33352V13.3333C3.33352 14.714 4.4528 15.8333 5.83351 15.8333H14.1668C15.5476 15.8333 16.6668 14.714 16.6668 13.3333V10.8333Z",
        fill: "white"
      })
    })]
  });
};
export default TheatreMode;