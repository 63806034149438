import create, { GetState, SetState } from 'zustand';
interface IType {
  downloadedImages: {
    [key: string]: string;
  };
  addDownloadedImage: (value: {
    [key: string]: string;
  }) => void;
  getDownloadedImage: (url: string, options?: {
    cache_image_only: boolean;
  }) => string;
  setDownloadedImage: (value: {
    [key: string]: string;
  }) => void;
  resetDownloadedImage: () => void;
  __callback_action: (url: string) => void;
}
const store = (set: SetState<IType>, get: GetState<IType>): IType => ({
  downloadedImages: {},
  __callback_action: () => {
    return;
  },
  addDownloadedImage: data => {
    set(({
      downloadedImages
    }) => ({
      downloadedImages: {
        ...data,
        ...downloadedImages
      }
    }));
  },
  getDownloadedImage: (url, options) => {
    const {
      downloadedImages,
      __callback_action
    } = get();
    if (options?.cache_image_only === true) {
      return downloadedImages[url];
    }
    __callback_action(url);
    return downloadedImages[url] || url;
  },
  setDownloadedImage: data => set({
    downloadedImages: {
      ...data
    }
  }),
  resetDownloadedImage: () => set({
    downloadedImages: {}
  })
});
export const usePrefetchImageStore = create(store);