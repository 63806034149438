function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import React, { useEffect, useState } from 'react';
import * as workerTimers from 'worker-timers';
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
interface IProp {
  isShown: boolean;
}
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1c7nry",
  styles: "z-index:1;display:flex;flex-direction:column;align-items:center;justify-content:center;transition-property:all;transition-timing-function:cubic-bezier(0.4, 0, 1, 1);transition-duration:150ms"
} : {
  name: "1dk1ckh-Animate1",
  styles: "z-index:1;display:flex;flex-direction:column;align-items:center;justify-content:center;transition-property:all;transition-timing-function:cubic-bezier(0.4, 0, 1, 1);transition-duration:150ms;label:Animate1;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const Animate1 = ({
  isShown: isShownDefault,
  children
}: React.PropsWithChildren<IProp>) => {
  const [isDisplay, setIsDisplay] = useState(() => isShownDefault);
  const [isShown, setIsShown] = useState(() => isShownDefault);
  useEffect(() => {
    if (isShownDefault) {
      setIsDisplay(true);
      const timer = workerTimers.setTimeout(() => {
        setIsShown(true);
      }, 50);
      return () => {
        workerTimers.clearTimeout(timer);
      };
    } else {
      setIsShown(false);
      const timer = workerTimers.setTimeout(() => {
        setIsDisplay(false);
      }, 150);
      return () => {
        workerTimers.clearTimeout(timer);
      };
    }
  }, [isShownDefault]);
  if (!isDisplay) {
    return _jsx(_Fragment, {});
  }
  return _jsx("div", {
    css: _ref,
    style: {
      transform: isShown ? 'translateY(0%)' : 'translateY(50%)',
      opacity: isShown ? 1 : 0
    },
    children: children
  });
};
export default Animate1;