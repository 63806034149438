import { load } from '@fingerprintjs/botd';
import { getUtmCampaign } from '@functions/utmCampaign';
import { check_device_isMobile } from '@hooks/react-use/useDeviceInfo';
import { useAppStore } from '@stores/appStore';
import { useAuthStore } from '@stores/authStore';
import { BotDetectionResult } from '@utils/checkIfBot';
import Amplitude_v2 from './amplitudeInstance';
import { allowed_events_for_bk } from './types/constants';
import { IEventProps } from './types/eventPropsTypes';
import UpdateAmplitudeUserHOC from './updateAmplitudeUser';
const amp = new Amplitude_v2({
  key: (process.env.AMPLITUDE_API_KEY as string)
});
const {
  updateAmplitudeUser,
  resetAmplitudeUser
} = UpdateAmplitudeUserHOC(amp);
const logEvent = async (eventName: string, properties: IEventProps) => {
  properties = await populateproperties(eventName, properties);
  amp.logEvent(eventName, properties);
};
const populateproperties = async (eventName: string, props: IEventProps): Promise<IEventProps> => {
  const {
    botInfo
  } = await getBotDetails();
  const isBot = !!botInfo?.bot;
  const botName = isBot ? botInfo?.botKind : null;
  const isWebview = typeof window !== 'undefined' ? /(Version\/\d+.*\/\d+.0.0.0 Mobile|; ?wv|(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari))/i.test(window?.navigator?.userAgent) : false;
  const extraProps: IEventProps = {
    web_origin: typeof window !== 'undefined' ? window.location.origin : '',
    user_platform: 'web_locogg',
    'web-platform': 'loco.gg',
    web_device_type: check_device_isMobile() ? isWebview ? 'webview' : 'mobile_browser' : 'desktop'
  };
  if (allowed_events_for_bk[eventName]) {
    extraProps['bk_device_id'] = localStorage?.getItem('fingerprint') ?? '';
    const me = useAuthStore.getState().me;
    extraProps['bk_user_id'] = me?.user_uid ?? '';
  }
  return Object.assign({}, props, getUtmCampaign(), {
    bot_probable: !!isBot,
    bot_kind: botName || undefined
  }, extraProps);
};
const getBotDetails = async () => {
  const botInfo = useAppStore.getState().botInfo;
  if (botInfo) {
    return {
      botInfo: botInfo
    };
  }
  const botdPromise = load({
    monitoring: false
  });
  useAppStore.setState({
    botInfoLoading: true
  });
  const result = await botdPromise.then(botd => botd.detect()).then(result => {
    return (result as BotDetectionResult);
  }).catch(error => {
    console.error(error);
    return null;
  });
  const response = {
    botInfo: result,
    botInfoLoading: false
  };
  useAppStore.setState(response);
  return response;
};
const addAmplitudeIdentity = amp.addIdentity;
const setAmplitudeIdentity = amp.setIdentity;
export { addAmplitudeIdentity, logEvent, resetAmplitudeUser, setAmplitudeIdentity, updateAmplitudeUser };