function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { usePlayerStore } from '@components/VideoPlayer/store/usePlayerStore';
import { checkIfFatalError } from '@components/VideoPlayer/Utils/utils';
import { useStreamStore } from '@stores/streamStore';
import type { PlayerError as IPlayerError } from 'amazon-ivs-player';
import React from 'react';
import { TransI18, useTranslation } from 'src/i18n';
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const {
  setPlayerProps
} = usePlayerStore.getState();
const {
  updatePlaybackUrl
} = useStreamStore.getState();
const GenerateCustomErrorCode = (error: IPlayerError) => {
  // Error code should not ends with 0
  /**
   * @example How to read error from UI/BROWSER.
   * First 2 digit are ErrorType
   * Remaining digits are ErrorCode
   * If 3rd digit is 0, it meanse -ve Error Code.
   *
   * Eg: L1201 -> Type: Error; Code: -1
   * Eg: L51500 -> Type: ErrorNetwork; Code: 500
   */
  const customTypeCode = {
    // STRICTLY DO NOT CHANGE THESE VALUES
    // STRICTLY DO NOT CHANGE THESE VALUES
    // STRICTLY DO NOT CHANGE THESE VALUES
    // Default value: L11

    Error: 'L12',
    //A generic error occurred.
    getPlaybackFailed: 'L13',
    ErrorInvalidData: 'L21',
    //Data or input is invalid.
    ErrorInvalidParameter: 'L22',
    //A method parameter is invalid.
    ErrorInvalidState: 'L23',
    //The player or an internal object is in an invalid state.

    ErrorAuthorization: 'L31',
    //A network resource is not authorized.
    ErrorTimeout: 'L32',
    // The player timed out on an operation.

    ErrorNotAvailable: 'L41',
    //The stream is not available. The accompanying HTTP status code can help identify why the stream is unavailable. If the status code is 429, the concurrent-viewers limit was reached. If the status code is 404, the stream does not exist or is offline.
    ErrorNotSupported: 'L42',
    //A method or feature is not supported.
    ErrorNoSource: 'L43',
    //There is no source for the player to play.

    ErrorNetwork: 'L51',
    //There is a network error.
    ErrorNetworkIO: 'L52' //There is a network I/O failure.
  };

  let ErrorCode = '';
  ErrorCode += String(customTypeCode[error.type] || `L11`); // default : 11
  ErrorCode += error.code < 0 ? '0' : '';
  ErrorCode += Math.abs(error.code); // any number

  return ErrorCode;
};
var _ref = process.env.NODE_ENV === "production" ? {
  name: "nmrinp",
  styles: "position:relative;z-index:20;display:flex;height:100%;width:100%;align-items:flex-start;justify-content:center"
} : {
  name: "kv931u-PlayerErrorComp",
  styles: "position:relative;z-index:20;display:flex;height:100%;width:100%;align-items:flex-start;justify-content:center;label:PlayerErrorComp;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "jmdn0b",
  styles: "margin-top:0.5rem;display:flex;min-width:50%;flex-direction:row;flex-wrap:wrap;align-items:center;justify-content:space-between;gap:0.25rem;border-radius:0.375rem;--tw-bg-opacity:1;background-color:rgb(224 32 32 / var(--tw-bg-opacity));padding:1rem;font-size:14px;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));@media (max-width: 767px){min-width:75%;padding:0.625rem;font-size:12px;}"
} : {
  name: "zpewfo-PlayerErrorComp",
  styles: "margin-top:0.5rem;display:flex;min-width:50%;flex-direction:row;flex-wrap:wrap;align-items:center;justify-content:space-between;gap:0.25rem;border-radius:0.375rem;--tw-bg-opacity:1;background-color:rgb(224 32 32 / var(--tw-bg-opacity));padding:1rem;font-size:14px;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));@media (max-width: 767px){min-width:75%;padding:0.625rem;font-size:12px;};label:PlayerErrorComp;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "p2oy12",
  styles: "display:flex;flex-direction:column;gap:0.25rem"
} : {
  name: "1f32l0a-PlayerErrorComp",
  styles: "display:flex;flex-direction:column;gap:0.25rem;label:PlayerErrorComp;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "171ikgv",
  styles: "display:flex;align-items:center;justify-content:flex-start;gap:0.25rem"
} : {
  name: "kxnee2-PlayerErrorComp",
  styles: "display:flex;align-items:center;justify-content:flex-start;gap:0.25rem;label:PlayerErrorComp;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "nmrinp",
  styles: "position:relative;z-index:20;display:flex;height:100%;width:100%;align-items:flex-start;justify-content:center"
} : {
  name: "kv931u-PlayerErrorComp",
  styles: "position:relative;z-index:20;display:flex;height:100%;width:100%;align-items:flex-start;justify-content:center;label:PlayerErrorComp;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "jmdn0b",
  styles: "margin-top:0.5rem;display:flex;min-width:50%;flex-direction:row;flex-wrap:wrap;align-items:center;justify-content:space-between;gap:0.25rem;border-radius:0.375rem;--tw-bg-opacity:1;background-color:rgb(224 32 32 / var(--tw-bg-opacity));padding:1rem;font-size:14px;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));@media (max-width: 767px){min-width:75%;padding:0.625rem;font-size:12px;}"
} : {
  name: "zpewfo-PlayerErrorComp",
  styles: "margin-top:0.5rem;display:flex;min-width:50%;flex-direction:row;flex-wrap:wrap;align-items:center;justify-content:space-between;gap:0.25rem;border-radius:0.375rem;--tw-bg-opacity:1;background-color:rgb(224 32 32 / var(--tw-bg-opacity));padding:1rem;font-size:14px;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));@media (max-width: 767px){min-width:75%;padding:0.625rem;font-size:12px;};label:PlayerErrorComp;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "p2oy12",
  styles: "display:flex;flex-direction:column;gap:0.25rem"
} : {
  name: "1f32l0a-PlayerErrorComp",
  styles: "display:flex;flex-direction:column;gap:0.25rem;label:PlayerErrorComp;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "171ikgv",
  styles: "display:flex;align-items:center;justify-content:flex-start;gap:0.25rem"
} : {
  name: "kxnee2-PlayerErrorComp",
  styles: "display:flex;align-items:center;justify-content:flex-start;gap:0.25rem;label:PlayerErrorComp;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref9 = process.env.NODE_ENV === "production" ? {
  name: "1rmvtak",
  styles: "display:flex;gap:0.25rem"
} : {
  name: "1rpwaej-PlayerErrorComp",
  styles: "display:flex;gap:0.25rem;label:PlayerErrorComp;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref10 = process.env.NODE_ENV === "production" ? {
  name: "6357xy",
  styles: "margin-left:0.75rem;display:flex;gap:0.25rem"
} : {
  name: "sublbt-PlayerErrorComp",
  styles: "margin-left:0.75rem;display:flex;gap:0.25rem;label:PlayerErrorComp;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref11 = process.env.NODE_ENV === "production" ? {
  name: "0",
  styles: ""
} : {
  name: "1uqu5ru-PlayerErrorComp",
  styles: ";label:PlayerErrorComp;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const PlayerErrorComp = (props: {
  playerError: IPlayerError;
}) => {
  const isPortalOpen = usePlayerStore(state => state.isPortalOpen);
  const {
    t
  } = useTranslation();
  let messageToShow = props?.playerError?.message;
  if (!props?.playerError) {
    return _jsx(_Fragment, {});
  }
  try {
    const newmessageToShow = JSON.parse(messageToShow);
    messageToShow = newmessageToShow?.message || newmessageToShow?.error || messageToShow;
  } catch (err) {
    // Sometime, server sending JSON error. like 401
  }
  let isFatalError = checkIfFatalError(props?.playerError) || messageToShow === 'No Network';
  if (messageToShow === 'Source create failed') {
    isFatalError = false;
    messageToShow = t('video_player.player_messages.error');
  }
  if (isPortalOpen) {
    return _jsx("div", {
      css: _ref,
      children: _jsx("div", {
        css: _ref2,
        children: _jsx("div", {
          css: _ref3,
          children: _jsxs("div", {
            css: _ref4,
            children: [_jsx(Warning, {}), _jsx("p", {
              children: messageToShow
            })]
          })
        })
      })
    });
  }
  return _jsx("div", {
    css: _ref5,
    children: _jsxs("div", {
      css: _ref6,
      children: [_jsxs("div", {
        css: _ref7,
        children: [_jsxs("div", {
          css: _ref8,
          children: [_jsx(Warning, {}), _jsx("p", {
            children: _jsx(TransI18, {
              i18nKey: "video_player.player_messages.error_code"
            })
          }), _jsx("p", {
            children: ":"
          }), _jsx("p", {
            children: GenerateCustomErrorCode(props.playerError)
          })]
        }), _jsx("div", {
          css: _ref9,
          children: messageToShow
        })]
      }), isFatalError ? null : _jsx("div", {
        css: _ref10,
        children: _jsx("button", {
          css: _ref11,
          className: "ivs-reload-player-button",
          onClick: () => {
            setPlayerProps(null);
            updatePlaybackUrl(true);
          },
          children: _jsx(TransI18, {
            i18nKey: "video_player.player_messages.reload"
          })
        })
      })]
    })
  });
};
export default PlayerErrorComp;
const Warning = () => _jsx("svg", {
  width: "14",
  height: "13",
  viewBox: "0 0 14 13",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    d: "M1.84375 12.4155H11.8159C12.7681 12.4155 13.3647 11.73 13.3647 10.8667C13.3647 10.6064 13.3013 10.3462 13.1616 10.105L8.16602 1.18652C7.88037 0.672363 7.35352 0.399414 6.83301 0.399414C6.30615 0.399414 5.7793 0.672363 5.49365 1.18652L0.504395 10.1113C0.371094 10.3525 0.294922 10.6064 0.294922 10.8667C0.294922 11.73 0.891602 12.4155 1.84375 12.4155ZM6.83301 8.17529C6.50293 8.17529 6.3252 7.98486 6.31885 7.64844L6.22998 4.19531C6.22363 3.85889 6.47119 3.61768 6.82666 3.61768C7.17578 3.61768 7.43604 3.86523 7.42969 4.20166L7.34082 7.64844C7.33447 7.99121 7.15039 8.17529 6.83301 8.17529ZM6.83301 10.3018C6.4585 10.3018 6.12842 9.99707 6.12842 9.62256C6.12842 9.2417 6.45215 8.93701 6.83301 8.93701C7.22021 8.93701 7.54395 9.23535 7.54395 9.62256C7.54395 10.0034 7.21387 10.3018 6.83301 10.3018Z",
    fill: "white"
  })
});