import type { AppProps as NextJsAppProps } from 'next/app';
import { appWithTranslation, i18n, Trans, useTranslation as useDefaultTranslation } from 'next-i18next';
import NextConfig from '../../next-i18next.config';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
function withi18Wrapper<Props extends NextJsAppProps>(Component: React.ComponentType<Props>) {
  return appWithTranslation(Component, NextConfig);
}
const TransI18 = ({
  i18nKey,
  components,
  options,
  ins
}: {
  i18nKey: string;
  components?: React.ReactElement[] | {
    [tagName: string]: React.ReactElement;
  };
  ins?: string;
  options?: {
    [key: string]: string | number;
  };
}) => {
  const {
    t
  } = useTranslation(ins);
  return _jsx("span", {
    suppressHydrationWarning: true,
    children: _jsx(Trans, {
      t: t,
      i18nKey: i18nKey,
      values: options,
      components: components
    })
  });
};
const getT = (i18nKey: string, options?: {
  [key: string]: string | number;
}) => {
  if (!i18n || !i18n.isInitialized) return '.' + i18nKey;
  const {
    t
  } = i18n;
  return t(i18nKey, options);
};
type IUseTranslationType = typeof useDefaultTranslation;
const useTranslation: IUseTranslationType = (...rest) => {
  const result = useDefaultTranslation(...rest);
  return result;
};
export { getT, TransI18, useTranslation, withi18Wrapper };