export function beforeonloadRequest(url: string, initialOptions: RequestInit) {
  const defaults: RequestInit = {
    method: 'POST',
    //@ts-ignore
    headers: null,
    body: null,
    credentials: 'include'
  };
  const options = Object.assign({}, defaults, initialOptions);
  options.headers = options.headers || {};
  function trySendBeacon(): boolean {
    if (!('navigator' in window && 'sendBeacon' in window.navigator)) {
      return false; // Need sendBeacon to be available
    }

    if (options.method !== 'POST') {
      return false; // Method cannot be anything other than POST
    }

    if (options.credentials && options.credentials !== 'include') {
      return false; // Credentials mode cannot be anything other than include
    }

    if (
    //@ts-ignore
    Object.keys(options.headers).length > (
    //@ts-ignore
    'Content-Type' in options.headers ? 1 : 0)) {
      return false; // Cannot specify header other than Content-Type
    }

    let body = options.body;
    //@ts-ignore
    if ('Content-Type' in options.headers) {
      // If Content-Type is specified, try to wrap body in Blob so we can use the Content-Type

      if (!('Blob' in window)) {
        return false; // Need Blob to be available
      }

      if (typeof options.body !== 'string') {
        return false; // Body needs to be a string
      }

      body = new Blob([options.body], {
        type: options.headers['Content-Type']
      });
    }
    try {
      return window.navigator.sendBeacon(url, body); // Will return false if body is over size limit
    } catch (err) {
      // One example of such an error https://bugs.chromium.org/p/chromium/issues/detail?id=490015
      return false;
    }
  }
  function tryXHR(): boolean {
    const xhr = new XMLHttpRequest();
    //@ts-ignore
    xhr.open(options.method, url, false);
    xhr.withCredentials = options.credentials === 'include';
    //@ts-ignore
    for (const header of Object.keys(options.headers)) {
      //@ts-ignore
      xhr.setRequestHeader(header, options.headers[header]);
    }
    try {
      //@ts-ignore
      xhr.send(options.body);
    } catch (err) {
      // Browser likely not allowing synchronous XHR https://bugs.chromium.org/p/chromium/issues/detail?id=952452
      return false;
    }
    return true;
  }
  function tryFetch(): boolean {
    if (!('fetch' in window)) {
      // Need fetch to be available
      return false;
    }
    if (!('keepalive' in new Request(''))) {
      // Need Request to support keepalive
      return false;
    }
    try {
      fetch(url, Object.assign({}, options, {
        keepalive: true
      }));
    } catch (error) {
      console.log(error);
    }
    return true;
  }
  if (trySendBeacon()) {
    return true;
  }
  if (tryXHR()) {
    // Do before fetch because there's no way to synchronously know if fetch will fail (e.g. because of this https://bugs.chromium.org/p/chromium/issues/detail?id=835821)
    return true;
  }
  if (tryFetch()) {
    return true;
  }
  return false;
}
export default beforeonloadRequest;