function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import FadeEffect from '@components/FadeEffectElement';
import { InPortalComp } from '@components/portal/WithReactReversePortal';
import { PLAYER_TYPE, VIDEO_PLAYER_ID } from '@components/VideoPlayer/constants';
import { useControlDisplayStore } from '@components/VideoPlayer/store/useControlDisplayStore';
import { usePlayerStore } from '@components/VideoPlayer/store/usePlayerStore';
import usePlayerContext from '@components/VideoPlayer/store/WithContextProvider';
import { useDeviceInfo } from '@hooks/react-use';
import { useStreamStore } from '@stores/streamStore';
import React, { useEffect, useRef, useState } from 'react';
import { TransI18, useTranslation } from 'src/i18n';
import * as Icons from './HelperComponents';
import KeyboardSetting from './KeyboardSetting';
import MenuItem from './MenuItem';
import PlayerQualitySetting, { CurrentQualitySetting } from './PlayerQualitySetting';
import PlayerSpeedSetting, { CurrentSpeedSetting } from './PlayerSpeedSetting';
import ReportPlayerSetting, { ReportPlayerBottomSheet } from './ReportPlayerSetting';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
interface ISubMenu {
  icon: React.ReactNode;
  className: string;
  title: string;
  SubMenuComponent: React.ReactNode;
  value?: React.ReactNode;
  hideArrow?: boolean;
  BottomComponent?: React.ReactNode;
}
const {
  setIsSettingModalOpen
} = useControlDisplayStore.getState();
var _ref = process.env.NODE_ENV === "production" ? {
  name: "zjik7",
  styles: "display:flex"
} : {
  name: "b3qsn0-PlayerSettingComp",
  styles: "display:flex;label:PlayerSettingComp;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "1e44bvw",
  styles: "height:1.25rem;min-height:20px;width:1.25rem;min-width:20px"
} : {
  name: "7a1gj9-PlayerSettingComp",
  styles: "height:1.25rem;min-height:20px;width:1.25rem;min-width:20px;label:PlayerSettingComp;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const PlayerSettingComp = ({
  disabled: isElementDisabled
}: {
  disabled?: boolean;
}) => {
  const {
    t
  } = useTranslation();
  const isFullScreen = useStreamStore(state => state.isFullScreen);
  const theatreMode = useStreamStore(state => state.theatreMode);
  const {
    width
  } = useDeviceInfo();
  const {
    qualitites
  } = usePlayerContext();
  const activePlayerType = usePlayerStore(state => state.activePlayerType);
  const isLivePlayerActive = activePlayerType === PLAYER_TYPE.LIVE;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isSubSettingOpen, setIsSubSettingOpen] = useState(false);
  const [maxContainerHeight, setMaxContainerHeight] = useState('30vh');
  const isSettingModalOpen = useControlDisplayStore(state => state.isSettingModalOpen);
  const SettingButtonRef = useRef<HTMLButtonElement>(null);
  const SettingMenuRef = useRef<HTMLDivElement>(null);
  const isMobileWaliWidth = width ? width < 767 : false;
  useEffect(() => {
    if (!isSettingModalOpen) {
      setIsSubSettingOpen(false);
      return;
    }
    const onPointerDown: (event: any) => void = (event: React.PointerEvent) => {
      const targetElem = (event.target as HTMLElement);
      const parentElem = SettingButtonRef.current;
      const parentElem2 = SettingMenuRef.current;
      if (targetElem && (parentElem && parentElem.contains(targetElem) || parentElem2 && parentElem2.contains(targetElem))) {
        // No need to close modal if user click inside
        return;
      }
      setIsSubSettingOpen(false);
      setIsSettingModalOpen(false);
    };
    document.addEventListener('mousedown', onPointerDown, {
      passive: true
    });
    return () => {
      document.removeEventListener('mousedown', onPointerDown);
    };
  }, [isSettingModalOpen]);
  React.useLayoutEffect(() => {
    const VideoContainer = document.getElementById(VIDEO_PLAYER_ID);
    if (!VideoContainer || isMobileWaliWidth) {
      setMaxContainerHeight(isMobileWaliWidth ? '50vh' : '30vh');
      return;
    }
    const containerStyles = getComputedStyle(VideoContainer);
    const newHeight = Math.round(parseFloat(containerStyles.height) * 0.7) + 'px';
    setMaxContainerHeight(newHeight);
  }, [isSettingModalOpen, isMobileWaliWidth]);
  const SettingMenu: ISubMenu[] = [{
    icon: Icons.playbackSpeed,
    className: 'player-speed-title',
    title: t('video_player.player_controls.playback_speed'),
    value: _jsx(CurrentSpeedSetting, {}),
    SubMenuComponent: isLivePlayerActive ? null : _jsx(PlayerSpeedSetting, {
      closeSubModal: () => setIsSubSettingOpen(false)
    }) //isLive ? null : PlaybackSpeedModal,
    // hideArrow: isLive,
  }, {
    icon: Icons.videoQuality,
    className: 'player-quality-title',
    title: t('video_player.player_controls.video_quality'),
    value: _jsx(CurrentQualitySetting, {}),
    SubMenuComponent: qualitites.length > 0 ? _jsx(PlayerQualitySetting, {
      closeSubModal: () => setIsSubSettingOpen(false)
    }) : null //isiOS ? null : QualityToggleModal,
    // hideArrow: isiOS,
  }, {
    icon: Icons.keyboardShortcuts,
    title: t('video_player.player_controls.keyboard_shortcuts'),
    hideArrow: true,
    SubMenuComponent: _jsx(KeyboardSetting, {}),
    className: 'keyboard-shortcuts' // Hide using css
  }, {
    icon: Icons.reportVideo,
    title: t('video_player.player_controls.report_video'),
    className: 'report-video-title',
    // passProps: { reportStream },
    hideArrow: true,
    SubMenuComponent: _jsx(ReportPlayerSetting, {}),
    BottomComponent: _jsx(ReportPlayerBottomSheet, {
      closeSubModal: () => setIsSubSettingOpen(false)
    })
  }];
  const SelectedSubMenu = SettingMenu[selectedIndex];
  return _jsxs(React.Fragment, {
    children: [_jsxs("div", {
      css: _ref,
      className: "control-tooltip-parent",
      children: [isElementDisabled ? null : _jsx("span", {
        className: "control-tooltip",
        children: _jsx(TransI18, {
          i18nKey: "video_player.player_controls.settings"
        })
      }), _jsx("button", {
        disabled: isElementDisabled,
        "aria-disabled": isElementDisabled,
        ref: SettingButtonRef,
        className: 'setting-toggle' + (isSettingModalOpen ? 'settings-on' : 'settings-off'),
        style: {
          opacity: isElementDisabled ? 0.5 : 1
        },
        onClick: () => setIsSettingModalOpen(!isSettingModalOpen),
        children: _jsx("img", {
          src: "/new_setting1.svg",
          className: "icon--setting",
          css: _ref2,
          alt: "Setting Icon"
        })
      })]
    }), isMobileWaliWidth && !isFullScreen && !theatreMode ? _jsx(InPortalComp, {
      name: "mobile-setting-view",
      children: _jsx(NewSection, {
        isSettingModalOpen: isSettingModalOpen,
        SettingMenuRef: SettingMenuRef,
        maxContainerHeight: maxContainerHeight,
        isSubSettingOpen: isSubSettingOpen,
        SettingMenu: SettingMenu,
        setSelectedIndex: setSelectedIndex,
        setIsSubSettingOpen: setIsSubSettingOpen,
        SelectedSubMenu: SelectedSubMenu,
        isMobileWaliWidth: isMobileWaliWidth
      })
    }) : _jsx(NewSection, {
      isSettingModalOpen: isSettingModalOpen,
      SettingMenuRef: SettingMenuRef,
      maxContainerHeight: maxContainerHeight,
      isSubSettingOpen: isSubSettingOpen,
      SettingMenu: SettingMenu,
      setSelectedIndex: setSelectedIndex,
      setIsSubSettingOpen: setIsSubSettingOpen,
      SelectedSubMenu: SelectedSubMenu,
      isMobileWaliWidth: isMobileWaliWidth
    })]
  });
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "ybyr0p",
  styles: "display:flex;padding-bottom:2rem;padding-top:0.5rem;@media (min-width: 768px){display:none;}"
} : {
  name: "1yuh622-NewSection",
  styles: "display:flex;padding-bottom:2rem;padding-top:0.5rem;@media (min-width: 768px){display:none;};label:NewSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "11v0pe8",
  styles: "margin-left:auto;margin-right:auto;height:0.375rem;width:6rem;border-radius:13px;--tw-bg-opacity:1;background-color:rgb(76 76 76 / var(--tw-bg-opacity))"
} : {
  name: "1qmkb3v-NewSection",
  styles: "margin-left:auto;margin-right:auto;height:0.375rem;width:6rem;border-radius:13px;--tw-bg-opacity:1;background-color:rgb(76 76 76 / var(--tw-bg-opacity));label:NewSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "l1rcbp",
  styles: "display:flex;padding-bottom:0.75rem;padding-top:0.5rem;@media (min-width: 768px){display:none;}"
} : {
  name: "odv3sl-NewSection",
  styles: "display:flex;padding-bottom:0.75rem;padding-top:0.5rem;@media (min-width: 768px){display:none;};label:NewSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "11v0pe8",
  styles: "margin-left:auto;margin-right:auto;height:0.375rem;width:6rem;border-radius:13px;--tw-bg-opacity:1;background-color:rgb(76 76 76 / var(--tw-bg-opacity))"
} : {
  name: "1qmkb3v-NewSection",
  styles: "margin-left:auto;margin-right:auto;height:0.375rem;width:6rem;border-radius:13px;--tw-bg-opacity:1;background-color:rgb(76 76 76 / var(--tw-bg-opacity));label:NewSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "o3pf48",
  styles: "margin-top:0.25rem;margin-bottom:0.25rem;display:none;width:100%;border-style:none;@media (min-width: 768px){display:block;}"
} : {
  name: "9tzhz2-NewSection",
  styles: "margin-top:0.25rem;margin-bottom:0.25rem;display:none;width:100%;border-style:none;@media (min-width: 768px){display:block;};label:NewSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const NewSection = ({
  isSettingModalOpen,
  SettingMenuRef,
  maxContainerHeight,
  isSubSettingOpen,
  SettingMenu,
  setSelectedIndex,
  setIsSubSettingOpen,
  SelectedSubMenu,
  isMobileWaliWidth
}: {
  isSettingModalOpen: boolean;
  SettingMenuRef: React.RefObject<HTMLDivElement>;
  maxContainerHeight: string;
  isSubSettingOpen: boolean;
  SettingMenu: ISubMenu[];
  setSelectedIndex: (value: number) => void;
  setIsSubSettingOpen: (value: boolean) => void;
  SelectedSubMenu: ISubMenu;
  isMobileWaliWidth: boolean;
}) => {
  return _jsx(FadeEffect, {
    isOpen: isSettingModalOpen,
    children: ({
      isVisible
    }) => {
      return _jsxs("div", {
        ref: SettingMenuRef,
        style: {
          maxHeight: isSubSettingOpen ? '100%' : maxContainerHeight || '30vh'
        },
        className: ['player-setting-menu', isVisible ? 'active' : '', isSubSettingOpen ? 'child-modal-open' : isMobileWaliWidth ? '' : 'not-sub-menu'].join(' '),
        children: [isVisible && !isSubSettingOpen ? _jsx("div", {
          css: _ref3,
          children: _jsx("div", {
            css: _ref4,
            role: "presentation"
          })
        }) : null, SettingMenu.map(({
          hideArrow,
          SubMenuComponent,
          ...rest
        }, index) => _jsx(MenuItem, {
          ...rest,
          hideArrow: hideArrow || !SubMenuComponent,
          onClick: () => {
            if (SubMenuComponent) {
              setSelectedIndex(index);
              setIsSubSettingOpen(true);
            }
          }
        }, index)), _jsx(FadeEffect, {
          isOpen: isSubSettingOpen,
          children: ({
            isVisible
          }) => _jsxs("div", {
            style: {
              maxHeight: maxContainerHeight || '30vh',
              position: 'absolute',
              zIndex: '10'
            },
            className: ['player-setting-menu sub-menu', isVisible ? 'active' : ''].join(' '),
            children: [isVisible ? _jsx("div", {
              css: _ref5,
              children: _jsx("div", {
                css: _ref6,
                role: "presentation"
              })
            }) : null, _jsx(MenuItem, {
              title: SelectedSubMenu.title,
              onClick: () => setIsSubSettingOpen(false),
              isSubMenu: true,
              showBackArrow: true,
              hideArrow: true,
              isSubmenuTitle: true
            }), _jsx("div", {
              css: _ref7,
              style: {
                borderBottom: '1px solid #535353'
              }
            }), _jsx("div", {
              className: "sub-menu-scroll",
              children: SelectedSubMenu.SubMenuComponent
            }), SelectedSubMenu?.BottomComponent || null]
          })
        })]
      });
    }
  });
};
export default PlayerSettingComp;