import useOnPageVisbility from '@hooks/useOnPageVisbility';
import { useEffect, useState } from 'react';
import { PLAYER_TYPE } from '../constants';
import { checkIfDebugLogsNeeded } from './utils';

/**
 * @description Used to check whether we need to count watch time or not
 * @returns {Boolean}
 */
export const useCountMetric = () => {
  const [IS_COUNT_METRIC, SET_COUNT_METRIC] = useState(false);
  useEffect(() => {
    SET_COUNT_METRIC(true);
  }, []);
  useOnPageVisbility(() => SET_COUNT_METRIC(true), () => SET_COUNT_METRIC(false));
  return IS_COUNT_METRIC;
};
export class playerLogs {
  type?: PLAYER_TYPE;
  constructor(params?: {
    type?: PLAYER_TYPE;
  }) {
    this.type = params?.type;
  }
  log(...params: any) {
    const isDebugLogNeeded = checkIfDebugLogsNeeded();
    if (!isDebugLogNeeded) {
      return;
    }
    console.log(`${this.type === PLAYER_TYPE.LIVE ? `${'\x1b[31m'}🚀 ~ file: type: LIVE${'\x1b[0m'}` : this.type === PLAYER_TYPE.VOD ? `${'\x1b[32m'}🚀 ~ file: type: VOD${'\x1b[0m'}` : '🚀 ~ file: ~ dLog'} ~ `, ...params);
  }
}