import { setLocoggDomainCookie } from '@functions/api';
import useIsomorphicEffect from '@hooks/useIsomorphicEffect';
import { getSourceEventProperties } from '@layouts/stream/helpers';
import { Amplitude } from '@modules/analytics';
import { useAppStore } from '@stores/appStore';
import { useAuthStore } from '@stores/authStore';
import { isLanguageSelectionEnabled } from '@utils/platform';
import dayjs from 'dayjs';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from '../i18n';
import { checkIsFirstTimeBrowserLoaded, extractSelectedLanguage, parseSelectedLanguage } from '../utils_i18n';
import ConfirmLanguageModal from './ConfirmLanguageModal';
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const setAppLanguage = useAppStore.getState().setAppLanguage;
const DetectAppLanguage = () => {
  const isLanguageEnabled = isLanguageSelectionEnabled();
  const appLanguage = useAppStore(state => state.appLanguage);
  const isUserPresent = useAuthStore(state => !!state.me?.user_uid);
  const {
    i18n
  } = useTranslation();
  const isInitialized = !!i18n?.isInitialized;
  const triggerClickEvent = (lang: string) => {
    const me = useAuthStore.getState().me;
    const sourceTrackingInfo = useAppStore.getState().sourceTrackingInfo;
    const sourceEventProperties = getSourceEventProperties(sourceTrackingInfo);
    const language_client = lang;
    Amplitude.logEvent('language_change_client', {
      ...sourceEventProperties,
      username: me?.username,
      userid: me?.user_uid,
      user_type: me?.user_type,
      language_client,
      trigger: 'client_override',
      //user_choice, client_override
      source: undefined
    });
  };
  async function handleDefaultProfileLanguage() {
    try {
      const me = useAuthStore.getState().me;
      const appLanguage = useAppStore.getState().appLanguage;
      if (!me || !appLanguage) return;
      const userDefaultLanguage = me.language;
      if (!userDefaultLanguage) {
        return;
      }
      // Just security check, so that we can filter unknown language
      const selectedLang = parseSelectedLanguage(userDefaultLanguage);
      if (appLanguage !== selectedLang) {
        // Language mismatch
        // update browser's appLanguage to userDefault language
        dayjs.locale(selectedLang);
        setLocoggDomainCookie({
          lang: selectedLang
        });
        i18n.changeLanguage(selectedLang);
        setAppLanguage(selectedLang);
        triggerClickEvent(selectedLang);
        return;
      }
    } catch (e) {}
  }
  const hdp = useRef(handleDefaultProfileLanguage);
  hdp.current = handleDefaultProfileLanguage;
  const changeI18DefaultLanguage = () => {
    const isLoadedFirst = checkIsFirstTimeBrowserLoaded();
    // @ts-ignore
    const userLang = window.navigator.language || window.navigator.userLanguage;
    const choosenLanguage = extractSelectedLanguage(isLoadedFirst ? '' : document.cookie, userLang);
    const i18Lower = i18n.resolvedLanguage;
    if (i18n && i18n.isInitialized && i18Lower !== choosenLanguage) {
      // language must be in lower case
      i18n.changeLanguage(choosenLanguage);
      setLocoggDomainCookie({
        lang: choosenLanguage
      });
    }
    dayjs.locale(choosenLanguage);
  };
  const resolveLang = i18n?.resolvedLanguage;
  useEffect(() => {
    if (!resolveLang) return;
    const parsedLang = parseSelectedLanguage(resolveLang);
    setAppLanguage(parsedLang);
  }, [resolveLang]);
  const isMakeAPiCall = !!(isUserPresent && appLanguage && isLanguageEnabled);
  React.useEffect(() => {
    window.__i18 = () => i18n;
    if (isMakeAPiCall) {
      // Do not update user profile, if preprod-tx is not present
      hdp.current();
    }
  }, [isMakeAPiCall]);
  const _ = useRef(changeI18DefaultLanguage);
  _.current = changeI18DefaultLanguage;
  useIsomorphicEffect(() => {
    // This should work to change pt-BR to en, for pre-prod & pre-prod-tx
    _.current();
  }, [isInitialized]);
  if (!isLanguageEnabled) return _jsx(_Fragment, {});
  return _jsx(_Fragment, {
    children: _jsx(ConfirmLanguageModal, {})
  });
};
export default DetectAppLanguage;