import { PLAYER_TYPE } from '@components/VideoPlayer/constants';
import { usePlayerStore } from '@components/VideoPlayer/store/usePlayerStore';
import { useEffect, useRef, useState } from 'react';
import * as workerTimers from 'worker-timers';
import { IPlayerResponse } from './usePlayer';
const {
  setIsMuted
} = usePlayerStore.getState();
const usePlayerAudioState = ({
  vodPlayerRef,
  livePlayerRef,
  isFirstChunkLoaded
}: {
  vodPlayerRef: IPlayerResponse;
  livePlayerRef: IPlayerResponse;
  isFirstChunkLoaded: boolean;
}) => {
  const activePlayerType = usePlayerStore(state => state.activePlayerType);
  const playerIsMuted = usePlayerStore(state => state.isMuted);
  const playerVolume = usePlayerStore(state => state.volume);
  const {
    stream
  } = usePlayerStore(state => state.playerProps);
  const [isEventAdded, setIsEventAdded] = useState(false);
  const [isVideoFailedToUnmute, setVideoFailedToUnmute] = useState(false);
  const isChannelLive = stream?.status === 20;
  const isLivePlayerActive = activePlayerType === PLAYER_TYPE.LIVE;
  const activePlayerRef = useRef(isLivePlayerActive ? livePlayerRef : vodPlayerRef);
  activePlayerRef.current = isLivePlayerActive ? livePlayerRef : vodPlayerRef;
  const inActivePlayerRef = useRef(!isLivePlayerActive ? livePlayerRef : vodPlayerRef);
  inActivePlayerRef.current = !isLivePlayerActive ? livePlayerRef : vodPlayerRef;
  const isLivePlayerAvailable = isChannelLive && !livePlayerRef.error && livePlayerRef.isReady;
  const isVodPlayerAvailable = !vodPlayerRef.error && vodPlayerRef.isReady;
  const isVideoLoaded = isFirstChunkLoaded && (isLivePlayerActive ? isLivePlayerAvailable : isVodPlayerAvailable);
  const isPlayerFailedToUnmute = isFirstChunkLoaded && activePlayerRef.current.isMuted && !playerIsMuted;
  useEffect(() => {
    // Condition, So that this will not trigger for every video every time
    setIsEventAdded(false);
  }, []);

  /**
   * @description: This will handle player mute state & volume change based on user input
   * @readonly: Important
   */
  useEffect(() => {
    if (isVideoLoaded) {
      inActivePlayerRef.current.setMuted(true);
      activePlayerRef.current.setMuted(playerIsMuted);
      activePlayerRef.current.setVolume(playerVolume);
    }
  }, [isVideoLoaded, playerIsMuted, playerVolume]);
  useEffect(() => {
    if (!isVideoFailedToUnmute || isEventAdded) {
      return;
    }
    const onPointerDown = () => {
      setIsMuted(false);
      setVideoFailedToUnmute(false);
      setIsEventAdded(true); // now we never handle this case again
    };

    window.addEventListener('touchend', onPointerDown, {
      passive: true
    });
    window.addEventListener('pointerdown', onPointerDown, {
      passive: true
    });
    return () => {
      window.removeEventListener('touchend', onPointerDown);
      window.removeEventListener('pointerdown', onPointerDown);
    };
  }, [isVideoFailedToUnmute, isEventAdded]);
  useEffect(() => {
    if (!isPlayerFailedToUnmute || isEventAdded) {
      return;
    }
    // If player Failed to unmute audio, we will change state
    const timer = workerTimers.setTimeout(() => {
      setVideoFailedToUnmute(true);
      setIsMuted(true);
    }, 250);
    return () => {
      workerTimers.clearTimeout(timer);
    };
  }, [isPlayerFailedToUnmute, isEventAdded]);
  return null;
};
export default usePlayerAudioState;