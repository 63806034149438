import { useEffect, useRef } from 'react';
enum DismissalEvents {
  VisibilityChange = 'visibilitychange',
  PageHide = 'pagehide',
  BeforeUnload = 'beforeunload',
}
export const browserCompatApi = () => {
  let hidden, visibilityChange;
  if ('hidden' in document) {
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else if ('mozHidden' in document) {
    // Firefox up to v17
    hidden = 'mozHidden';
    visibilityChange = 'mozvisibilitychange';
  } else if ('webkitHidden' in document) {
    // Chrome up to v32, Android up to v4.4, Blackberry up to v10
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  } else {
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  }
  return {
    hidden,
    visibilityChange
  };
};
const useOnPageVisbility = (sideEffectCbOnVisible: () => void, sideEffectCbOnHidden: () => void): void => {
  const sideEffectCbOnVisibleRef = useRef<any>(() => null);
  const sideEffectCbOnHiddenRef = useRef<any>(() => null);
  sideEffectCbOnVisibleRef.current = sideEffectCbOnVisible;
  sideEffectCbOnHiddenRef.current = sideEffectCbOnHidden;
  useEffect(() => {
    let terminatingEventSent = false;
    const {
      hidden,
      visibilityChange
    } = browserCompatApi();
    const onHideHandler = ({
      type
    }: Event): void => {
      if (type == DismissalEvents.VisibilityChange && document.visibilityState == 'visible') {
        sideEffectCbOnVisibleRef.current();
      }
      if (terminatingEventSent) return;
      if (type == DismissalEvents.BeforeUnload) {
        terminatingEventSent = true;
        sideEffectCbOnHiddenRef.current();
      }

      // pagehide fire before VisibilityChange so we are adding a flag and adding a check with it so two event doesn't get fire
      if (type === DismissalEvents.PageHide) {
        terminatingEventSent = true;
        sideEffectCbOnHiddenRef.current();
      }
      if (type == DismissalEvents.VisibilityChange && document.visibilityState == hidden) {
        sideEffectCbOnHiddenRef.current();
      }
    };
    document.addEventListener(visibilityChange, onHideHandler);
    window.addEventListener(DismissalEvents.PageHide, onHideHandler);
    window.addEventListener('beforeunload', onHideHandler);
    return (): void => {
      document.removeEventListener(visibilityChange, onHideHandler);
      window.removeEventListener(DismissalEvents.PageHide, onHideHandler);
      window.removeEventListener('beforeunload', onHideHandler);
    };
  }, []);
};
export default useOnPageVisbility;