function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { Button } from '@components/atoms/button';
import React from 'react';
import { TransI18 } from 'src/i18n';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
interface MatureContentBannerProps {
  thumbnailUrl: string | null;
  onAccept: () => void;
  onReject: () => void;
}
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1toifsr",
  styles: "position:relative;display:flex;height:100%;width:100%;align-items:center;justify-content:center"
} : {
  name: "bndzr3-MatureContentBanner",
  styles: "position:relative;display:flex;height:100%;width:100%;align-items:center;justify-content:center;label:MatureContentBanner;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "1ob7dfu",
  styles: "position:absolute;top:0px;right:0px;bottom:0px;left:0px"
} : {
  name: "3i13nw-MatureContentBanner",
  styles: "position:absolute;top:0px;right:0px;bottom:0px;left:0px;label:MatureContentBanner;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "txds98",
  styles: "position:relative;display:flex;flex-direction:column;align-items:center;gap:1.5rem"
} : {
  name: "irr9oy-MatureContentBanner",
  styles: "position:relative;display:flex;flex-direction:column;align-items:center;gap:1.5rem;label:MatureContentBanner;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "nqe40w",
  styles: "display:flex;flex-direction:column;align-items:center;justify-content:center;gap:0.75rem;@media (min-width: 768px){flex-direction:row;gap:1.5rem;}"
} : {
  name: "15443ub-MatureContentBanner",
  styles: "display:flex;flex-direction:column;align-items:center;justify-content:center;gap:0.75rem;@media (min-width: 768px){flex-direction:row;gap:1.5rem;};label:MatureContentBanner;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "1infa4c",
  styles: "margin-left:auto;margin-right:auto"
} : {
  name: "1in03a1-MatureContentBanner",
  styles: "margin-left:auto;margin-right:auto;label:MatureContentBanner;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "p5tmle",
  styles: "display:flex;flex-direction:column;gap:0.5rem"
} : {
  name: "1rf10ri-MatureContentBanner",
  styles: "display:flex;flex-direction:column;gap:0.5rem;label:MatureContentBanner;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "11rpgzn",
  styles: "text-align:center;font-size:12px;font-weight:700;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));line-height:20.75px;@media (min-width: 768px){text-align:left;font-size:16px;}"
} : {
  name: "1cfo4xq-MatureContentBanner",
  styles: "text-align:center;font-size:12px;font-weight:700;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));line-height:20.75px;@media (min-width: 768px){text-align:left;font-size:16px;};label:MatureContentBanner;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "1f1tqnh",
  styles: "white-space:nowrap;text-align:center;font-size:10px;font-weight:400;--tw-text-opacity:1;color:rgb(178 178 178 / var(--tw-text-opacity));line-height:18px;@media (min-width: 768px){text-align:left;font-size:12px;}"
} : {
  name: "lgrctr-MatureContentBanner",
  styles: "white-space:nowrap;text-align:center;font-size:10px;font-weight:400;--tw-text-opacity:1;color:rgb(178 178 178 / var(--tw-text-opacity));line-height:18px;@media (min-width: 768px){text-align:left;font-size:12px;};label:MatureContentBanner;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref9 = process.env.NODE_ENV === "production" ? {
  name: "12irccq",
  styles: "display:flex;width:100%;justify-content:center;gap:0.75rem;@media (min-width: 768px){justify-content:flex-start;gap:1rem;}"
} : {
  name: "hg7l3z-MatureContentBanner",
  styles: "display:flex;width:100%;justify-content:center;gap:0.75rem;@media (min-width: 768px){justify-content:flex-start;gap:1rem;};label:MatureContentBanner;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref10 = process.env.NODE_ENV === "production" ? {
  name: "430w2m",
  styles: "height:2rem;flex:1 1 0%;border-radius:10px;padding-top:0.5rem;padding-bottom:0.5rem;padding-left:0.75rem;padding-right:0.75rem;text-align:center;font-size:12px;font-weight:700;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));line-height:18.16px;@media (min-width: 768px){height:2.5rem;border-radius:0.5rem;--tw-bg-opacity:1;background-color:rgb(40 40 40 / var(--tw-bg-opacity));padding-top:0.625rem;padding-bottom:0.625rem;padding-left:1.5rem;padding-right:1.5rem;font-size:14px;}"
} : {
  name: "2h0tj-MatureContentBanner",
  styles: "height:2rem;flex:1 1 0%;border-radius:10px;padding-top:0.5rem;padding-bottom:0.5rem;padding-left:0.75rem;padding-right:0.75rem;text-align:center;font-size:12px;font-weight:700;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));line-height:18.16px;@media (min-width: 768px){height:2.5rem;border-radius:0.5rem;--tw-bg-opacity:1;background-color:rgb(40 40 40 / var(--tw-bg-opacity));padding-top:0.625rem;padding-bottom:0.625rem;padding-left:1.5rem;padding-right:1.5rem;font-size:14px;};label:MatureContentBanner;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const MatureContentBanner: React.FC<MatureContentBannerProps> = ({
  thumbnailUrl,
  onAccept,
  onReject
}) => {
  return _jsxs("div", {
    css: _ref,
    style: {
      backgroundImage: `url(${thumbnailUrl})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    },
    children: [_jsx("div", {
      css: _ref2,
      style: {
        backdropFilter: 'blur(24px) brightness(30%)'
      }
    }), _jsxs("div", {
      css: _ref3,
      children: [_jsxs("div", {
        css: _ref4,
        children: [_jsxs("div", {
          css: _ref5,
          children: [_jsxs("svg", {
            width: "62",
            height: "63",
            viewBox: "0 0 62 63",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
            className: "hidden md:block",
            children: [_jsx("rect", {
              y: "0.5",
              width: "62",
              height: "62",
              rx: "16",
              fill: "#FF5A12"
            }), _jsx("path", {
              d: "M31.3178 46C32.7759 46 33.9578 44.818 33.9578 43.36C33.9578 41.902 32.7759 40.72 31.3178 40.72H29.64C28.182 40.72 27 41.902 27 43.36C27 44.818 28.182 46 29.64 46H31.3178ZM30.5 38.52C32.433 38.52 34 36.953 34 35.02V20.5C34 18.567 32.433 17 30.5 17C28.567 17 27 18.567 27 20.5V35.02C27 36.953 28.567 38.52 30.5 38.52Z",
              fill: "white"
            })]
          }), _jsxs("svg", {
            width: "40",
            height: "40",
            viewBox: "0 0 62 63",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
            className: "md:hidden",
            children: [_jsx("rect", {
              y: "0.5",
              width: "62",
              height: "62",
              rx: "16",
              fill: "#FF5A12"
            }), _jsx("path", {
              d: "M31.3178 46C32.7759 46 33.9578 44.818 33.9578 43.36C33.9578 41.902 32.7759 40.72 31.3178 40.72H29.64C28.182 40.72 27 41.902 27 43.36C27 44.818 28.182 46 29.64 46H31.3178ZM30.5 38.52C32.433 38.52 34 36.953 34 35.02V20.5C34 18.567 32.433 17 30.5 17C28.567 17 27 18.567 27 20.5V35.02C27 36.953 28.567 38.52 30.5 38.52Z",
              fill: "white"
            })]
          })]
        }), _jsxs("div", {
          css: _ref6,
          children: [_jsx("h2", {
            css: _ref7,
            children: _jsx(TransI18, {
              i18nKey: "video_player.mature_content.title"
            })
          }), _jsx("div", {
            css: _ref8,
            children: _jsx(TransI18, {
              i18nKey: "video_player.mature_content.description",
              components: {
                Wrap: _jsx("p", {})
              }
            })
          })]
        })]
      }), _jsxs("div", {
        css: _ref9,
        children: [_jsx("button", {
          onClick: onReject,
          css: _ref10,
          children: _jsx(TransI18, {
            i18nKey: "video_player.mature_content.no"
          })
        }), _jsx(Button, {
          size: 'sm',
          title: _jsx(TransI18, {
            i18nKey: "video_player.mature_content.yes"
          }),
          variant: 'primary',
          onClick: onAccept,
          className: "flex-1 md:hidden"
        }), _jsx(Button, {
          size: 'md',
          title: _jsx(TransI18, {
            i18nKey: "video_player.mature_content.yes"
          }),
          variant: 'primary',
          onClick: onAccept,
          className: "hidden flex-1 md:flex"
        })]
      })]
    })]
  });
};
export default MatureContentBanner;