import create, { SetState } from 'zustand';
export enum LoginTypes {
  mandatory_login = 'mandatory_login',
  // download = 'download',
  button = 'button',
  prompt = 'prompt',
  nudge = 'nudge',
}
type ILoginNuggetStore = {
  loginType: null | LoginTypes;
  loginNuggetDefaultTime: number;
  loginNuggetTime: number;
  setLoginNuggetTime: (value: number) => void;
  setLoginNuggetDefaultTime: (value: number) => void;
  setLoginType: (value: LoginTypes | null) => void;
};
const store = (set: SetState<ILoginNuggetStore>): ILoginNuggetStore => ({
  loginType: null,
  setLoginType: value => set(() => ({
    loginType: value
  })),
  loginNuggetDefaultTime: 900,
  loginNuggetTime: 0,
  setLoginNuggetDefaultTime: value => set(() => ({
    loginNuggetDefaultTime: value
  })),
  setLoginNuggetTime: value => set(() => ({
    loginNuggetTime: value
  }))
});
export const useLoginNuggetStore = create<ILoginNuggetStore>(store);