import { LoginScreen, useAppStore } from '@stores/appStore';
import create, { SetState } from 'zustand';
import { OTP_ENPIRY_TIME } from '../constants';
type IModalType = null | 'PHONE_LOGIN' | 'PHONE_LINKING';
interface loginSliceStateInterface {
  isInitialized: boolean;
  modal_type: IModalType;
  code: string;
  phone: string;
  phoneLoading: boolean;
  phoneError: null | string;
  otpRetries: number; // to count retries
  otpMethod: 'SMS' | 'WHATSAPP';
  otpLoading: boolean;
  otpError: null | string;
  resendOtpTimer: number;
  setResendOtpTimer: (resendOtpTimer: number) => number;
  resetResendOtpTimer: () => void;
  setIsInitialized: (isInitialized: boolean) => void;
  setModalType: (modal_type: IModalType | null) => void;
  setPhoneNumberStart: (data: {
    code: string;
    phone: string;
  }) => void;
  setPhoneNumberError: (phoneError: string | null) => void;
  setOtpSendMethod: (otpMethod: 'SMS' | 'WHATSAPP') => void;
  setOtpFlowStart: () => void;
  setOtpRetries: (otpRetries: number) => void;
  setOtpFlowError: (otpError: string | null) => void;
}
const otplessStore = (set: SetState<loginSliceStateInterface>): loginSliceStateInterface => ({
  isInitialized: false,
  modal_type: null,
  code: '',
  phone: '',
  phoneLoading: false,
  phoneError: null,
  otpRetries: 0,
  otpMethod: 'SMS',
  otpLoading: false,
  otpError: null,
  resendOtpTimer: OTP_ENPIRY_TIME,
  setResendOtpTimer: resendOtpTimer => {
    set({
      resendOtpTimer
    });
    return resendOtpTimer;
  },
  resetResendOtpTimer: () => {
    set({
      resendOtpTimer: OTP_ENPIRY_TIME
    });
  },
  setIsInitialized: isInitialized => {
    set({
      isInitialized
    });
  },
  setModalType: modal_type => {
    const state: any = {};
    state.modal_type = modal_type;

    // reset all states
    state.code = '';
    state.phone = '';
    state.phoneLoading = false;
    state.phoneError = null;
    state.otpRetries = 0;
    state.otpMethod = 'SMS';
    state.otpLoading = false;
    state.otpError = null;
    set({
      ...state
    });
  },
  setPhoneNumberStart: data => {
    const state: any = {};
    state.code = data.code;
    state.phone = data.phone;
    state.phoneLoading = true;
    state.phoneError = null;
    set({
      ...state
    });
  },
  setPhoneNumberError: phoneError => {
    const state: any = {};
    state.phoneError = phoneError || null;
    state.phoneLoading = false;
    set({
      ...state
    });
  },
  setOtpSendMethod: otpMethod => {
    const state: any = {};
    state.otpMethod = otpMethod === 'WHATSAPP' ? 'WHATSAPP' : 'SMS';
    set({
      ...state
    });
  },
  setOtpFlowStart: () => {
    const state: any = {};
    state.otpLoading = true;
    state.otpError = null;
    set({
      ...state
    });
  },
  setOtpRetries: otpRetries => {
    const state: any = {};
    state.otpRetries = otpRetries;
    set({
      ...state
    });
  },
  setOtpFlowError: otpError => {
    const state: any = {};
    state.otpLoading = false;
    state.otpError = otpError || null;
    set({
      ...state
    });
  }
});
export const useOtplessStore = create<loginSliceStateInterface>(otplessStore);
const {
  setIsInitialized,
  setPhoneNumberStart,
  setPhoneNumberError,
  setModalType,
  setOtpFlowStart,
  setOtpSendMethod: _setOtpSendMethod,
  setOtpRetries,
  setOtpFlowError,
  resetResendOtpTimer,
  setResendOtpTimer
} = useOtplessStore.getState();
const openLoginLinkingModal = ({
  modal_type
}: {
  modal_type: IModalType;
}) => {
  if (modal_type === 'PHONE_LOGIN' || modal_type === 'PHONE_LINKING' || modal_type === null) {
    if (modal_type === null) {
      useAppStore.getState().set_login_screen(LoginScreen.LOGINOPTIONSSCREEN);
    }
    setModalType(modal_type);
  } else {
    throw new Error(`Invalid Modaltype:${modal_type} for otpless`);
  }
};
const setOtpSendMethod = ({
  otp_method
}: {
  otp_method: 'WHATSAPP' | 'SMS';
}) => {
  _setOtpSendMethod(otp_method);
};

// To Do not export all internal functions
export { openLoginLinkingModal, resetResendOtpTimer, setIsInitialized, setOtpFlowError, setOtpFlowStart, setOtpRetries, setOtpSendMethod, setPhoneNumberError, setPhoneNumberStart, setResendOtpTimer };