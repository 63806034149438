import { fetchMeProfileApi, googleLoginApi } from '@functions/api';
import { getSourceEventProperties } from '@layouts/stream/helpers';
import { Amplitude } from '@modules/analytics';
import * as eventConstants from '@modules/analytics/types/constants';
import { PAGES_ROUTE } from '@modules/analytics/types/constants';
import * as eventPropsTypes from '@modules/analytics/types/eventPropsTypes';
import { setLoginInfo } from '@modules/storage/loginInformation';
import * as Google_Login from '@react-oauth/google';
import { LoginScreen, useAppStore } from '@stores/appStore';
import { useAuthStore } from '@stores/authStore';
import { LoginTypes } from '@stores/loginNuggetStore';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'src/i18n';
import { updateUserLanguage } from 'src/i18n/ClientActions';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const {
  onSuspendedModalOpen,
  onLoginModalOpen,
  onLoginModalClose
} = useAppStore.getState();
const GoogleOneTapComponent = ({
  onSuccess,
  onFailure
}: {
  onSuccess: (result: Google_Login.CredentialResponse) => void;
  onFailure: (err?: string) => void;
}) => {
  const {
    isSuspendedModalOpen,
    isLoginModalOpen
  } = useAppStore(state => ({
    isSuspendedModalOpen: state.isSuspendedModalOpen,
    isLoginModalOpen: state.isLoginModalOpen
  }));
  const initialApiLoadingState = useAppStore(state => state.initialApiLoadingState);
  const router = useRouter();
  const userId = useAuthStore(state => state.me?.user_uid);
  const [isDisableGoogleOneTab, setDisableGoogleOneTab] = useState(true);
  const isReadNotification = useRef(false);
  const isHideModal = router.pathname === PAGES_ROUTE.ACTIVATE_PAGE ? true : initialApiLoadingState || !!userId; // hide if user present

  const googlePromptNotification = (notification: Google_Login.PromptMomentNotification) => {
    const momentType = notification.getMomentType();
    if (momentType === 'display') {
      // Is Display :: Firefox
      isReadNotification.current = true;
    }
    if (!isReadNotification.current) {
      return;
    }
    const isDismissedMoment = notification.isDismissedMoment();
    if (isDismissedMoment) {
      const getDismissedReason = notification.getDismissedReason();
      if (getDismissedReason === 'credential_returned') {
        // This is Success
        return;
      }
      onFailure(getDismissedReason);
      return;
    }

    // InCase of Skipped notifications
    const isSkippedMoment = notification.isSkippedMoment(); // present
    if (isSkippedMoment) {
      onFailure('skipped');
      return;
    }
  };
  useEffect(() => {
    isReadNotification.current = true;
    setDisableGoogleOneTab(true);
    let timer: NodeJS.Timeout;
    if (!isHideModal) {
      timer = setTimeout(() => {
        setDisableGoogleOneTab(false);
      }, 1000);
    }
    return () => {
      timer && clearTimeout(timer);
      isReadNotification.current = false;
    };
  }, [isHideModal]);
  useEffect(() => {
    if (isSuspendedModalOpen || isLoginModalOpen) {
      if (!isHideModal) {
        // enable disable one tap, if modal is already visible
        setDisableGoogleOneTab(true);
      }
    }
  }, [isLoginModalOpen, isSuspendedModalOpen, isHideModal]);
  Google_Login.useGoogleOneTapLogin({
    onSuccess: async (response: Google_Login.CredentialResponse) => {
      isReadNotification.current = false;
      onSuccess(response);
    },
    onError: () => {
      isReadNotification.current = false;
      onFailure();
    },
    promptMomentNotification: (notification: Google_Login.PromptMomentNotification) => {
      googlePromptNotification(notification);
    },
    use_fedcm_for_prompt: true,
    disabled: isDisableGoogleOneTab,
    cancel_on_tap_outside: false
  });
  return null;
};
const GoogleOneTap = () => {
  const alert = useAlert();
  const {
    t
  } = useTranslation();
  const setMe = useAuthStore(state => state.setMe);
  const setAccessToken = useAuthStore(state => state.setAccessToken);
  const setRefreshToken = useAuthStore(state => state.setRefreshToken);
  const setLoginBonus = useAuthStore(state => state.setLoginBonus);
  const set_login_screen = useAppStore(state => state.set_login_screen);
  const onFailureEvent = (reason?: string) => {
    const eventTrigger = useAppStore.getState().eventTrigger;
    const pageInfo = useAppStore.getState().pageInfo;
    const prevSourceTrackingInfo = useAppStore.getState().prevSourceTrackingInfo;
    const sourceEventProperties = getSourceEventProperties(prevSourceTrackingInfo);
    const params: eventPropsTypes.web_login_event = {
      email: undefined,
      login_mode: 'google_one_tap',
      response: 'fail',
      failure_msg: reason || 'unknown_reason',
      trigger: eventTrigger || '',
      nudged_on: pageInfo.page,
      login_type: LoginTypes.nudge,
      streamer_name: pageInfo.streamerName,
      streamer_id: pageInfo.streamerId,
      category_name: pageInfo.categoryImage,
      category_id: pageInfo.categoryId,
      video_id: pageInfo.videoId,
      web_page_type: pageInfo.page,
      ...sourceEventProperties
    };
    Amplitude.logEvent(eventConstants.web_login_event, params);
  };
  return _jsx(GoogleOneTapComponent, {
    onSuccess: async response => {
      if (response?.credential) {
        const eventTrigger = useAppStore.getState().eventTrigger;
        const pageInfo = useAppStore.getState().pageInfo;
        const prevSourceTrackingInfo = useAppStore.getState().prevSourceTrackingInfo;
        const sourceEventProperties = getSourceEventProperties(prevSourceTrackingInfo);
        let userEmail: string | undefined = undefined;
        try {
          const tokenInfo = response?.credential && window.atob(response?.credential?.split('.')[1]) || '{}';
          const parsedInfo = JSON.parse(tokenInfo);
          userEmail = parsedInfo?.email;
        } catch (err) {}
        const params: eventPropsTypes.web_login_event = {
          email: userEmail,
          login_mode: 'google_one_tap',
          response: 'success',
          failure_msg: undefined,
          trigger: eventTrigger || '',
          nudged_on: pageInfo.page,
          login_type: LoginTypes.nudge,
          streamer_name: pageInfo.streamerName,
          streamer_id: pageInfo.streamerId,
          category_name: pageInfo.categoryImage,
          category_id: pageInfo.categoryId,
          video_id: pageInfo.videoId,
          web_page_type: pageInfo.page,
          ...sourceEventProperties
        };
        Amplitude.logEvent(eventConstants.web_login_event, params);
        window?.dataLayer?.push?.({
          event: 'Login_Form_Website',
          ...params
        });
        const result = await googleLoginApi(response?.credential);
        if (result.access_token && result.refresh_token) {
          setAccessToken(result.access_token);
          setRefreshToken(result.refresh_token);
          setLoginBonus(result.login_bonus?.amount);
          const meApiResult: any = await fetchMeProfileApi();
          if (!meApiResult?.statusCode) {
            setMe(meApiResult); // Here we only set data from me-profile api. Then add more data
            setLoginInfo({
              //@ts-ignore
              email: response?.profileObj?.email,
              userUid: meApiResult.user_uid
            });
          }
          if (result.login_bonus) {
            onLoginModalOpen();
            set_login_screen(LoginScreen.UPDATEPROFILE);
          } else {
            updateUserLanguage();
            set_login_screen(LoginScreen.LOGINOPTIONSSCREEN);
            onLoginModalClose();
          }
        } else if (result?.statusCode == 401 && result?.error_code == 'F001') {
          set_login_screen(LoginScreen.LOGINOPTIONSSCREEN);
          onLoginModalClose();
          onSuspendedModalOpen();
        } else {
          alert.success(t('stream_page.toast_popup.login_errors.failed') + ' ' + t('stream_page.toast_popup.login_errors.retry'));
        }
      } else {
        onFailureEvent();
        alert.success(t('stream_page.toast_popup.login_errors.generic') + ' ' + t('stream_page.toast_popup.login_errors.retry'));
      }
    },
    onFailure: onFailureEvent
  });
};
export default GoogleOneTap;