function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import React, { useEffect, useState } from 'react';
import * as workerTimers from 'worker-timers';
import { usePrefetchImageStore } from './PreFetchImageStore';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const {
  getDownloadedImage
} = usePrefetchImageStore.getState();
interface IProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  __test?: string;
  alt: string;
  src: string;
}
var _ref = process.env.NODE_ENV === "production" ? {
  name: "h97vxs",
  styles: "position:relative;margin:0px;padding:0px"
} : {
  name: "175udyh-CacheImage",
  styles: "position:relative;margin:0px;padding:0px;label:CacheImage;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "655ctd",
  styles: "position:absolute;top:0px;right:0px;bottom:0px;left:0px;display:flex;height:100%;width:100%;align-items:center;justify-content:center"
} : {
  name: "9teqnv-CacheImage",
  styles: "position:absolute;top:0px;right:0px;bottom:0px;left:0px;display:flex;height:100%;width:100%;align-items:center;justify-content:center;label:CacheImage;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "1c87443",
  styles: "position:absolute;top:0px;right:0px;bottom:0px;left:0px;height:100%;width:100%;--tw-bg-opacity:1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));opacity:0.3"
} : {
  name: "ogqm2e-CacheImage",
  styles: "position:absolute;top:0px;right:0px;bottom:0px;left:0px;height:100%;width:100%;--tw-bg-opacity:1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));opacity:0.3;label:CacheImage;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const CacheImage = (props: IProps) => {
  const {
    src: defaultSrc
  } = props;
  const isImageDownloaded = getDownloadedImage(defaultSrc, {
    cache_image_only: true
  });
  // if Img already downloaded, show that image, instead use default https url
  const [imgSrc, setImgSrc] = useState('');
  const [isImgLoaded, setImgLoaded] = useState(false);
  useEffect(() => {
    setImgLoaded(true);
    let timerRef: null | number = null;
    setImgSrc(isImageDownloaded || defaultSrc);
    if (!isImageDownloaded) {
      timerRef = workerTimers.setTimeout(() => {
        if (!isImageDownloaded) {
          getDownloadedImage(defaultSrc);
        }
      }, 250);
    }
    return () => {
      timerRef !== null && workerTimers.clearTimeout(timerRef);
    };
  }, [defaultSrc, isImageDownloaded]);
  return _jsx("div", {
    css: _ref,
    style: {
      backgroundColor: 'rgba(255,255,255)'
    },
    children: _jsxs(_Fragment, {
      children: [imgSrc ? _jsx("img", {
        ...props,
        src: imgSrc,
        alt: props.alt,
        onLoadedData: () => {
          if (imgSrc === defaultSrc || imgSrc === isImageDownloaded) {
            // Loading Same Image
            setImgLoaded(false);
          }
        }
      }) :
      // If height or width id defined, then empty div is show there with same dimention
      // @ts-ignore
      _jsx("div", {
        ...props
      }), !imgSrc || !isImgLoaded || imgSrc !== defaultSrc && imgSrc !== isImageDownloaded ?
      // No Img present, Img is loading, ImgUrl is changed
      // Show Loaded, If Image is Different, Or we downloading the Image
      _jsxs("div", {
        css: _ref2,
        children: [_jsx("div", {
          css: _ref3
        }), _jsx("div", {
          className: "spinner",
          children: _jsx("div", {
            className: "rotate",
            children: _jsxs("svg", {
              viewBox: "0 0 100 100",
              children: [_jsx("clipPath", {
                id: "clip",
                children: _jsx("path", {
                  d: "M 50 0 a 50 50 0 0 1 0 100 50 50 0 0 1 0 -100 v 8 a 42 42 0 0 0 0 84 42 42 0 0 0 0 -84"
                })
              }), _jsx("foreignObject", {
                x: "0",
                y: "0",
                width: "100",
                height: "100",
                clipPath: "url(#clip)",
                children: _jsx("div", {
                  style: {
                    width: '100px',
                    height: '100px',
                    borderRadius: '50%',
                    background: 'conic-gradient(from 180deg at 50% 50%, #FFFFFF 0deg, rgba(255, 255, 255, 0) 360deg)'
                  }
                })
              })]
            })
          })
        })]
      }) : _jsx(_Fragment, {})]
    })
  });
};
export default CacheImage;