import { getCookie } from '@utils/cookies';
import { fetchWithAllErrorHandle } from '../apiWrapper';
const serverUrl = process.env.SERVER_URL;
const yenServerUrl = process.env.YEN_SERVER;
export const fetchTopRanks = async (streamer_uid: string, board_type: number, leaderboard_offset: number, limit: number, offset: number): Promise<any> => {
  const authToken = (getCookie('access_token') as string);
  const result = await fetchWithAllErrorHandle.url(`${serverUrl}/leaderboard/paginated/${streamer_uid}/?leaderboard_offset=${leaderboard_offset}&leaderboard_type=${board_type}&limit=${limit}&offset=${offset}`).headers({
    Authorization: authToken
  }).get().json(json => {
    return json;
  }).catch((error: Error | null) => {
    return error;
  });
  return result;
};
export const fetchUserRank = async (streamer_uid: string, board_type: number, leaderboard_offset: number): Promise<any> => {
  const authToken = (getCookie('access_token') as string);
  const result = await fetchWithAllErrorHandle.url(`${serverUrl}/leaderboard/rank/${streamer_uid}/?leaderboard_offset=${leaderboard_offset}&leaderboard_type=${board_type}`).headers({
    Authorization: authToken
  }).get().json((json: any) => {
    return json;
  }).catch((error: Error | null) => {
    return error;
  });
  return result;
};
export const getRewardInfo = async (transaction_id: string): Promise<any> => {
  const authToken = (getCookie('access_token') as string);
  const result = await fetchWithAllErrorHandle.url(`${yenServerUrl}/medals/${transaction_id}/details/`).headers({
    Authorization: authToken
  }).get().json(json => {
    return json;
  }).catch((error: Error | null) => {
    return error;
  });
  return result;
};

// {
//   "rank": "#1",
//   "medal_icon_url": "https://leaderboard-medals.s3.ap-south-1.amazonaws.com/Rank_1_Trophy%403x.png",
//   "card_desc": "Dark.Skull835 was #1 supporter of UNTEKA.VKBHRY1O for the month Jul 2021",
//   "card_title": "Woohoo!!",
//   "user_name": "Dark.Skull835",
//   "user_avatar_url": "https://s3-ap-southeast-1.amazonaws.com/showtime-development/loco-avatars/fd7c6937dd0e4191a1f9e4e0eec0d44f.jpg",
//   "streamer_avatar_url": "https://s3-ap-southeast-1.amazonaws.com/showtime-development/thumbnail/users/923b5342950e4321b7201dffcfc2592e.png"
//  "streamer_id":"someid"
// }

export const getAllMedals = async (): Promise<any> => {
  const authToken = (getCookie('access_token') as string);
  const result = await fetchWithAllErrorHandle.url(`${yenServerUrl}/medals/instream_leaderboard/client/?limit=20`).headers({
    Authorization: authToken
  }).get().json(json => {
    return json;
  }).catch((error: Error | null) => {
    return error;
  });
  return result;
};