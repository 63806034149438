import * as amplitude from '@amplitude/analytics-browser';
import { LogLevel } from '@amplitude/analytics-types';
import { userAgentEnrichmentPlugin } from '@amplitude/plugin-user-agent-enrichment-browser';
import { IEventProps } from './types/eventPropsTypes';
const DEFAULT_CONFIG = {
  logLevel: process.env.NEXT_PUBLIC_APP_STAGE === 'production' ? LogLevel.None : LogLevel.Warn,
  cookieOptions: {
    expiration: 365
  },
  // Expires in 1 year (would fallback to 10 years by default, which isn't GDPR compliant),
  attribution: {
    disabled: true
  },
  defaultTracking: {
    formInteractions: false,
    pageViews: false,
    sessions: false
  }
};
class Amplitude {
  private amplitudeInstance: typeof amplitude;
  private initialized: boolean;
  constructor(config: {
    key: string;
  }) {
    this.amplitudeInstance = amplitude;
    this.initialized = false;
    if (!process.browser || !config.key) {
      return;
    }
    const uaPlugin = userAgentEnrichmentPlugin({
      osName: true,
      osVersion: true,
      deviceManufacturer: true,
      deviceModel: true
    });
    this.amplitudeInstance.add(uaPlugin);
    this.amplitudeInstance.init(config.key, undefined, DEFAULT_CONFIG);
    this.initialized = true;
  }
  public setUserId(userId?: string | null): void {
    this.amplitudeInstance.setUserId(userId || undefined);
  }
  public getDeviceId = () => this.amplitudeInstance.getDeviceId();
  public async reset(): Promise<void> {
    // Waiting for some time So that all the events will linked with previous user fired
    return new Promise(res => {
      return setTimeout(() => {
        this.amplitudeInstance.reset();
        // Assign new session ID, so that all events bind to this session only
        this.amplitudeInstance.setSessionId(Date.now());
        res();
      }, 100);
    });
  }
  public setIdentity(identityObj: IEventProps): void {
    if (!identityObj) {
      return;
    }
    const identify = new this.amplitudeInstance.Identify();
    Object.entries(identityObj).forEach(([key, value]) => {
      identify.set(key, value);
    });
    this.amplitudeInstance.identify(identify);
  }
  public addIdentity(identityObj: IEventProps): void {
    if (!identityObj) {
      return;
    }
    const identify = new this.amplitudeInstance.Identify();
    Object.entries(identityObj).forEach(([key, value]) => {
      identify.add(key, value);
    });
    this.amplitudeInstance.identify(identify);
  }
  public setUserProperties(identityObj: IEventProps): void {
    this.setIdentity(identityObj);
  }
  public logEvent(eventName: string, properties: IEventProps): void {
    this.amplitudeInstance.track(eventName, {
      ...properties
    });
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: eventName,
        ...properties
      });
    }
  }
}
export default Amplitude;