import * as React from 'react';
import * as WorkerTimers from 'worker-timers';
export const useDelayEffectWithCleanup = (callback: React.EffectCallback, cleanup?: () => void, dependencies?: React.DependencyList, timeout?: number) => {
  const callbackRef = React.useRef(callback);
  callbackRef.current = callback;
  React.useEffect(() => {
    let fallbackToClear: any = null;
    const timerRef = WorkerTimers.setTimeout(() => {
      fallbackToClear = callbackRef.current();
    }, timeout || 150);
    return () => {
      if (typeof cleanup === 'function') {
        cleanup();
      }
      if (typeof fallbackToClear === 'function') {
        fallbackToClear();
      }
      WorkerTimers.clearTimeout(timerRef);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(dependencies || []), timeout]);
};
export const useDelayEffect = (callback: React.EffectCallback, dependencies?: React.DependencyList, timeout?: number) => {
  useDelayEffectWithCleanup(callback, undefined, dependencies, timeout);
};
export default useDelayEffect;