import { getCookie } from '@utils/cookies';
import { fetchWithAllErrorHandle } from './apiWrapper';
const SERVER_URL = (process.env.SERVER_URL as string);
interface UserSettings {
  can_choose_avatar: number; // or boolean if you meant to use 1 as true and 0 as false
  can_stream: number; // or boolean
  can_upload_pic: number; // or boolean
  show_achievements: number; // or boolean
  show_following: number; // or boolean
  show_past_streams: number; // or boolean
  show_watchlist: number; // or boolean
  is_loco_verified: number; // or boolean
  initial_tab: string;
  is_vip: boolean;
  is_ad_free: boolean;
  vip_expiration_time: number;
  ad_expiration_time: number;
  leaderboard_url: string;
  vip_type: 'non-vip' | 'expired' | 'active'; //
}

export const getUserPermission = async () => {
  const Authorization = getCookie('access_token');
  const result = await fetchWithAllErrorHandle.url(`${SERVER_URL}/profile/me/permissions/`).headers({
    Authorization: Authorization!
  }).get().json((json: any) => {
    return json?.data;
  }).catch((error: Error | null) => {
    return error;
  });
  return (result as UserSettings);
};