function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import FadeEffect from '@components/FadeEffectElement';
import { usePlayerStore } from '@components/VideoPlayer/store/usePlayerStore';
import { useDeviceInfo } from '@hooks/react-use';
import { useStreamStore } from '@stores/streamStore';
import React from 'react';
import Lottie from 'react-lottie';
import animationData from './PianoLoadingAnimation.json';

// This component is used in whole locogg directory
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "xyyuvf",
  styles: "position:absolute;height:100%;width:100%"
} : {
  name: "1745bnf-getComponent",
  styles: "position:absolute;height:100%;width:100%;label:getComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "1vtbwhp",
  styles: "position:absolute;height:100%;width:100%;transition-property:all;transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transition-duration:150ms"
} : {
  name: "1rptesu-getComponent",
  styles: "position:absolute;height:100%;width:100%;transition-property:all;transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transition-duration:150ms;label:getComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "nffb2y",
  styles: "position:absolute;z-index:1"
} : {
  name: "n1o200-getComponent",
  styles: "position:absolute;z-index:1;label:getComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const PlayerSpinner = ({
  isVisible,
  size: defaultSize
}: {
  isVisible?: boolean;
  size?: number;
}) => {
  const {
    screen_isMobile
  } = useDeviceInfo();
  const isFullScreen = useStreamStore(state => state.isFullScreen);
  const isPortalOpen = usePlayerStore(state => state.isPortalOpen);
  /**
   * Sizes
   * Web 84x84
   * FullScreen 120x120
   * Mini Player 72x72
   * mWeb 56x56
   */
  const size = defaultSize ? defaultSize : screen_isMobile ? isFullScreen ? 72 // mweb full screen
  : 56 // mweb
  : isFullScreen ? 120 // web fullscreen
  : isPortalOpen ? 72 // mini player
  : 84; // web

  const options = {
    loop: true,
    autoplay: true,
    animationData: animationData
  };
  const getComponent = (isVisible: boolean) => _jsxs("div", {
    css: _ref,
    children: [_jsx("div", {
      css: _ref2,
      style: {
        background: isVisible ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0)',
        visibility: isVisible ? 'visible' : 'hidden',
        opacity: isVisible ? 0.4 : 0
      }
    }), _jsx("div", {
      css: _ref3,
      style: {
        height: size,
        width: size,
        left: `calc(50% - ${size / 2}px)`,
        top: `calc(50% - ${size / 2}px)`
      },
      children: _jsx(Lottie, {
        options: options,
        height: size,
        width: size
      })
    })]
  });
  if (typeof isVisible === 'boolean') {
    return _jsx(FadeEffect, {
      isOpen: isVisible,
      children: ({
        isVisible
      }) => getComponent(isVisible)
    });
  }
  return getComponent(true);
};
export default PlayerSpinner;