import { StreamInterface } from '@customTypes/index';
import { logLiveView, logVodView } from '@functions/stream';
import useOnPageVisbility from '@hooks/useOnPageVisbility';
import { useStreamStore } from '@stores/streamStore';
import { usePlayerEventStore } from '@stores/usePlayerEvents';
import { streamView } from '@utils/gtm';
import { useEffect, useRef, useState } from 'react';
import { unicornLogFun, unicornVisitLogFun } from './unicornLogFun';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export default function LogEvents() {
  const stream = useStreamStore(state => state.stream);
  const currentStreamRef = useRef(stream);
  currentStreamRef.current = stream;
  const total_watch_duration = usePlayerEventStore(stream?.uid || '')(state => Math.round(state.total_watch_duration));
  const isDurationIs10 = total_watch_duration === 10;
  const streamUid = stream?.uid || null;
  const isLiveVideo = stream?.status === 20;
  const [eventCalled, setEventCalled] = useState(false);
  useOnPageVisbility(
  // Will trigger then page come to visible from hidden // not on initialization
  () => unicornVisitLogFun(),
  // Will trigger then page go to hidden from visible
  () => unicornLogFun(stream?.uid));
  useEffect(() => {
    if (streamUid) {
      // Reset State when stream is presnet / changed
      setEventCalled(false);
    }
  }, [streamUid]);
  useEffect(() => {
    function fetchLogViewApi() {
      if (isDurationIs10 && streamUid) {
        if (isLiveVideo) {
          logLiveView(streamUid);
        } else {
          logVodView(streamUid);
        }
      }
    }
    fetchLogViewApi();
  }, [streamUid, isLiveVideo, isDurationIs10]);
  useEffect(() => {
    if (total_watch_duration > 30 && !eventCalled && streamUid) {
      streamView((currentStreamRef.current as StreamInterface));
      setEventCalled(true);
    }
  }, [eventCalled, total_watch_duration, streamUid]);
  return _jsx("div", {});
}