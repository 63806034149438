function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { Button } from '@components/atoms/button';
import Modal from '@components/core/modal/Modal';
import { setLocoggDomainCookie, updateProfile } from '@functions/api';
import { useDeviceInfo } from '@hooks/react-use';
import { getSourceEventProperties } from '@layouts/stream/helpers';
import { Amplitude } from '@modules/analytics';
import { useAppStore } from '@stores/appStore';
import { useAuthStore } from '@stores/authStore';
import dayjs from 'dayjs';
import React, { useRef } from 'react';
import { LANGUAGE_PRESET, SUPPORTED_LANGUAGE_FULL_NAME } from '../constants_i18n';
import { TransI18, useTranslation } from '../i18n';
import { parseSelectedLanguage } from '../utils_i18n';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const setAppLanguage = useAppStore.getState().setAppLanguage;
var _ref = process.env.NODE_ENV === "production" ? {
  name: "p92thh",
  styles: "position:absolute;top:0px;right:0px;z-index:50;margin:0px;display:none;--tw-translate-y:-46px;transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));align-items:center;justify-content:center;gap:0.25rem;padding:0px;@media (min-width: 768px){display:flex;}"
} : {
  name: "1t8y2bk-ConfirmLanguageModal",
  styles: "position:absolute;top:0px;right:0px;z-index:50;margin:0px;display:none;--tw-translate-y:-46px;transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));align-items:center;justify-content:center;gap:0.25rem;padding:0px;@media (min-width: 768px){display:flex;};label:ConfirmLanguageModal;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "406tr9",
  styles: "display:flex;cursor:pointer;align-items:center;justify-content:center;padding:0.5rem"
} : {
  name: "3p44pp-ConfirmLanguageModal",
  styles: "display:flex;cursor:pointer;align-items:center;justify-content:center;padding:0.5rem;label:ConfirmLanguageModal;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "1xp7sb2",
  styles: "height:1.5rem;width:1.5rem;min-width:24px"
} : {
  name: "b5uia-ConfirmLanguageModal",
  styles: "height:1.5rem;width:1.5rem;min-width:24px;label:ConfirmLanguageModal;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "1njyut",
  styles: "position:relative;margin-left:auto;margin-right:auto;height:auto;width:100%;--tw-bg-opacity:1;background-color:rgb(40 40 40 / var(--tw-bg-opacity));--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity))"
} : {
  name: "ixqgmx-ConfirmLanguageModal",
  styles: "position:relative;margin-left:auto;margin-right:auto;height:auto;width:100%;--tw-bg-opacity:1;background-color:rgb(40 40 40 / var(--tw-bg-opacity));--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));label:ConfirmLanguageModal;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "m2sr57",
  styles: "display:flex;padding-bottom:28px;@media (min-width: 768px){display:none;}"
} : {
  name: "mbbjoc-ConfirmLanguageModal",
  styles: "display:flex;padding-bottom:28px;@media (min-width: 768px){display:none;};label:ConfirmLanguageModal;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "11v0pe8",
  styles: "margin-left:auto;margin-right:auto;height:0.375rem;width:6rem;border-radius:13px;--tw-bg-opacity:1;background-color:rgb(76 76 76 / var(--tw-bg-opacity))"
} : {
  name: "18amqee-ConfirmLanguageModal",
  styles: "margin-left:auto;margin-right:auto;height:0.375rem;width:6rem;border-radius:13px;--tw-bg-opacity:1;background-color:rgb(76 76 76 / var(--tw-bg-opacity));label:ConfirmLanguageModal;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "1wnfjpe",
  styles: "display:flex;flex-direction:column;align-items:center;justify-content:center;gap:0.75rem"
} : {
  name: "z43kqs-ConfirmLanguageModal",
  styles: "display:flex;flex-direction:column;align-items:center;justify-content:center;gap:0.75rem;label:ConfirmLanguageModal;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "h9yby7",
  styles: "text-align:center;font-size:20px;font-weight:700;line-height:25.94px"
} : {
  name: "7gjnyw-ConfirmLanguageModal",
  styles: "text-align:center;font-size:20px;font-weight:700;line-height:25.94px;label:ConfirmLanguageModal;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref9 = process.env.NODE_ENV === "production" ? {
  name: "1vj31cc",
  styles: "margin-top:0.5rem;margin-bottom:0.5rem;text-align:center;font-size:16px;font-weight:400;--tw-text-opacity:1;color:rgb(178 178 178 / var(--tw-text-opacity));line-height:20.75px"
} : {
  name: "1g5noaz-ConfirmLanguageModal",
  styles: "margin-top:0.5rem;margin-bottom:0.5rem;text-align:center;font-size:16px;font-weight:400;--tw-text-opacity:1;color:rgb(178 178 178 / var(--tw-text-opacity));line-height:20.75px;label:ConfirmLanguageModal;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const ConfirmLanguageModal = () => {
  const {
    screen_isDesktop
  } = useDeviceInfo();
  const {
    i18n
  } = useTranslation();
  const langRef = useRef<LANGUAGE_PRESET>('en');
  const isShowLanguageChangeModal = useAppStore(state => state.isShowLanguageChangeModal);
  const setIsShowLanguageChangeModal = useAppStore(state => state.setIsShowLanguageChangeModal);
  const setIsLanguageSelectionOpen = useAppStore(state => state.setIsLanguageSelectionOpen);
  const handleClose = () => {
    setIsShowLanguageChangeModal(null);
    setIsLanguageSelectionOpen(false);
  };
  const triggerClickEvent = (lang: string) => {
    const me = useAuthStore.getState().me;
    const sourceTrackingInfo = useAppStore.getState().sourceTrackingInfo;
    const sourceEventProperties = getSourceEventProperties(sourceTrackingInfo);
    const language_client = lang;
    Amplitude.logEvent('language_change_client', {
      ...sourceEventProperties,
      username: me?.username,
      userid: me?.user_uid,
      user_type: me?.user_type,
      language_client,
      trigger: 'user_choice',
      //user_choice, client_override
      source: 'settings' // this modal can only be opened from Profile Setting
    });
  };

  const onLanguageChange = () => {
    if (!isShowLanguageChangeModal) return;
    const lang = parseSelectedLanguage(isShowLanguageChangeModal);
    dayjs.locale(lang);
    setLocoggDomainCookie({
      lang: lang
    });
    i18n.changeLanguage(lang);
    setAppLanguage(lang);
    handleClose();
    updateProfile({
      language: lang
    }).then(() => {
      useAuthStore.getState().updateMe({
        language: lang
      });
    });
    triggerClickEvent(lang);
  };
  langRef.current = isShowLanguageChangeModal || langRef.current;
  return _jsxs(Modal, {
    visible: !!isShowLanguageChangeModal,
    onClose: handleClose,
    customStyles: {
      width: !screen_isDesktop ? '100%' : '400px',
      height: 'fit-content'
    },
    closeOnEsc: true,
    children: [_jsx("div", {
      css: _ref,
      children: _jsx("button", {
        css: _ref2,
        onClick: () => {
          handleClose();
        },
        children: _jsx("img", {
          src: "/new_close_icon.png",
          alt: "close",
          css: _ref3
        })
      })
    }), _jsxs("div", {
      css: _ref4,
      children: [_jsx("div", {
        css: _ref5,
        children: _jsx("div", {
          css: _ref6,
          onClick: () => {
            handleClose();
          },
          role: "presentation"
        })
      }), _jsxs("div", {
        css: _ref7,
        children: [_jsxs("div", {
          css: _ref8,
          children: [_jsx(TransI18, {
            i18nKey: "change_language.change_language_to",
            options: {
              language_full_name: ''
            }
          }), screen_isDesktop ? _jsx(_Fragment, {
            children: " "
          }) : _jsx("br", {}), _jsx("span", {
            children: SUPPORTED_LANGUAGE_FULL_NAME[langRef.current]
          })]
        }), _jsx("div", {
          css: _ref9,
          children: _jsx(TransI18, {
            i18nKey: "change_language.change_language_description"
          })
        }), _jsx(Button, {
          onClick: onLanguageChange,
          variant: 'primary',
          title: _jsx(TransI18, {
            i18nKey: "common.confirm"
          }),
          className: "w-full"
        }), _jsx(Button, {
          onClick: handleClose,
          variant: 'text',
          title: _jsx(TransI18, {
            i18nKey: "common.cancel"
          }),
          className: "bg-transparent"
        })]
      })]
    })]
  });
};
export default ConfirmLanguageModal;