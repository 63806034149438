import { fetchMeProfileApi, meApi, registerDeviceProfile } from '@functions/api';
import { getProfileInfo, getWalletDetails } from '@functions/profile';
import { Amplitude } from '@modules/analytics';
import { resetAmplitudeUser } from '@modules/analytics/getAmplitude';
import { removeLoginInfo } from '@modules/storage/loginInformation';
import * as Sentry from '@sentry/nextjs';
import { useAppStore } from '@stores/appStore';
import { useAuthStore } from '@stores/authStore';
import { useProfileStore } from '@stores/profileStore';
import { getCookie } from '@utils/cookies';
import * as React from 'react';
export default function useHandleLogin() {
  const setMe = useAuthStore(state => state.setMe);
  const setAccessToken = useAuthStore(state => state.setAccessToken);
  const setRefreshToken = useAuthStore(state => state.setRefreshToken);
  const user_uid = useAuthStore(state => state.me?.user_uid);
  const updateMe = useAuthStore(state => state.updateMe);
  const setWalletLoading = useProfileStore(state => state.setWalletLoading);
  const setWallet = useProfileStore(state => state.setWallet);
  const accessToken = useAuthStore(state => state.accessToken);
  const setInitialApiLoadingState = useAppStore(state => state.setInitialApiLoadingState);
  const postLoginCallback = () => {
    async function fetchWalletDetails(): Promise<void> {
      setWalletLoading(true);
      const res = await getWalletDetails();
      if (res.self_wallet) {
        setInitialApiLoadingState(false);
        setWallet({
          gold: res.self_wallet.gold,
          diamond: res.self_wallet.diamond
        });
      }
      setWalletLoading(false);
    }
    async function _getProfileInfo(): Promise<void> {
      const [profileResp, meResp] = await Promise.allSettled([getProfileInfo(), meApi()]);
      const profileResult = profileResp.status === 'fulfilled' ? profileResp.value : {};
      const meResult = meResp.status === 'fulfilled' ? meResp.value : {};
      if (profileResult || meResult) {
        const currnetMe = useAuthStore.getState().me || {};
        updateMe(Object.assign({}, {
          is_username_changed: profileResult?.is_username_changed,
          social_link_info: profileResult?.social_link_info,
          can_stream: meResult?.can_stream,
          profile_tags: meResult?.profile_tags,
          game_ids: meResult?.game_ids,
          is_clip_upload_enabled: meResult?.is_clip_upload_enabled
        }, currnetMe, profileResult, meResult));
        setInitialApiLoadingState(false);
      }
      if (profileResult.error_code == 'F001') {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        window.location.reload();
      }
    }
    fetchWalletDetails();
    _getProfileInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  React.useEffect(() => {
    async function handleAuth(): Promise<void> {
      setInitialApiLoadingState(true);
      const mode = getCookie('mode');
      if (mode === 'logged-in') {
        const result = await fetchMeProfileApi();
        // Force logout event if meApi fails
        if (result.statusCode && result.statusCode !== 200 || result.status_code && result.status_code !== 200) {
          const logoutErr = new Error(result.message ?? 'Logout Error');
          const bugData = {
            forced: true,
            api_failed: '/user/me-profile/',
            via_refresh_token: false,
            stack: result,
            reason: result?.message ?? 'Unknown Error'
          };
          Sentry.captureException(logoutErr, {
            extra: {
              statusCode: result.statusCode ?? result.status_code,
              message: result.message,
              subMessage: result.subMessage ?? 'No extra information',
              ...bugData
            }
          });
          Amplitude.logEvent('logout', bugData);
          await resetAmplitudeUser();
        }
        if (result?.username) {
          setMe(result); // Here we only set data from me-profile api. Then add more data
          if (getCookie('access_token')) {
            setAccessToken((getCookie('access_token') as string));
          }
          if (getCookie('refresh_token')) {
            setRefreshToken((getCookie('refresh_token') as string));
          }
        } else {
          removeLoginInfo();
          const result = await registerDeviceProfile();
          setInitialApiLoadingState(false);
          if (result?.access_token && result?.refresh_token) {
            setAccessToken(result.access_token);
            setRefreshToken(result.refresh_token);
          }
        }
      } else {
        removeLoginInfo();
        const result = await registerDeviceProfile();
        setInitialApiLoadingState(false);
        if (result?.access_token && result?.refresh_token) {
          setAccessToken(result.access_token);
          setRefreshToken(result.refresh_token);
        }
      }
    }
    const timer = setTimeout(() => {
      handleAuth();
    }, 10);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isUserPresent = !!(user_uid && accessToken);
  React.useEffect(() => {
    if (isUserPresent) {
      postLoginCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserPresent]);
  return null;
}