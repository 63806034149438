type RouteObjectType = {
  [id: string]: string;
};
export function getURLParams(url?: string): RouteObjectType {
  const queryString = window.location.search;
  const urlParams = url ? new URL(url).searchParams : new URLSearchParams(queryString);
  const routeObject: RouteObjectType = {};
  urlParams.forEach((param, index) => {
    routeObject[index] = param;
  });
  return routeObject;
}