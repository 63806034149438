function unregister() {
  if (typeof window !== 'undefined') {
    if (window.navigator && navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (const registration of registrations) {
          if (registration?.scope?.includes('partytown')) {
            continue
          }
          registration.unregister()
        }
      })
    }

    if ('caches' in window) {
      caches.keys().then(function (keyList) {
        return Promise.all(
          keyList.map(function (key) {
            return caches.delete(key)
          })
        )
      })
    }
  }
}

export default unregister
