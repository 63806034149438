// string variable values as s_n_a_k_e_c_a_s_e
export const VIDEO_PLAYER_PORTAL_ID = 'video_player_43A9F2';
export const VIDEO_PLAYER_ID = 'video_player_43A9F2';
export const MINI_VIDEO_PLAYER_ID = 'mini_video_player_43A9F2';
export const HOVER_TIME_MS_ID = 'times_onhover_43A9F2';
export const PLAYER_CONTROLS_SEEKBAR_WRAPPER_ID = 'player-controls-seek-bar-wrapper';
export const CONTROLS_TIMEOUT_MOBILE = 3; // in seconds
export const CONTROLS_TIMEOUT_WEB = 3; // in seconds
export const CONTROLS_TIMEOUT_PIP = 0.5; // in seconds

export enum PLAYER_TYPE {
  LIVE = 'LIVE',
  VOD = 'VOD',
}
export enum PLAYER_TYPE_ID {
  LIVE = '__live_player',
  VOD = '__vod_player',
}
export const PLAYER_KEY_MAPPING = {
  // All must be in small letters
  play_pause: ['k', ' '],
  mute_unmute: 'm',
  volume_up: 'arrowup',
  volume_down: 'arrowdown',
  forward_seek: 'arrowright',
  backward_seek: 'arrowleft',
  full_screen: 'f',
  theatre_mode: 't',
  chat_view: 'c',
  mini_player: 'i'
};