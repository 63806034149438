import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
export const playbackSpeed = _jsx(_Fragment, {
  children: _jsx("div", {
    children: _jsxs("svg", {
      css: process.env.NODE_ENV === "production" ? {
        name: "1igb9gs",
        styles: "height:1.5rem;width:1.5rem;@media (min-width: 768px){height:1.25rem;width:1.25rem;}"
      } : {
        name: "16rdwgr-playbackSpeed",
        styles: "height:1.5rem;width:1.5rem;@media (min-width: 768px){height:1.25rem;width:1.25rem;};label:playbackSpeed;",
        toString: _EMOTION_STRINGIFIED_CSS_ERROR__
      },
      viewBox: "0 0 24 24",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      children: [_jsx("path", {
        d: "M9.60751 1.51737C10.3776 1.34229 11.1785 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C11.1785 22.75 10.3776 22.6577 9.60751 22.4826C9.2036 22.3908 8.95061 21.9889 9.04244 21.585C9.13427 21.1811 9.53614 20.9281 9.94005 21.02C10.6018 21.1704 11.2911 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75C11.2911 2.75 10.6018 2.8296 9.94005 2.98004C9.53614 3.07187 9.13427 2.81888 9.04244 2.41497C8.95061 2.01106 9.2036 1.60919 9.60751 1.51737Z",
        css: process.env.NODE_ENV === "production" ? {
          name: "1xiecb9",
          styles: "fill:#FF5A12;@media (min-width: 768px){fill:#fff;}"
        } : {
          name: "1xjk8u7-playbackSpeed",
          styles: "fill:#FF5A12;@media (min-width: 768px){fill:#fff;};label:playbackSpeed;",
          toString: _EMOTION_STRINGIFIED_CSS_ERROR__
        }
      }), _jsx("path", {
        d: "M7.31372 3.13198C7.53443 3.4825 7.42919 3.94557 7.07868 4.16627C5.90349 4.90623 4.90623 5.90349 4.16627 7.07868C3.94556 7.42919 3.4825 7.53443 3.13198 7.31372C2.78146 7.09302 2.67623 6.62995 2.89693 6.27944C3.75646 4.91436 4.91436 3.75646 6.27943 2.89693C6.62995 2.67623 7.09302 2.78146 7.31372 3.13198Z",
        css: process.env.NODE_ENV === "production" ? {
          name: "1xiecb9",
          styles: "fill:#FF5A12;@media (min-width: 768px){fill:#fff;}"
        } : {
          name: "1xjk8u7-playbackSpeed",
          styles: "fill:#FF5A12;@media (min-width: 768px){fill:#fff;};label:playbackSpeed;",
          toString: _EMOTION_STRINGIFIED_CSS_ERROR__
        }
      }), _jsx("path", {
        d: "M2.98004 9.94005C3.07187 9.53614 2.81888 9.13427 2.41497 9.04244C2.01106 8.95061 1.60919 9.2036 1.51737 9.60751C1.34229 10.3776 1.25 11.1785 1.25 12C1.25 12.8215 1.34229 13.6224 1.51737 14.3925C1.60919 14.7964 2.01106 15.0494 2.41497 14.9576C2.81888 14.8657 3.07187 14.4639 2.98004 14.06C2.8296 13.3982 2.75 12.7089 2.75 12C2.75 11.2911 2.8296 10.6018 2.98004 9.94005Z",
        css: process.env.NODE_ENV === "production" ? {
          name: "1xiecb9",
          styles: "fill:#FF5A12;@media (min-width: 768px){fill:#fff;}"
        } : {
          name: "1xjk8u7-playbackSpeed",
          styles: "fill:#FF5A12;@media (min-width: 768px){fill:#fff;};label:playbackSpeed;",
          toString: _EMOTION_STRINGIFIED_CSS_ERROR__
        }
      }), _jsx("path", {
        d: "M3.13198 16.6863C3.4825 16.4656 3.94557 16.5708 4.16627 16.9213C4.90623 18.0965 5.90349 19.0938 7.07868 19.8337C7.42919 20.0544 7.53443 20.5175 7.31372 20.868C7.09302 21.2185 6.62995 21.3238 6.27944 21.1031C4.91436 20.2435 3.75646 19.0856 2.89693 17.7206C2.67623 17.37 2.78146 16.907 3.13198 16.6863Z",
        css: process.env.NODE_ENV === "production" ? {
          name: "1xiecb9",
          styles: "fill:#FF5A12;@media (min-width: 768px){fill:#fff;}"
        } : {
          name: "1xjk8u7-playbackSpeed",
          styles: "fill:#FF5A12;@media (min-width: 768px){fill:#fff;};label:playbackSpeed;",
          toString: _EMOTION_STRINGIFIED_CSS_ERROR__
        }
      }), _jsx("path", {
        d: "M15.4137 10.941C16.1954 11.4026 16.1954 12.5974 15.4137 13.059L10.6935 15.8458C9.93371 16.2944 9 15.7105 9 14.7868V9.21316C9 8.28947 9.93371 7.70561 10.6935 8.15419L15.4137 10.941Z",
        css: process.env.NODE_ENV === "production" ? {
          name: "1xiecb9",
          styles: "fill:#FF5A12;@media (min-width: 768px){fill:#fff;}"
        } : {
          name: "1xjk8u7-playbackSpeed",
          styles: "fill:#FF5A12;@media (min-width: 768px){fill:#fff;};label:playbackSpeed;",
          toString: _EMOTION_STRINGIFIED_CSS_ERROR__
        }
      })]
    })
  })
});
export const videoQuality = _jsx(_Fragment, {
  children: _jsx("div", {
    children: _jsx("svg", {
      css: process.env.NODE_ENV === "production" ? {
        name: "1igb9gs",
        styles: "height:1.5rem;width:1.5rem;@media (min-width: 768px){height:1.25rem;width:1.25rem;}"
      } : {
        name: "1ffqzsz-videoQuality",
        styles: "height:1.5rem;width:1.5rem;@media (min-width: 768px){height:1.25rem;width:1.25rem;};label:videoQuality;",
        toString: _EMOTION_STRINGIFIED_CSS_ERROR__
      },
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      children: _jsx("path", {
        d: "M13.86 5.67845C10.5536 4.77385 7.01626 4.77385 3.70989 5.67845C3.29024 5.79334 3 6.17428 3 6.60844V16.7646C3 17.1987 3.29024 17.5797 3.70989 17.6946C6.97756 18.5883 10.4823 18.6185 13.86 17.6946C14.2796 17.5797 14.5699 17.1987 14.5699 16.7646V6.60844C14.5699 6.17428 14.2796 5.79334 13.86 5.67845ZM19.914 6.55764C17.7807 8.45633 15.9993 11.2173 15.9256 11.3431C15.7986 11.5547 15.7974 11.8195 15.9256 12.0312C15.9993 12.1557 17.7783 15.0461 19.9116 16.9448C20.3143 17.3027 21 17.0899 21 16.4441V7.05953C21 6.41252 20.3155 6.2021 19.9152 6.55885L19.914 6.55764Z",
        css: process.env.NODE_ENV === "production" ? {
          name: "1xiecb9",
          styles: "fill:#FF5A12;@media (min-width: 768px){fill:#fff;}"
        } : {
          name: "icyq63-videoQuality",
          styles: "fill:#FF5A12;@media (min-width: 768px){fill:#fff;};label:videoQuality;",
          toString: _EMOTION_STRINGIFIED_CSS_ERROR__
        }
      })
    })
  })
});
export const keyboardShortcuts = _jsx(_Fragment, {
  children: _jsx("div", {
    children: _jsx("svg", {
      css: process.env.NODE_ENV === "production" ? {
        name: "1igb9gs",
        styles: "height:1.5rem;width:1.5rem;@media (min-width: 768px){height:1.25rem;width:1.25rem;}"
      } : {
        name: "19a3eae-keyboardShortcuts",
        styles: "height:1.5rem;width:1.5rem;@media (min-width: 768px){height:1.25rem;width:1.25rem;};label:keyboardShortcuts;",
        toString: _EMOTION_STRINGIFIED_CSS_ERROR__
      },
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      children: _jsx("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 5H16C18.8284 5 20.2426 5 21.1213 5.87868C22 6.75736 22 8.17157 22 11V13C22 15.8284 22 17.2426 21.1213 18.1213C20.2426 19 18.8284 19 16 19H8C5.17157 19 3.75736 19 2.87868 18.1213C2 17.2426 2 15.8284 2 13V11C2 8.17157 2 6.75736 2.87868 5.87868C3.75736 5 5.17157 5 8 5ZM6 10C6.55228 10 7 9.55228 7 9C7 8.44772 6.55228 8 6 8C5.44772 8 5 8.44772 5 9C5 9.55228 5.44772 10 6 10ZM6 13C6.55228 13 7 12.5523 7 12C7 11.4477 6.55228 11 6 11C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13ZM9 13C9.55228 13 10 12.5523 10 12C10 11.4477 9.55228 11 9 11C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13ZM9 10C9.55228 10 10 9.55228 10 9C10 8.44772 9.55228 8 9 8C8.44772 8 8 8.44772 8 9C8 9.55228 8.44772 10 9 10ZM12 10C12.5523 10 13 9.55228 13 9C13 8.44772 12.5523 8 12 8C11.4477 8 11 8.44772 11 9C11 9.55228 11.4477 10 12 10ZM12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13ZM15 10C15.5523 10 16 9.55228 16 9C16 8.44772 15.5523 8 15 8C14.4477 8 14 8.44772 14 9C14 9.55228 14.4477 10 15 10ZM15 13C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11C14.4477 11 14 11.4477 14 12C14 12.5523 14.4477 13 15 13ZM18 10C18.5523 10 19 9.55228 19 9C19 8.44772 18.5523 8 18 8C17.4477 8 17 8.44772 17 9C17 9.55228 17.4477 10 18 10ZM18 13C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11C17.4477 11 17 11.4477 17 12C17 12.5523 17.4477 13 18 13ZM17.75 16C17.75 16.4142 17.4142 16.75 17 16.75H7C6.58579 16.75 6.25 16.4142 6.25 16C6.25 15.5858 6.58579 15.25 7 15.25H17C17.4142 15.25 17.75 15.5858 17.75 16Z",
        css: process.env.NODE_ENV === "production" ? {
          name: "1xiecb9",
          styles: "fill:#FF5A12;@media (min-width: 768px){fill:#fff;}"
        } : {
          name: "10bitj8-keyboardShortcuts",
          styles: "fill:#FF5A12;@media (min-width: 768px){fill:#fff;};label:keyboardShortcuts;",
          toString: _EMOTION_STRINGIFIED_CSS_ERROR__
        }
      })
    })
  })
});
export const reportVideo = _jsx(_Fragment, {
  children: _jsx("div", {
    children: _jsx("svg", {
      css: process.env.NODE_ENV === "production" ? {
        name: "1igb9gs",
        styles: "height:1.5rem;width:1.5rem;@media (min-width: 768px){height:1.25rem;width:1.25rem;}"
      } : {
        name: "kl1ha1-reportVideo",
        styles: "height:1.5rem;width:1.5rem;@media (min-width: 768px){height:1.25rem;width:1.25rem;};label:reportVideo;",
        toString: _EMOTION_STRINGIFIED_CSS_ERROR__
      },
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      children: _jsx("path", {
        d: "M5.75 1C6.16421 1 6.5 1.33579 6.5 1.75V3.6L8.22067 3.25587C9.8712 2.92576 11.5821 3.08284 13.1449 3.70797L13.3486 3.78943C14.9097 4.41389 16.628 4.53051 18.2592 4.1227C19.0165 3.93339 19.75 4.50613 19.75 5.28669V12.6537C19.75 13.298 19.3115 13.8596 18.6864 14.0159L18.472 14.0695C16.7024 14.5119 14.8385 14.3854 13.1449 13.708C11.5821 13.0828 9.8712 12.9258 8.22067 13.2559L6.5 13.6V21.75C6.5 22.1642 6.16421 22.5 5.75 22.5C5.33579 22.5 5 22.1642 5 21.75V1.75C5 1.33579 5.33579 1 5.75 1Z",
        css: process.env.NODE_ENV === "production" ? {
          name: "1xiecb9",
          styles: "fill:#FF5A12;@media (min-width: 768px){fill:#fff;}"
        } : {
          name: "1fa12ff-reportVideo",
          styles: "fill:#FF5A12;@media (min-width: 768px){fill:#fff;};label:reportVideo;",
          toString: _EMOTION_STRINGIFIED_CSS_ERROR__
        }
      })
    })
  })
});
export const SelectedTitle = () => {
  return _jsx("div", {
    className: "setting-selected-icon",
    role: "img",
    "aria-label": "check",
    children: _jsx("svg", {
      width: "20",
      height: "20",
      viewBox: "0 0 20 20",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      children: _jsx("path", {
        d: "M5.83301 10.75L8.45206 13.75L14.9997 6.25",
        stroke: "white",
        "stroke-width": "1.5",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      })
    })
  });
};
export const UnSelectedButton = () => {
  return _jsx("div", {
    className: "setting-selected-icon",
    role: "img",
    "aria-label": "check",
    children: _jsxs("svg", {
      width: "14",
      height: "14",
      viewBox: "0 0 12 12",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      children: [_jsx("g", {
        clipPath: "url(#clip0_3065_100759)",
        children: _jsx("path", {
          d: "M6 0.75C4.96165 0.75 3.94662 1.05791 3.08326 1.63478C2.2199 2.21166 1.54699 3.0316 1.14963 3.99091C0.752275 4.95022 0.648307 6.00582 0.85088 7.02422C1.05345 8.04262 1.55347 8.97808 2.28769 9.71231C3.02192 10.4465 3.95738 10.9466 4.97578 11.1491C5.99418 11.3517 7.04978 11.2477 8.00909 10.8504C8.9684 10.453 9.78834 9.7801 10.3652 8.91674C10.9421 8.05339 11.25 7.03835 11.25 6C11.25 4.60761 10.6969 3.27226 9.71231 2.28769C8.72775 1.30312 7.39239 0.75 6 0.75ZM6 10.5C5.10999 10.5 4.23996 10.2361 3.49994 9.74161C2.75992 9.24715 2.18314 8.54434 1.84254 7.72208C1.50195 6.89981 1.41284 5.99501 1.58647 5.12209C1.7601 4.24918 2.18869 3.44736 2.81802 2.81802C3.44736 2.18868 4.24918 1.7601 5.1221 1.58647C5.99501 1.41283 6.89981 1.50195 7.72208 1.84254C8.54434 2.18314 9.24715 2.75991 9.74162 3.49993C10.2361 4.23996 10.5 5.10998 10.5 6C10.5 7.19347 10.0259 8.33807 9.18198 9.18198C8.33807 10.0259 7.19348 10.5 6 10.5Z",
          fill: "white"
        })
      }), _jsx("defs", {
        children: _jsx("clipPath", {
          id: "clip0_3065_100759",
          children: _jsx("rect", {
            width: "12",
            height: "12",
            fill: "white"
          })
        })
      })]
    })
  });
};
export const SelectedButton = () => {
  return _jsx("div", {
    className: "setting-selected-icon",
    role: "img",
    "aria-label": "check",
    children: _jsxs("svg", {
      width: "14",
      height: "14",
      viewBox: "0 0 12 12",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      children: [_jsxs("g", {
        clipPath: "url(#clip0_3065_100764)",
        children: [_jsx("path", {
          d: "M6 0.75C4.96165 0.75 3.94662 1.05791 3.08326 1.63478C2.2199 2.21166 1.54699 3.0316 1.14963 3.99091C0.752275 4.95022 0.648307 6.00582 0.85088 7.02422C1.05345 8.04262 1.55347 8.97808 2.28769 9.71231C3.02192 10.4465 3.95738 10.9466 4.97578 11.1491C5.99418 11.3517 7.04978 11.2477 8.00909 10.8504C8.9684 10.453 9.78834 9.7801 10.3652 8.91674C10.9421 8.05339 11.25 7.03835 11.25 6C11.25 4.60761 10.6969 3.27226 9.71231 2.28769C8.72775 1.30312 7.39239 0.75 6 0.75ZM6 10.5C5.10999 10.5 4.23996 10.2361 3.49994 9.74161C2.75992 9.24715 2.18314 8.54434 1.84254 7.72208C1.50195 6.89981 1.41284 5.99501 1.58647 5.12209C1.7601 4.24918 2.18869 3.44736 2.81802 2.81802C3.44736 2.18868 4.24918 1.7601 5.1221 1.58647C5.99501 1.41283 6.89981 1.50195 7.72208 1.84254C8.54434 2.18314 9.24715 2.75991 9.74162 3.49993C10.2361 4.23996 10.5 5.10998 10.5 6C10.5 7.19347 10.0259 8.33807 9.18198 9.18198C8.33807 10.0259 7.19348 10.5 6 10.5Z",
          fill: "white"
        }), _jsx("path", {
          d: "M6 3.75C5.55499 3.75 5.11998 3.88196 4.74997 4.12919C4.37996 4.37643 4.09157 4.72783 3.92127 5.13896C3.75097 5.5501 3.70642 6.0025 3.79323 6.43895C3.88005 6.87541 4.09434 7.27632 4.40901 7.59099C4.72368 7.90566 5.12459 8.11995 5.56105 8.20677C5.99751 8.29358 6.4499 8.24903 6.86104 8.07873C7.27217 7.90843 7.62357 7.62004 7.87081 7.25003C8.11804 6.88002 8.25 6.44501 8.25 6C8.25 5.40326 8.01295 4.83097 7.59099 4.40901C7.16903 3.98705 6.59674 3.75 6 3.75Z",
          fill: "white"
        })]
      }), _jsx("defs", {
        children: _jsx("clipPath", {
          id: "clip0_3065_100764",
          children: _jsx("rect", {
            width: "12",
            height: "12",
            fill: "white"
          })
        })
      })]
    })
  });
};
export const UpwardArrow = _jsx(_Fragment, {
  children: _jsx("svg", {
    width: "8",
    height: "8",
    viewBox: "0 0 8 8",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    children: _jsx("path", {
      d: "M4.52455 7.4866V1.75197L7.0972 4.25734C7.3028 4.45756 7.6402 4.45756 7.8458 4.25734C8.0514 4.05712 8.0514 3.73368 7.8458 3.53345L4.37166 0.150168C4.16606 -0.0500562 3.83394 -0.0500562 3.62834 0.150168L0.154201 3.53345C-0.0514002 3.73368 -0.0514002 4.05712 0.154201 4.25734C0.359802 4.45756 0.691927 4.45756 0.897529 4.25734L3.47018 1.75197L3.47018 7.4866C3.47018 7.76897 3.70741 8 3.99736 8C4.28731 8 4.52455 7.76897 4.52455 7.4866Z",
      fill: "white"
    })
  })
});