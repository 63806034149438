import { initializePrometheus, isServerSide, observeRequest } from './index.server';
export async function instrumentedFetch(url: string, options?: RequestInit) {
  if (isServerSide()) {
    await initializePrometheus();
  }
  const startTime = Date.now();
  let response: Response | undefined;
  try {
    response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response;
  } finally {
    if (isServerSide()) {
      const duration = Date.now() - startTime;
      const method = options?.method || 'GET';
      const statusCode = response ? response.status.toString() : '0';
      observeRequest(duration, new URL(url).pathname, method, statusCode);
    }
  }
}