function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { getTokenFromCode } from '@functions/api';
import * as Google_Login from '@react-oauth/google';
import React, { useRef } from 'react';
import { useTranslation } from 'src/i18n';
import GoogleOneTap from './GoogleOneTap';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export default Google_Login;
const GOOGLE_CLIENT_ID = (process.env.GOOGLE_CLIENT_ID as string);
interface GoogleOAuthProviderProps {
  children: React.ReactNode | undefined;
}
export const GoogleOAuthProvider = (props: GoogleOAuthProviderProps) => {
  return _jsxs(Google_Login.GoogleOAuthProvider, {
    clientId: GOOGLE_CLIENT_ID,
    children: [props.children, _jsx(GoogleOneTap, {})]
  });
};
var _ref = process.env.NODE_ENV === "production" ? {
  name: "179t5g5",
  styles: "position:relative;z-index:1"
} : {
  name: "1sj490m-GoogleLoginButton",
  styles: "position:relative;z-index:1;label:GoogleLoginButton;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "1r82y81",
  styles: "position:absolute;top:0px;left:0px;bottom:0px;right:0px;z-index:9999;opacity:0"
} : {
  name: "wanxoh-GoogleLoginButton",
  styles: "position:absolute;top:0px;left:0px;bottom:0px;right:0px;z-index:9999;opacity:0;label:GoogleLoginButton;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export const GoogleLoginButton = ({
  responseGoogle,
  children
}: {
  children: React.ReactNode;
  responseGoogle: (params?: any) => void;
}) => {
  const {
    t
  } = useTranslation();
  const parentDivRef = useRef<HTMLDivElement>(null);
  if (typeof window !== 'undefined' && window.location.origin.search('localhost') >= 0) {
    // For Localhost: Using oAuth code flow
    return _jsx(UseGoogleLogin, {
      render: renderProps => {
        return _jsx("div", {
          onClick: renderProps.onClick,
          role: "presentation",
          children: children
        });
      },
      onSuccess: responseGoogle,
      onFailure: responseGoogle
    });
  }
  return _jsxs("div", {
    ref: parentDivRef,
    css: _ref,
    children: [children, _jsx("div", {
      css: _ref2,
      children: _jsx(Google_Login.GoogleLogin, {
        onSuccess: response => {
          let userEmail: string | undefined = undefined;
          try {
            const tokenInfo = response?.credential && window.atob(response?.credential?.split('.')[1]) || '{}';
            const parsedInfo = JSON.parse(tokenInfo);
            userEmail = parsedInfo?.email;
          } catch (err) {}
          responseGoogle({
            __resp: 'parsed response from [GoogleLogin/index.tsx]',
            tokenId: response?.credential,
            profileObj: {
              email: userEmail
            }
          });
        },
        width: typeof document !== 'undefined' && parentDivRef.current && getComputedStyle(parentDivRef.current).width || undefined,
        text: "continue_with",
        onError: () => {
          responseGoogle({
            error: t('common.something_went_wrong')
          });
        }
      })
    })]
  });
};
interface UseGoogleLoginProps {
  render: ({
    onClick
  }: {
    onClick: () => void;
  }) => React.ReactElement;
  onSuccess: (value?: any) => void;
  onFailure: (value?: any) => void;
}
export const UseGoogleLogin = (props: UseGoogleLoginProps) => {
  const {
    onSuccess,
    onFailure,
    render
  } = props || {};
  const {
    t
  } = useTranslation();
  const login = Google_Login.useGoogleLogin({
    onError: onFailure,
    onSuccess: async codeResponse => {
      try {
        const result = await getTokenFromCode(codeResponse?.code);
        if (result instanceof Error) {
          const err = (new Error() as any);
          try {
            const errorMessage = (JSON.parse(result?.message) || {}).error;
            err.code = errorMessage?.code;
            err.message = errorMessage?.message;
            err.stack = errorMessage?.stack;
          } catch (err) {}
          throw err;
        }
        if (result?.error) {
          throw result?.error;
        }
        const tokenId = result.tokens.id_token;
        const profileObj = result.profileObj;
        onSuccess({
          tokenId,
          profileObj
        });
      } catch (err: any) {
        // Throwing error in <onError> Format
        onFailure({
          error: err?.code || 518,
          error_description: err?.message || t('common.something_went_wrong'),
          error_uri: err?.stack || 'something_went_wrong'
        });
      }
    },
    flow: 'auth-code' // For server-side authentication
  });

  return render({
    onClick: login
  });
};