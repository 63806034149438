import { useControlDisplayStore } from '@components/VideoPlayer/store/useControlDisplayStore';
import { usePlayerStore } from '@components/VideoPlayer/store/usePlayerStore';
import usePlayerContext from '@components/VideoPlayer/store/WithContextProvider';
import { useStreamStore } from '@stores/streamStore';
import { increaseCount } from '@stores/usePlayerEvents';
import React from 'react';
import PlayerSettingComp from '../PlayerSettingComp';
import BackToLive from './BackToLive';
import FullScreenElement from './FullScreenElement';
import MiniPlayerToogle from './MiniPlayerToggle';
import PlayPauseToggle from './PlayPauseToggle';
import SeekbarControls from './SeekbarControls';
import ShowStreamTime from './ShowStreamTime';
import TheatreMode from './TheatreMode';
import VolumeControl from './VolumeControlComp';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const {
  setIsPlaying
} = useStreamStore.getState();
const {
  setVolume,
  setIsMuted
} = usePlayerStore.getState();
const {
  resetControlSectionVisiblity
} = useControlDisplayStore.getState();
const PlayerControls = () => {
  const {
    onSeekToThisPosition
  } = usePlayerContext();
  const isPlaying = useStreamStore(state => state.isPlaying);
  const currentVolume = usePlayerStore(state => state.volume);
  const isPlayerMuted = usePlayerStore(state => state.isMuted);
  const stream = usePlayerStore(state => state.playerProps.stream);
  const isChannelLive = stream?.status === 20;
  return _jsxs(React.Fragment, {
    children: [_jsxs("div", {
      className: "player-controls-wrapper player-setting-wrapper",
      children: [_jsxs("div", {
        className: "player-controls-btn-container",
        children: [_jsx(PlayPauseToggle, {
          onClick: () => {
            increaseCount(stream?.uid || '', 'player_play_pause_toggle_count', 1);
            setIsPlaying(!isPlaying);
          },
          isPaused: !isPlaying,
          isDisabled: false,
          isPortalOpen: false
        }), isChannelLive && _jsx(BackToLive, {
          onClickLive: () => {
            const playerDuration = usePlayerStore.getState().playerDuration;
            onSeekToThisPosition({
              position: playerDuration,
              duration: playerDuration,
              progress: 100,
              source: 'live_button'
            });
          }
        }), _jsx(ShowStreamTime, {}), _jsx(VolumeControl, {
          currentVolume: isPlayerMuted ? 0 : currentVolume,
          onToggle: () => {
            increaseCount(stream?.uid || '', 'mute_toggle_count', 1);
            setIsMuted(!isPlayerMuted);
          },
          onChange: (n_volume: number) => {
            const p_muted = usePlayerStore.getState().isMuted;
            if (p_muted && n_volume > 0) {
              increaseCount(stream?.uid || '', 'mute_toggle_count', 1);
            } else if (!p_muted && n_volume === 0) {
              increaseCount(stream?.uid || '', 'mute_toggle_count', 1);
            }
            setVolume(n_volume);
          },
          isMuted: isPlayerMuted
        })]
      }), _jsxs("div", {
        className: "player-controls-btn-container",
        onPointerDown: () => resetControlSectionVisiblity(),
        children: [_jsx(PlayerSettingComp, {}), _jsx(MiniPlayerToogle, {}), _jsx(TheatreMode, {}), _jsx(FullScreenElement, {})]
      })]
    }), _jsx(SeekbarControls, {})]
  });
};
export default PlayerControls;