import { InPortalComp, OutPortalComp } from '@components/portal/WithReactReversePortal';
import { useDeviceInfo } from '@hooks/react-use';
import { unicornLogFun, unicornVisitLogFun } from '@layouts/stream/unicornLogFun';
import React, { useEffect } from 'react';
import { VIDEO_PLAYER_PORTAL_ID } from './constants';
import IVSVideoPlayer from './IVSVideoPlayer';
import { usePlayerStore } from './store/usePlayerStore';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const PlayerExit = () => {
  const streamUid = usePlayerStore(state => state.playerProps.stream?.uid || null);
  const {
    width
  } = useDeviceInfo();
  const isMobileWaliWidth = width ? width < 767 : false;
  useEffect(() => {
    if (streamUid) {
      unicornVisitLogFun();
      return () => {
        unicornLogFun(streamUid);
      };
    }
  }, [streamUid]);
  return _jsxs(React.Fragment, {
    children: [_jsx(InPortalComp, {
      name: VIDEO_PLAYER_PORTAL_ID,
      children: _jsx(IVSVideoPlayer, {})
    }), isMobileWaliWidth ? _jsx(OutPortalComp, {
      name: "mobile-setting-view"
    }) : null]
  });
};
export default PlayerExit;