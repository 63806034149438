import create, { SetState } from 'zustand';
type Wallet = {
  gold: number;
  diamond: number;
};
type VIPTYPE = 'non-vip' | 'expired' | 'active';
type IProfileStore = {
  walletLoading: boolean;
  wallet: Wallet;
  isUserVip: boolean;
  vipType: VIPTYPE | null;
  setWallet: (value: Wallet) => void;
  setWalletLoading: (value: boolean) => void;
  setVIPType: (value: VIPTYPE) => void;
  setIsUserVip: (value: boolean) => void;
};
const store = (set: SetState<IProfileStore>): IProfileStore => ({
  walletLoading: false,
  vipType: null,
  wallet: {
    gold: 0,
    diamond: 0
  },
  isUserVip: false,
  setWallet: value => set(() => ({
    wallet: value
  })),
  setWalletLoading: value => set(() => ({
    walletLoading: value
  })),
  setIsUserVip: value => set(() => ({
    isUserVip: value
  })),
  setVIPType: (value: VIPTYPE) => set(() => ({
    vipType: value
  }))
});
export const useProfileStore = create<IProfileStore>(store);