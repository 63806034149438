import create, { SetState } from 'zustand';
import { MeInterface } from '../types';
type IAuthStore = {
  me: null | MeInterface;
  setMe: (value: MeInterface | null) => void;
  updateMe: (value: Partial<MeInterface>) => void;
  accessToken: string | null;
  refreshToken: string | null;
  mode: string | null;
  userlogin: string | null;
  setAccessToken: (value: string) => void;
  setRefreshToken: (value: string) => void;
  loginBonus: string | null;
  setLoginBonus: (value: string) => void;
};
const store = (set: SetState<IAuthStore>): IAuthStore => ({
  me: null,
  setMe: value => {
    if (value) {
      // @ts-expect-error
      const userUid = value.user_id;
      // @ts-expect-error
      const avatarUrl = value.avatar;
      value.user_uid = value.user_uid || userUid;
      value.avatar_url = value.avatar_url || avatarUrl;
    }
    set(() => ({
      me: value
    }));
  },
  updateMe: value => {
    set(({
      me
    }) => ({
      me: me ? Object.assign({}, me, value) : me
    }));
  },
  userlogin: null,
  mode: null,
  accessToken: null,
  setAccessToken: value => set(() => ({
    accessToken: value
  })),
  refreshToken: null,
  setRefreshToken: value => set(() => ({
    refreshToken: value
  })),
  loginBonus: '',
  setLoginBonus: value => set(() => ({
    loginBonus: value
  }))
});
export const useAuthStore = create<IAuthStore>(store);