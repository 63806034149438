function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { usePlayerStore } from '@components/VideoPlayer/store/usePlayerStore';
import usePlayerContext from '@components/VideoPlayer/store/WithContextProvider';
import { appendMetric } from '@stores/usePlayerEvents';
import { TransI18 } from 'src/i18n';
import { SelectedTitle } from './HelperComponents';
import MenuItem from './MenuItem';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "2dptx0",
  styles: "margin-right:0.25rem;height:1rem;width:1rem"
} : {
  name: "6biwvg-PlayerQualitySetting",
  styles: "margin-right:0.25rem;height:1rem;width:1rem;label:PlayerQualitySetting;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "8ot8zd",
  styles: "margin-right:0.25rem;height:0.25rem;width:0.25rem"
} : {
  name: "1roc7z8-PlayerQualitySetting",
  styles: "margin-right:0.25rem;height:0.25rem;width:0.25rem;label:PlayerQualitySetting;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const PlayerQualitySetting = ({
  closeSubModal
}: {
  closeSubModal: () => void;
}) => {
  const {
    quality,
    qualitites,
    isAutoQualityMode,
    setQuality,
    setAutoQualityMode
  } = usePlayerContext();
  const AutoQuality = {
    name: 'AUTO',
    codecs: '',
    bitrate: 0,
    width: 0,
    height: 0
  };
  const updatedQualities = qualitites.length > 0 ? qualitites : quality ? [quality] : [];
  return _jsx(_Fragment, {
    children: [AutoQuality, ...updatedQualities].map((item, index) => {
      const name = item.name === AutoQuality.name ? _jsx(TransI18, {
        i18nKey: "video_player.player_controls.auto_text"
      }) : item.name;
      return _jsx(MenuItem, {
        className: "player-quality-list",
        title: (isAutoQualityMode ? name === AutoQuality.name : name === quality?.name) ? _jsxs(_Fragment, {
          children: [_jsx(SelectedTitle, {}), name]
        }) : _jsxs(_Fragment, {
          children: [_jsx("div", {
            css: _ref
          }), name]
        }),
        value: _jsx(_Fragment, {
          children: _jsx("div", {
            css: _ref2
          })
        }),
        onClick: () => {
          const streamUid = usePlayerStore.getState().playerProps?.stream?.uid || '';
          appendMetric(streamUid, 'manual_bitrate_change', item.name);
          if (item.name === AutoQuality.name) {
            setAutoQualityMode(true);
          } else {
            setAutoQualityMode(false);
            setQuality(item);
          }
          closeSubModal();
        },
        hideArrow: true,
        isSubMenu: true
      }, index);
    })
  });
};
export default PlayerQualitySetting;
export const CurrentQualitySetting = () => {
  const {
    quality,
    isAutoQualityMode
  } = usePlayerContext();
  return _jsxs("span", {
    className: "player-quality-value",
    children: [isAutoQualityMode ? _jsxs(_Fragment, {
      children: [_jsx(TransI18, {
        i18nKey: "video_player.player_controls.auto_text"
      }), "\xA0"]
    }) : '', quality?.name]
  });
};