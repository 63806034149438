function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { useRouter } from 'next/router';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1nalyzl",
  styles: "overflow:hidden;@media (min-width: 768px){overflow:auto;}"
} : {
  name: "1dfeuhb-PageWrapper",
  styles: "overflow:hidden;@media (min-width: 768px){overflow:auto;};label:PageWrapper;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "1qmr6ab",
  styles: "overflow:auto"
} : {
  name: "1dlvffu-PageWrapper",
  styles: "overflow:auto;label:PageWrapper;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export const PageWrapper = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const router = useRouter();
  const isChatPopout = router.pathname.includes('chat');
  return _jsx("div", {
    css: _ref,
    children: _jsx("div", {
      css: _ref2,
      children: _jsx("div", {
        css: ["display:flex;flex-direction:column;font-weight:400;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));", isChatPopout ? {
          "backgroundColor": "transparent"
        } : {
          "--tw-bg-opacity": "1",
          "backgroundColor": "rgb(5 5 6 / var(--tw-bg-opacity))"
        }, process.env.NODE_ENV === "production" ? "" : ";label:PageWrapper;", process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92YXIvbG9jby9sb2NvL2FwcHMvbG9jb2dnL3NyYy9sYXlvdXRzL2NvcmUvUGFnZVdyYXBwZXIudHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQWVVIiwiZmlsZSI6Ii92YXIvbG9jby9sb2NvL2FwcHMvbG9jb2dnL3NyYy9sYXlvdXRzL2NvcmUvUGFnZVdyYXBwZXIudHN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHsgdXNlUm91dGVyIH0gZnJvbSAnbmV4dC9yb3V0ZXInXG5pbXBvcnQgdHcgZnJvbSAndHdpbi5tYWNybydcblxuZXhwb3J0IGNvbnN0IFBhZ2VXcmFwcGVyID0gKHtcbiAgY2hpbGRyZW4sXG59OiB7XG4gIGNoaWxkcmVuOiBSZWFjdC5SZWFjdE5vZGVcbn0pOiBKU1guRWxlbWVudCA9PiB7XG4gIGNvbnN0IHJvdXRlciA9IHVzZVJvdXRlcigpXG5cbiAgY29uc3QgaXNDaGF0UG9wb3V0ID0gcm91dGVyLnBhdGhuYW1lLmluY2x1ZGVzKCdjaGF0JylcbiAgcmV0dXJuIChcbiAgICA8ZGl2IHR3PVwib3ZlcmZsb3ctaGlkZGVuIG1kOm92ZXJmbG93LWF1dG9cIj5cbiAgICAgIDxkaXYgdHc9XCJvdmVyZmxvdy1hdXRvXCI+XG4gICAgICAgIDxkaXZcbiAgICAgICAgICBjc3M9e1tcbiAgICAgICAgICAgIHR3YGZvbnQtbm9ybWFsIGZsZXggZmxleC1jb2wgdGV4dC13aGl0ZWAsXG4gICAgICAgICAgICBpc0NoYXRQb3BvdXQgPyB0d2BiZy10cmFuc3BhcmVudGAgOiB0d2BiZy1icmFuZC1ibGFja2AsXG4gICAgICAgICAgXX1cbiAgICAgICAgPlxuICAgICAgICAgIHtjaGlsZHJlbn1cbiAgICAgICAgPC9kaXY+XG4gICAgICA8L2Rpdj5cbiAgICA8L2Rpdj5cbiAgKVxufVxuIl19 */"],
        children: children
      })
    })
  });
};