export const abbreviateNumber = (value: number) => {
  if (value?.toString().length <= 3) {
    return value;
  }
  let newValue: any = value;
  const suffixes = ['', 'K', 'M', 'B', 'T'];
  let suffixNum = 0;
  while (newValue >= 1000) {
    newValue /= 1000;
    suffixNum++;
  }
  newValue = newValue?.toFixed(1);
  if (newValue?.endsWith('.0')) {
    newValue = newValue.slice(0, -2);
  }
  newValue += suffixes[suffixNum];
  return newValue;
};
export const expandAbbreviatedNumber = (abbr: string): number | undefined => {
  // Define conversion factors for each abbreviation
  const conversionFactors: Record<string, number> = {
    K: 1000,
    M: 1000000,
    B: 1000000000
  };

  // Extract the numeric portion of the abbreviation and the suffix
  const match = abbr.match(/^([0-9.]+)([KMB])$/);
  if (!match) {
    // Return undefined if the abbreviation format is invalid
    return undefined;
  }

  // Convert the numeric portion of the abbreviation to a number
  const numericPart = parseFloat(match[1]);

  // Multiply the numeric part by the appropriate conversion factor
  const conversionFactor = conversionFactors[match[2]];
  if (!conversionFactor) {
    // Return undefined if the suffix is invalid
    return undefined;
  }
  return numericPart * conversionFactor;
};
export default function secondsToDhms(value: number) {
  value = Number(value);
  const d = Math.floor(value / (3600 * 24));
  const h = Math.floor(value % (3600 * 24) / 3600);
  const m = Math.floor(value % 3600 / 60);
  const s = Math.floor(value % 60);
  return {
    days: d > 0 ? d : undefined,
    hours: h > 0 ? h : undefined,
    minutes: m > 0 ? m : undefined,
    seconds: s > 0 ? s : undefined
  };
}