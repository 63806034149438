import { SidebarFeedInterface } from '@customTypes/index';
import create, { SetState } from 'zustand';
type ISideBarStore = {
  isSideBarOpen: boolean;
  toggleSideBar: () => void;
  closeSideBar: () => void;
  openSideBar: () => void;
  sideBarFeedData: null | SidebarFeedInterface | {
    results: [];
  };
  setSideBarFeedData: (value: SidebarFeedInterface | null | {
    results: [];
  }) => void;
};
const store = (set: SetState<ISideBarStore>): ISideBarStore => ({
  isSideBarOpen: false,
  toggleSideBar: () => set(state => ({
    isSideBarOpen: !state.isSideBarOpen
  })),
  closeSideBar: () => set(() => ({
    isSideBarOpen: false
  })),
  openSideBar: () => set(() => ({
    isSideBarOpen: true
  })),
  sideBarFeedData: null,
  setSideBarFeedData: value => set(() => ({
    sideBarFeedData: value
  }))
});
export const useSidebarStore = create<ISideBarStore>(store);