import React from 'react';
import { TransI18 } from 'src/i18n';
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
interface IProps {
  isPaused: boolean;
  onClick: (event: React.PointerEvent<HTMLButtonElement>) => void;
  isDisabled?: boolean;
  isPortalOpen?: boolean;
}
const PlayPauseToggle = ({
  onClick,
  isPaused,
  isDisabled,
  isPortalOpen
}: IProps) => {
  return _jsxs("button", {
    "aria-disabled": !!isDisabled,
    className: `player-controls-btn play-pause-btn control-tooltip-parent ${isPaused ? 'play-btn' : 'pause-btn'} ${isPortalOpen ? 'play-pause-btn-mini-player' : ''}`,
    onPointerDown: onClick,
    children: [isPortalOpen ? _jsx(_Fragment, {}) : _jsx("span", {
      className: "control-tooltip live_demo",
      style: {
        left: '90%'
      },
      children: _jsx(TransI18, {
        i18nKey: isPaused ? 'video_player.player_controls.play_pause' : 'video_player.player_controls.play_pause_alt'
      })
    }), isPaused ? _jsx(_Fragment, {
      children: _jsx("svg", {
        width: "20",
        height: "20",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        children: _jsx("path", {
          d: "M21.4086 9.35258C23.5305 10.5065 23.5305 13.4935 21.4086 14.6474L8.59662 21.6145C6.53435 22.736 4 21.2763 4 18.9671L4 5.0329C4 2.72368 6.53435 1.26402 8.59661 2.38548L21.4086 9.35258Z",
          fill: "white"
        })
      })
    }) : _jsx(_Fragment, {
      children: _jsxs("svg", {
        width: "20",
        height: "20",
        viewBox: "0 0 20 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        children: [_jsx("path", {
          d: "M1.66667 5.00008C1.66667 3.42873 1.66667 2.64306 2.15483 2.1549C2.64298 1.66675 3.42866 1.66675 5.00001 1.66675C6.57135 1.66675 7.35703 1.66675 7.84518 2.1549C8.33334 2.64306 8.33334 3.42873 8.33334 5.00008V15.0001C8.33334 16.5714 8.33334 17.3571 7.84518 17.8453C7.35703 18.3334 6.57135 18.3334 5.00001 18.3334C3.42866 18.3334 2.64298 18.3334 2.15483 17.8453C1.66667 17.3571 1.66667 16.5714 1.66667 15.0001V5.00008Z",
          fill: "white"
        }), _jsx("path", {
          d: "M11.6667 5.00008C11.6667 3.42873 11.6667 2.64306 12.1548 2.1549C12.643 1.66675 13.4287 1.66675 15 1.66675C16.5714 1.66675 17.357 1.66675 17.8452 2.1549C18.3333 2.64306 18.3333 3.42873 18.3333 5.00008V15.0001C18.3333 16.5714 18.3333 17.3571 17.8452 17.8453C17.357 18.3334 16.5714 18.3334 15 18.3334C13.4287 18.3334 12.643 18.3334 12.1548 17.8453C11.6667 17.3571 11.6667 16.5714 11.6667 15.0001V5.00008Z",
          fill: "white"
        })]
      })
    })]
  });
};
export default PlayPauseToggle;