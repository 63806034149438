import React from 'react';
import { useEffect } from 'react';
const useIsOverlay = () => {
  const [isOverlay, setIsOverlay] = React.useState(false);
  const isOverlayOnStreamingPlatform = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const streamingKeywords = ['obs', 'streamlabs', 'elgato', 'omlet']; // Add more if needed
    return streamingKeywords.some(keyword => userAgent.includes(keyword));
  };
  useEffect(() => {
    // Start periodic updates
    setIsOverlay(isOverlayOnStreamingPlatform());

    // Cleanup on unmount
  }, []);
  return isOverlay;
};
export default useIsOverlay;