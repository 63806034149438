import { ISticker } from '@stores/streamStore';
import { getCookie } from '@utils/cookies';
import { fetchWithAllErrorHandle } from './apiWrapper';
import beforeonloadRequest from './beforeonloadRequest';
const serverUrl1 = (process.env.SERVER_URL as string);
const yenServer2 = (process.env.YEN_SERVER_V2 as string);
const CHAT_URL_V1 = (process.env.CHAT_URL_V1 as string);
const CHAT_URL_V2 = (process.env.CHAT_URL_V2 as string);
const clientID = (process.env.CLIENT_ID as string);
const clientSecret = (process.env.CLIENT_SECRET as string);
const UNICORN_V2 = (process.env.UNICORN_V2 as string);
export const logLiveView = async (streamId: string): Promise<any> => {
  const URL = `${serverUrl1}/live/${streamId}/view/`;
  const authToken = (getCookie('access_token') as string);
  const result = await fetchWithAllErrorHandle.url(URL).auth(authToken).post({
    duration: 100
  }).json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
export const logVodView = async (streamId: string): Promise<any> => {
  const URL = `${serverUrl1}/vod/${streamId}/view/`;
  const authToken = (getCookie('access_token') as string);
  const result = await fetchWithAllErrorHandle.url(URL).auth(authToken).post({}).json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
export const fetchAllTabsForStickers = async ({
  limit,
  stream_uid,
  streamer_uid,
  game_uid,
  nocache
}: {
  limit: number;
  stream_uid: string;
  streamer_uid: string;
  game_uid: string;
  nocache?: boolean;
}): Promise<any> => {
  const url = new URL(`${yenServer2}/sticker/tabs/all/`);
  //@ts-ignore
  const params = new URLSearchParams({
    stream_uid,
    streamer_uid,
    game_uid,
    limit
  });
  if (nocache) {
    const epochTime = new Date().getTime();
    params.append('epochTime', epochTime.toString());
  }
  url.search = params.toString();
  const authToken = (getCookie('access_token') as string);
  const result = await fetchWithAllErrorHandle
  //@ts-ignore
  .url(url).auth(authToken).get().json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
interface IfetchStickerDetails {
  sticker_id: string;
  stream_id: string;
  streamer_id: string;
  is_streamer_vip: boolean;
  error_type: string;
  error_code: string;
}
export const fetchStickerDetails = async ({
  error_code,
  error_type,
  is_streamer_vip,
  sticker_id,
  stream_id,
  streamer_id
}: IfetchStickerDetails) => {
  const authToken = getCookie('access_token');
  const url = new URL(`${yenServer2}/sticker/${sticker_id}/details/`);
  //@ts-ignore
  url.search = new URLSearchParams({
    stream_id,
    streamer_id,
    is_streamer_vip,
    error_code,
    error_type
  });
  const res = await fetchWithAllErrorHandle
  //@ts-ignore
  .url(url).headers({
    'Content-Type': 'application/json',
    Authorization: authToken!
  }).get().json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return res;
};
export const fetchAllStickers = async ({
  limit,
  stream_uid,
  streamer_uid,
  game_uid,
  tab_key,
  next,
  nocache
}: {
  limit: number;
  stream_uid: string;
  streamer_uid: string;
  game_uid: string;
  tab_key: 10 | 20 | 40 | 50 | 60;
  next?: string;
  nocache?: boolean;
}): Promise<any> => {
  const url = new URL(`${yenServer2}/sticker/all/`);

  //@ts-ignore
  const params = new URLSearchParams({
    stream_uid,
    streamer_uid,
    game_uid,
    tab_key,
    limit
  });
  if (next) {
    const offset = next.split('=')[1];
    params.set('offset', offset);
  }
  if (nocache) {
    const epochTime = new Date().getTime();
    params.append('epochTime', epochTime.toString());
  }
  url.search = params.toString();
  const authToken = (getCookie('access_token') as string);
  const result = await fetchWithAllErrorHandle
  //@ts-ignore
  .url(url).auth(authToken).get().json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
type IPostStickerBody = {
  isActive: boolean;
  moderator_type?: number;
  msg_time?: number;
  msgId?: string;
  deviceId?: string;
  profile: {
    avatar: string | undefined;
    color: string;
    uid: string | undefined;
    username: string | undefined;
  };
  stream_metadata: {
    category_name: string;
    category_uid: string;
    streamer_name: string;
    streamer_uid: string;
  };
  sticker: ISticker;
  streamer_uid: string;
  type: 4 | 5;
};
type IPostSticker = {
  streamId: string;
  body: IPostStickerBody;
};
export const postSticker = async ({
  streamId,
  body
}: IPostSticker) => {
  const url = new URL(`${CHAT_URL_V2}/streams/${streamId}/chat/`);
  const host = window.location.origin === 'http://localhost:8000/' ? undefined : window.location.origin;
  const hostOrgin = host ? {
    Origin: host
  } : undefined;
  const authToken = (getCookie('access_token') as string);
  const result = await fetchWithAllErrorHandle
  //@ts-ignore
  .url(url).headers({
    'X-CLIENT-ID': clientID!,
    'X-CLIENT-SECRET': clientSecret!,
    'X-PLATFORM': '7',
    ...hostOrgin
  }).auth(authToken).post(body).json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
interface IchatReplay {
  streamId: string;
  isSeeked: boolean;
  offset: number;
}
export const chatReplay = async ({
  isSeeked,
  streamId,
  offset
}: IchatReplay) => {
  const url = `${CHAT_URL_V1}/streams/${streamId}/replaychat/?offset=${offset}&is_seeked=${isSeeked ? 1 : 0}`;
  // origin header to solve cors issue
  const host = window.location.origin === 'http://localhost:8000' ? undefined : window.location.origin;
  const hostOrgin = host ? {
    Origin: host
  } : undefined;
  const authToken = (getCookie('access_token') as string);
  const result = await fetchWithAllErrorHandle
  //@ts-ignore
  .url(url).headers({
    'X-CLIENT-ID': clientID!,
    'X-CLIENT-SECRET': clientSecret!,
    'X-PLATFORM': '7',
    ...hostOrgin
  }).auth(authToken).get().json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
export const unicornEvents = async (props: any) => {
  const url = `${UNICORN_V2}/events/`;
  const authToken = (getCookie('access_token') as string);
  beforeonloadRequest(url, {
    method: 'POST',
    body: JSON.stringify(props),
    headers: {
      /* other headers are not possible to send because navigator.sendBeacon doens't allow  */
      'Content-Type': 'application/json',
      Authorization: authToken
    }
  });
};
export const getLeaderboardNudgeDetails = async (streamer_id: string): Promise<{
  show_nudge: number;
  icon_url?: string;
  message?: string;
  status?: number;
}> => {
  const authToken = getCookie('access_token');
  const res = await fetchWithAllErrorHandle.url(`${serverUrl1}/leaderboard/nudge/${streamer_id}/`).headers({
    'Content-Type': 'application/json',
    Authorization: authToken!
  }).get().json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return res;
};
interface ISendMessage {
  streamId: string;
  params: {
    chat_text_color?: string | undefined;
    chat_text_weight?: number | undefined;
    message: string;
    moderator_type: number;
    msg_time: number;
    msgId?: string;
    deviceId?: string;
    profile: {
      avatar: string | undefined;
      color: string;
      text_color?: string | undefined;
      text_weight?: number | undefined;
      uid: string | undefined;
      username: string | undefined;
    };
    reward_metadata?: {
      badge_thumbnails: string[];
    };
    sticker?: ISticker;
    type: number;
  };
}
export const sendMessage = async ({
  streamId,
  params
}: ISendMessage) => {
  const url = new URL(`${CHAT_URL_V2}/streams/${streamId}/chat/`);
  const authToken = (getCookie('access_token') as string);
  const result = await fetchWithAllErrorHandle
  //@ts-ignore
  .url(url).headers({
    'X-CLIENT-ID': clientID!,
    'X-CLIENT-SECRET': clientSecret!,
    'X-PLATFORM': '7'
  }).auth(authToken).post(params).json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
export const getStreamConfig = async (stream_uid: string) => {
  const authToken = (getCookie('access_token') as string);
  const res = await fetchWithAllErrorHandle.url(`${serverUrl1}/streams/${stream_uid}/config/`).headers({
    Authorization: authToken!
  }).get().json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return res;
};
export const streamAgeVerification = async (stream_uid: string, platform: 7) => {
  const authToken = (getCookie('access_token') as string);
  const res = await fetchWithAllErrorHandle.url(`${serverUrl1}/streams/age_verification/`).headers({
    Authorization: authToken!
  }).post({
    platform,
    stream_uid
  }).json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return res;
};