import { useDeviceInfo } from '@hooks/react-use';
import { positions } from 'react-alert';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const AlertTemplate = ({
  style,
  options,
  message
}: any) => {
  const {
    screen_isMobile
  } = useDeviceInfo();
  return _jsx("div", {
    style: {
      background: '#282828',
      zIndex: 1000,
      borderRadius: '12px',
      padding: '12px 16px 12px 16px',
      display: 'flex',
      justifyContent: 'center',
      borderWidth: screen_isMobile ? '0 0 1px 0' : '0 0 0 1px',
      borderStyle: 'solid',
      borderColor: '#FF5A12',
      alignItems: 'center',
      gap: '10px',
      overflow: 'hidden',
      ...style
    },
    children: _jsx("div", {
      style: {
        marginLeft: '2px',
        fontSize: '12px',
        padding: '4px 0',
        fontWeight: '400',
        lineHeight: '15.56px',
        color: 'white',
        display: 'inline-block'
      },
      children: message
    })
  });
};
export const Alertoptions = {
  timeout: 3000,
  position: positions.TOP_CENTER,
  offset: '80px',
  containerStyle: {
    zIndex: 100
  }
};