import { usePlayerStore } from '@components/VideoPlayer/store/usePlayerStore';
import { playerLogs, useCountMetric } from '@components/VideoPlayer/Utils/helper';
import { logStreamStatsEvent, logWatchStreamIntervalEvent } from '@layouts/stream/unicornLogFun';
import { useStreamStore } from '@stores/streamStore';
import { increaseStreamStatsCount, increaseWatchStreamCount, useStreamStatsEventData, useWatchIntervalEventData } from '@stores/usePlayerEvents';
import React, { useEffect } from 'react';
import * as workerTimers from 'worker-timers';
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const dLog = new playerLogs();
const WatchStreamInterval = ({
  streamUid
}: {
  streamUid: string;
}) => {
  const isUserInView = useCountMetric();
  const __counter_value_watch_interval = useWatchIntervalEventData(streamUid)(state => state.__counter_value);
  const __counter_value_stream_stats = useStreamStatsEventData(streamUid)(state => state.__counter_value);
  const hasPlayerError = usePlayerStore(state => state.playerError);
  const isLoading = usePlayerStore(state => state.isLoading);
  const isStartCountingForMetric = isUserInView && !hasPlayerError && !isLoading;
  useEffect(() => {
    if (!isStartCountingForMetric) {
      return;
    }
    if (__counter_value_watch_interval === 1) {
      const stream = useStreamStore.getState().stream;
      // 1 -> 60 count
      stream && logWatchStreamIntervalEvent({
        stream
      });
      return;
    } else if (__counter_value_watch_interval < 0) {
      dLog.log(`__counter_value is negative for watch_interval.`);
      return;
    }
    const timer = workerTimers.setTimeout(() => {
      increaseWatchStreamCount(streamUid, '__counter_value', -1);
    }, 1007);
    return () => {
      workerTimers.clearTimeout(timer);
    };
  }, [__counter_value_watch_interval, isStartCountingForMetric, streamUid]);
  useEffect(() => {
    if (!isStartCountingForMetric) {
      return;
    }
    if (__counter_value_stream_stats === 1) {
      // 1 -> 60 count
      const stream = useStreamStore.getState().stream;
      stream && logStreamStatsEvent({
        source: 'interval',
        stream
      });
      return;
    } else if (__counter_value_stream_stats < 0) {
      dLog.log(`__counter_value is negative for stream_stats.`);
      return;
    }
    const timer = workerTimers.setTimeout(() => {
      increaseStreamStatsCount(streamUid, '__counter_value', -1);
    }, 1007);
    return () => {
      workerTimers.clearTimeout(timer);
    };
  }, [__counter_value_stream_stats, isStartCountingForMetric, streamUid]);
  return _jsx(_Fragment, {});
};
export default WatchStreamInterval;