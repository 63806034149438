import { StreamInterface } from '@customTypes/index';
// @ts-ignore
import { sendGTMEvent } from '@next/third-parties/google';
import capitalizeFirstLetter from './capitalizeFirstLetter';
declare global {
  interface Window {
    //@ts-ignore
    dataLayer: any;
    fbq: any;
  }
}
export const GA_TRACKING_ID = process.env.GA_TRACKING_ID;
export const pageview = (url: string) => {
  sendGTMEvent({
    event: 'pageviewed',
    page: url
  });
};
export const streamView = (stream: StreamInterface) => {
  if (typeof window.fbq === 'function') {
    window.fbq('trackCustom', 'watch_video_30_sec_loco_gg', {
      content_name: capitalizeFirstLetter(stream.streamer.username) + ' - ' + 'Loco',
      content_category: stream.game_name,
      content_type: 'Video'
    });
  }
};