import { isFullScreenApiAvailable } from '@components/VideoPlayer/Utils/utils';
import { useStreamStore } from '@stores/streamStore';
import React, { useEffect, useState } from 'react';
import { TransI18 } from 'src/i18n';
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const {
  setIsFullScreen
} = useStreamStore.getState();
const FullScreenElement = ({
  disabled: isElementDisabled
}: {
  disabled?: boolean;
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const isFullScreen = useStreamStore(state => state.isFullScreen);
  useEffect(() => {
    const isApiPresent = isFullScreenApiAvailable();
    setIsDisabled(!isApiPresent);
    if (!isApiPresent) {
      setIsFullScreen(false);
    }
  }, [isFullScreen]);
  if (isDisabled) {
    return _jsx(_Fragment, {});
  }
  return _jsxs("button", {
    onClick: () => setIsFullScreen(!isFullScreen),
    disabled: isElementDisabled,
    "aria-disabled": isElementDisabled,
    className: `btn control-tooltip-parent player_fullscreen_toggle ` + (isFullScreen ? 'player_max' : 'player_min'),
    children: [_jsx("span", {
      className: "control-tooltip full_page",
      style: {
        left: '-150%'
      },
      children: _jsx(TransI18, {
        i18nKey: isFullScreen ? 'video_player.player_controls.fullscreen_actions.exit' : 'video_player.player_controls.fullscreen_actions.enter'
      })
    }), _jsxs("svg", {
      style: {
        opacity: isElementDisabled ? 0.5 : 1
      },
      width: "20",
      height: "20",
      viewBox: "0 0 20 20",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      children: [_jsx("path", {
        d: "M17.5 11.6667C17.5 14.4166 17.5 15.7915 16.6457 16.6458C15.7914 17.5001 14.4165 17.5001 11.6666 17.5001",
        stroke: "white",
        "stroke-width": "1.66667",
        "stroke-linecap": "round"
      }), _jsx("path", {
        d: "M8.33333 17.5001C5.58347 17.5001 4.20854 17.5001 3.35427 16.6458C2.5 15.7915 2.5 14.4166 2.5 11.6667",
        stroke: "white",
        "stroke-width": "1.66667",
        "stroke-linecap": "round"
      }), _jsx("path", {
        d: "M8.33333 2.5C5.58347 2.5 4.20854 2.5 3.35427 3.35427C2.5 4.20854 2.5 5.58347 2.5 8.33333",
        stroke: "white",
        "stroke-width": "1.66667",
        "stroke-linecap": "round"
      }), _jsx("path", {
        d: "M11.6666 2.5C14.4165 2.5 15.7914 2.5 16.6457 3.35427C17.5 4.20854 17.5 5.58347 17.5 8.33333",
        stroke: "white",
        "stroke-width": "1.66667",
        "stroke-linecap": "round"
      })]
    })]
  });
};
export default FullScreenElement;