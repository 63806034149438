import create, { SetState } from 'zustand';
export interface IThumbnailSegment {
  start_time: number; // 0
  end_time: number; //2000
  sprite_sheet_url: string;
  x: number;
  y: number;
}
export interface IThumbnailResponse {
  uf: number;
  lua: number;
  v: number;
  lps: string;
  total_duration: number;
  w: number; // 160
  h: number; // 90
  contents: IThumbnailSegment[];
}
interface IType {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  currentDuration: number;
  setCurrentDuration: (value: number) => void;
  thumbnailPrefix: string;
  setTrickUrlPrefix: (url: string) => void;
  thumbnailResponse: IThumbnailResponse | null;
  setThumbnailResponse: (response: IThumbnailResponse | null) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
}
const store = (set: SetState<IType>) => ({
  loading: false,
  setLoading: (value: boolean) => {
    set({
      loading: !!value
    });
  },
  isVisible: false,
  setIsVisible: (value: boolean) => {
    set({
      isVisible: !!value
    });
  },
  currentDuration: 0,
  setCurrentDuration: (value: number) => {
    set({
      currentDuration: value
    });
  },
  thumbnailPrefix: '',
  setTrickUrlPrefix: (url: string) => {
    set({
      thumbnailPrefix: url
    });
  },
  thumbnailResponse: null,
  setThumbnailResponse: (response: IThumbnailResponse | null) => {
    set({
      thumbnailResponse: response
    });
  }
});
export const useTrickPlayStore = create<IType>(store);