function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { usePlayerStore } from '@components/VideoPlayer/store/usePlayerStore';
import usePlayerContext from '@components/VideoPlayer/store/WithContextProvider';
import { appendMetric } from '@stores/usePlayerEvents';
import { TransI18 } from 'src/i18n';
import { SelectedTitle } from './HelperComponents';
import MenuItem from './MenuItem';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const playbackSpeedOptions = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];
var _ref = process.env.NODE_ENV === "production" ? {
  name: "2dptx0",
  styles: "margin-right:0.25rem;height:1rem;width:1rem"
} : {
  name: "8k90nm-PlayerSpeedSetting",
  styles: "margin-right:0.25rem;height:1rem;width:1rem;label:PlayerSpeedSetting;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "8ot8zd",
  styles: "margin-right:0.25rem;height:0.25rem;width:0.25rem"
} : {
  name: "1gyojhm-PlayerSpeedSetting",
  styles: "margin-right:0.25rem;height:0.25rem;width:0.25rem;label:PlayerSpeedSetting;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const PlayerSpeedSetting = ({
  closeSubModal
}: {
  closeSubModal: () => void;
}) => {
  const {
    playbackRate,
    setPlaybackRate
  } = usePlayerContext();
  return _jsx(_Fragment, {
    children: playbackSpeedOptions.map((name, index) => {
      const title = name === 1 ? _jsx(TransI18, {
        i18nKey: "video_player.player_controls.normal_text"
      }) : name + 'x';
      return _jsx(MenuItem, {
        className: "player-speed-list",
        title: playbackRate === name ? _jsxs(_Fragment, {
          children: [_jsx(SelectedTitle, {}), title]
        }) : _jsxs(_Fragment, {
          children: [_jsx("div", {
            css: _ref
          }), title]
        }),
        value: _jsx(_Fragment, {
          children: _jsx("div", {
            css: _ref2
          })
        }),
        onClick: () => {
          const streamUid = usePlayerStore.getState().playerProps?.stream?.uid || '';
          appendMetric(streamUid, 'playback_speed', name);
          setPlaybackRate(name);
          closeSubModal();
        },
        hideArrow: true,
        isSubMenu: true
      }, index);
    })
  });
};
export default PlayerSpeedSetting;
export const CurrentSpeedSetting = () => {
  const {
    playbackRate
  } = usePlayerContext();
  return _jsx("span", {
    className: "player-speed-value",
    children: playbackRate === 1 ? _jsx(TransI18, {
      i18nKey: "video_player.player_controls.normal_text"
    }) : playbackRate + 'x'
  });
};