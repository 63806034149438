import { usePlayerStore } from '@components/VideoPlayer/store/usePlayerStore';
import { useEffect, useState } from 'react';
import { StreamData, streamStorage } from './localStreamStorage';
export function useStreamStorage() {
  const streamUid = usePlayerStore(state => state.playerProps.stream?.uid || null);
  const [streamData, setStreamData] = useState<StreamData | null>(null);
  useEffect(() => {
    if (!streamUid) {
      setStreamData(null);
      return;
    }
    const data = streamStorage.getStreamData(streamUid);
    setStreamData(data);
    const listener = (data: StreamData | null) => {
      setStreamData(data);
    };
    streamStorage.addListener(streamUid, listener);
    return () => streamStorage.removeListener(streamUid, listener);
  }, [streamUid]);
  return streamData;
}