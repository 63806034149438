import { cva } from 'class-variance-authority';
const defaultIconSizes: Record<'lg' | 'md' | 'sm', string> = {
  lg: 'w-12 p-3',
  md: 'w-10 p-2.5',
  sm: 'w-8 p-2'
};
export const buttonVariants = cva('inline-flex items-center justify-center gap-2 whitespace-nowrap font-bold rounded-[10px] disabled:pointer-events-none [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0', {
  variants: {
    size: {
      xl: 'h-14 text-xl py-3 px-6',
      lg: 'h-12 text-md py-3 px-6',
      md: 'h-10 text-sm px-6 py-2.5',
      sm: 'h-8 text-xs py-2 px-3'
    },
    variant: {
      primary: 'bg-loco-primary stroke-black text-black',
      secondary: 'border-loco-primary border-2 stroke-loco-primary text-loco-primary  bg-black',
      secondaryTransparent: 'border-loco-primary border-2 stroke-loco-primary text-loco-primary  bg-transparent',
      text: 'text-loco-primary stroke-loco-primary bg-black',
      'icon-primary': 'bg-loco-primary stroke-black text-black',
      'icon-secondary': 'bg-grey-1 stroke-white',
      'icon-text': 'stroke-loco-primary'
    },
    disabled: {
      true: 'cursor-not-allowed stroke-grey-5 text-grey-5',
      false: 'cursor-pointer'
    }
  },
  defaultVariants: {
    variant: 'primary',
    size: 'md'
  },
  compoundVariants: [{
    variant: 'primary',
    disabled: true,
    className: 'bg-grey-1'
  }, {
    variant: 'secondary',
    disabled: true,
    className: 'border-grey-1 bg-black '
  }, {
    variant: 'secondaryTransparent',
    disabled: true,
    className: 'border-grey-1 bg-transparent '
  }, {
    variant: 'text',
    disabled: true,
    className: ''
  }, ...(['icon-primary', 'icon-secondary', 'icon-text'] as const).flatMap(variant => (Object.keys(defaultIconSizes) as Array<keyof typeof defaultIconSizes>).map(size => ({
    variant,
    size,
    className: defaultIconSizes[size]
  })))]
});