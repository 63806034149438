import { updateProfile } from '@functions/api';
import { useAppStore } from '@stores/appStore';
import { useAuthStore } from '@stores/authStore';
export const updateUserLanguage = () => {
  const meLanguage = useAuthStore.getState().me?.language;
  const appLanguage = useAppStore.getState().appLanguage;
  if (!appLanguage || meLanguage) return;
  updateProfile({
    language: appLanguage
  }).then(() => {
    useAuthStore.getState().updateMe({
      language: appLanguage
    });
  });
};