import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
interface IgooglePlayStoreProps {
  width: string;
  height: string;
  className: string;
  isHovered: boolean;
}
const GooglePlayStore = (props: IgooglePlayStoreProps) => {
  const {
    ...leftProps
  } = props;
  return _jsxs("svg", {
    viewBox: "0 0 32 32",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    ...leftProps,
    children: [_jsx("rect", {
      width: "32",
      height: "32",
      rx: "8",
      fill: "white"
    }), _jsx("path", {
      d: "M6.95644 5.33325L17.3343 14.7322L20.0123 12.3064C16.353 10.2952 11.4519 7.59729 9.24766 6.38426L7.77097 5.57389C7.50994 5.42985 7.23047 5.35228 6.95644 5.33325ZM5.37232 6.45857C5.35132 6.56185 5.33325 6.66563 5.33325 6.77706V25.3519C5.33325 25.4299 5.35055 25.5024 5.36255 25.5767L15.9201 16.0132L5.37232 6.45857ZM21.7722 13.2742L18.7485 16.0132L21.7195 18.7044C23.2936 17.8402 24.3838 17.24 24.5049 17.1739C25.0399 16.8777 25.3421 16.4307 25.3331 15.9424C25.3251 15.4632 25.0228 15.0347 24.5088 14.7711C24.3938 14.7113 23.3183 14.123 21.7722 13.2742ZM17.3343 17.2942L6.98769 26.6666C7.18871 26.6349 7.38948 26.5806 7.5815 26.4755C7.84752 26.3287 15.0341 22.3791 19.9595 19.6723L17.3343 17.2942Z",
      fill: "black"
    })]
  });
};
export default GooglePlayStore;