function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import FadeEffect from '@components/FadeEffectElement';
import { OutPortalComp } from '@components/portal/WithReactReversePortal';
import React from 'react';
import { TransI18 } from 'src/i18n';
import StreamStartingSoon from './Components/Common/StreamStartingSoon';
import { MINI_VIDEO_PLAYER_ID, VIDEO_PLAYER_PORTAL_ID } from './constants';
import { usePlayerStore } from './store/usePlayerStore';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "143v3n9",
  styles: "position:fixed;display:block;height:13.35rem;width:23rem;cursor:grab;overflow:hidden;border-radius:16px;border-width:6px;--tw-border-opacity:1;border-color:rgb(33 33 33 / var(--tw-border-opacity));--tw-bg-opacity:1;background-color:rgb(5 5 6 / var(--tw-bg-opacity));transition-property:all;transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transition-duration:300ms;@media (max-width: 767px){display:none;}"
} : {
  name: "w2pir6-MiniPlayerEntry",
  styles: "position:fixed;display:block;height:13.35rem;width:23rem;cursor:grab;overflow:hidden;border-radius:16px;border-width:6px;--tw-border-opacity:1;border-color:rgb(33 33 33 / var(--tw-border-opacity));--tw-bg-opacity:1;background-color:rgb(5 5 6 / var(--tw-bg-opacity));transition-property:all;transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transition-duration:300ms;@media (max-width: 767px){display:none;};label:MiniPlayerEntry;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "1elm2u6",
  styles: "position:absolute;top:0px;right:0px;bottom:0px;left:0px;display:flex;align-items:center;justify-content:center;background-image:linear-gradient(to top right, var(--tw-gradient-stops));--tw-gradient-from:#9ca3af;--tw-gradient-to:#1f2937;--tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to);--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity))"
} : {
  name: "3rrq9v-MiniPlayerEntry",
  styles: "position:absolute;top:0px;right:0px;bottom:0px;left:0px;display:flex;align-items:center;justify-content:center;background-image:linear-gradient(to top right, var(--tw-gradient-stops));--tw-gradient-from:#9ca3af;--tw-gradient-to:#1f2937;--tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to);--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));label:MiniPlayerEntry;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const MiniPlayerEntry = () => {
  const {
    isPortalOpen,
    isMiniPlayerEnabled
  } = usePlayerStore(state => ({
    isPortalOpen: state.isPortalOpen,
    isMiniPlayerEnabled: state.isMiniPlayerEnabled
  }));
  return _jsx(FadeEffect, {
    isOpen: isPortalOpen && isMiniPlayerEnabled,
    children: ({
      isVisible
    }) => {
      return _jsx("div", {
        id: MINI_VIDEO_PLAYER_ID,
        style: isVisible ? {
          opacity: 1,
          zIndex: 1000,
          visibility: 'visible',
          bottom: '160px',
          right: '20px'
        } : {
          opacity: 0,
          zIndex: -1,
          visibility: 'hidden',
          bottom: '160px',
          // bottom: '-13.75rem',
          right: '-23rem'
        },
        css: _ref,
        children: isPortalOpen ? _jsx(OutPortalComp, {
          name: VIDEO_PLAYER_PORTAL_ID,
          fallback: _jsx(StreamStartingSoon, {})
        }) : _jsx(_Fragment, {
          children: _jsx("div", {
            css: _ref2,
            children: _jsx(TransI18, {
              i18nKey: "video_player.player_messages.miniplayer"
            })
          })
        })
      });
    }
  });
};
export default MiniPlayerEntry;