import { Fragment, ReactNode, useEffect, useState } from 'react';
import type { HtmlPortalNode } from 'react-reverse-portal';
import { createHtmlPortalNode, InPortal, OutPortal } from 'react-reverse-portal';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const AllPortalNodes: {
  [key: string]: HtmlPortalNode;
} = {};
const usePortalNode = (name: string) => {
  const [isMounted, setMounted] = useState(false);
  if (!name) {
    throw new Error(`name:${name} is not defined`);
  }
  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);
  if (isMounted && !AllPortalNodes[name]) {
    AllPortalNodes[name] = createHtmlPortalNode({
      attributes: {
        style: 'height:100%;width:100%;',
        id: '__portal_' + name
      }
    });
  }
  return AllPortalNodes[name];
};
export const InPortalComp = ({
  name,
  children
}: {
  name: string;
  children: ReactNode;
}) => {
  const portalNode = usePortalNode(name);
  if (!portalNode) return null;
  return _jsx(Fragment, {
    children: _jsx(InPortal, {
      node: portalNode,
      children: children
    })
  });
};
export const OutPortalComp = (props: {
  name: string;
  fallback?: React.ReactNode;
} & {
  [key: string]: React.ReactNode;
}) => {
  const portalNode = usePortalNode(props.name);
  if (!portalNode) return _jsx(_Fragment, {
    children: props.fallback || null
  });
  return _jsx(OutPortal, {
    node: portalNode,
    ...props
  });
};