import { useAppStore } from '@stores/appStore';
import { getCookie } from '@utils/cookies';
import { GetServerSidePropsContext } from 'next';
import { extractSelectedLanguage, parseSelectedLanguage } from 'src/i18n';
export const getAppLanguage = (context?: GetServerSidePropsContext) => {
  if (typeof window !== 'undefined') {
    return parseSelectedLanguage(getCookie('appLanguage') || useAppStore.getState().appLanguage || '');
  }
  const cookie = context?.req.headers.cookie ?? '';
  const currentLocale = context?.req.headers['accept-language'];
  const selectedLanguage = extractSelectedLanguage(cookie, currentLocale);
  return parseSelectedLanguage(selectedLanguage);
};
export const getAppLocale = (context?: GetServerSidePropsContext) => {
  if (typeof window !== 'undefined') {
    return window.navigator.language || useAppStore.getState().appLanguage || 'en';
  }
  return context?.req.headers['accept-language'] ?? 'en';
};