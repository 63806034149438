import { getCookie } from '@utils/cookies';
import { fetchWithAllErrorHandle } from './apiWrapper';
const serverUrl1 = process.env.SERVER_URL;
export const postFollowGame = async ({
  gameID
}: {
  gameID: string;
}) => {
  const token = getCookie('access_token');
  const followGameResponse = await fetchWithAllErrorHandle.url(`${serverUrl1!}/games/${gameID}/follow/`).headers({
    'Content-Type': 'application/json',
    Authorization: token!
  }).post().json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return followGameResponse;
};
export const postUnfollowGame = async ({
  gameID
}: {
  gameID: string;
}) => {
  const token = getCookie('access_token');
  const unfollowGameResponse = await fetchWithAllErrorHandle.url(`${serverUrl1!}/games/${gameID}/unfollow/`).headers({
    'Content-Type': 'application/json',
    Authorization: token!
  }).post().json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return unfollowGameResponse;
};
export const postFollowStreamer = async ({
  streamerID
}: {
  streamerID: string;
}) => {
  const token = getCookie('access_token');
  const followStreamerResponse = await fetchWithAllErrorHandle.url(`${serverUrl1!}/profile/${streamerID}/follow/`).headers({
    'Content-Type': 'application/json',
    Authorization: token!
  }).post().json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return followStreamerResponse;
};
export const postUnfollowStreamer = async ({
  streamerID
}: {
  streamerID: string;
}) => {
  const token = getCookie('access_token');
  const unfollowStreamerResponse = await fetchWithAllErrorHandle.url(`${serverUrl1!}/profile/${streamerID}/unfollow/`).headers({
    'Content-Type': 'application/json',
    Authorization: token!
  }).post().json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return unfollowStreamerResponse;
};