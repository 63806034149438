import { useControlDisplayStore } from '@components/VideoPlayer/store/useControlDisplayStore';
import { usePlayerStore } from '@components/VideoPlayer/store/usePlayerStore';
import { useDeviceInfo } from '@hooks/react-use';
import { PAGES_ROUTE } from '@modules/analytics/types/constants';
import { useStreamStore } from '@stores/streamStore';
import { updateMetric } from '@stores/usePlayerEvents';
import { useRouter } from 'next/router';
import { TransI18 } from 'src/i18n';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const {
  setIsPortalOpen
} = usePlayerStore.getState();
const {
  resetControlSectionVisiblity
} = useControlDisplayStore.getState();
const MiniPlayerToogle = ({
  disabled: isElementDisabled
}: {
  disabled?: boolean;
}) => {
  const {
    screen_isMobile
  } = useDeviceInfo();
  const isPortalOpen = usePlayerStore(state => state.isPortalOpen);
  const playerStreamId = usePlayerStore(state => state.playerProps.stream?.uid || null);
  const streamId = useStreamStore(state => state.streamID || null);
  const router = useRouter();
  const currentPath = router.pathname;
  const redirectToStream = () => {
    if (playerStreamId) {
      const videoPath = '/stream/' + playerStreamId;
      router.push(videoPath);
    }
  };
  const toogleMiniPlayer = () => {
    const isStreamPage = currentPath === PAGES_ROUTE.STREAM_PAGE;
    const isStreamerPage = currentPath === PAGES_ROUTE.STREAMER_PAGE;
    if (!isPortalOpen) {
      setIsPortalOpen(true);
      resetControlSectionVisiblity();
      return;
    }
    if (isStreamPage && isPortalOpen) {
      const streamerPageScroll = (document.getElementById('streamer_page_wrapper') as HTMLDivElement | undefined);
      streamerPageScroll?.scrollTo(0, 0);
      setIsPortalOpen(false);
      return;
    }
    if (isStreamerPage && isPortalOpen) {
      if (streamId) {
        const streamerPageScroll = (document.getElementById('streamer_page_wrapper') as HTMLDivElement | undefined);
        streamerPageScroll?.scrollTo(0, 0);
        setIsPortalOpen(false);
      } else {
        updateMetric(playerStreamId || '', 'player_pip_expand', true);
        redirectToStream();
      }
      return;
    }
    // Here will redirect user to stream page
    updateMetric(playerStreamId || '', 'player_pip_expand', true);
    redirectToStream();
  };
  if (screen_isMobile) {
    return null;
  }
  return _jsx("button", {
    disabled: isElementDisabled,
    "aria-disabled": isElementDisabled,
    className: `control-tooltip-parent miniplayer-toggle ` + (isPortalOpen ? 'miniplayer-toggle-on' : 'miniplayer-toggle-off'),
    onPointerDown: toogleMiniPlayer,
    children: isPortalOpen ? _jsx("svg", {
      width: "20",
      height: "20",
      viewBox: "0 0 20 20",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      children: _jsx("path", {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M2.46245 4.21756C1.66669 5.31283 1.66669 6.87522 1.66669 10C1.66669 13.1248 1.66669 14.6872 2.46245 15.7824C2.71945 16.1362 3.03052 16.4472 3.38425 16.7042C4.47952 17.5 6.04191 17.5 9.16669 17.5H10.8334C13.9581 17.5 15.5205 17.5 16.6158 16.7042C16.9695 16.4472 17.2806 16.1362 17.5376 15.7824C18.3334 14.6872 18.3334 13.1248 18.3334 10C18.3334 6.87522 18.3334 5.31283 17.5376 4.21756C17.2806 3.86383 16.9695 3.55276 16.6158 3.29576C15.5205 2.5 13.9581 2.5 10.8334 2.5H9.16669C6.04191 2.5 4.47952 2.5 3.38425 3.29576C3.03052 3.55276 2.71945 3.86383 2.46245 4.21756ZM9.31168 10.367C9.16669 10.7243 9.16669 11.1773 9.16669 12.0833C9.16669 12.9893 9.16669 13.4423 9.31168 13.7997C9.505 14.2761 9.87581 14.6546 10.3425 14.852C10.6926 15 11.1363 15 12.0238 15H12.9762C13.8637 15 14.3075 15 14.6575 14.852C15.1242 14.6546 15.495 14.2761 15.6884 13.7997C15.8334 13.4423 15.8334 12.9893 15.8334 12.0833C15.8334 11.1773 15.8334 10.7243 15.6884 10.367C15.495 9.89056 15.1242 9.51203 14.6575 9.31468C14.3075 9.16667 13.8637 9.16667 12.9762 9.16667H12.0238C11.1363 9.16667 10.6926 9.16667 10.3425 9.31468C9.87581 9.51203 9.505 9.89056 9.31168 10.367Z",
        fill: "white"
      })
    }) : _jsxs(_Fragment, {
      children: [isElementDisabled ? null : _jsx("span", {
        className: "control-tooltip",
        children: _jsx(TransI18, {
          i18nKey: "video_player.player_messages.miniplayer"
        })
      }), _jsx("svg", {
        width: "20",
        height: "20",
        style: {
          opacity: isElementDisabled ? 0.5 : 1
        },
        viewBox: "0 0 20 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        children: _jsx("path", {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M2.46245 4.21756C1.66669 5.31283 1.66669 6.87522 1.66669 10C1.66669 13.1248 1.66669 14.6872 2.46245 15.7824C2.71945 16.1362 3.03052 16.4472 3.38425 16.7042C4.47952 17.5 6.04191 17.5 9.16669 17.5H10.8334C13.9581 17.5 15.5205 17.5 16.6158 16.7042C16.9695 16.4472 17.2806 16.1362 17.5376 15.7824C18.3334 14.6872 18.3334 13.1248 18.3334 10C18.3334 6.87522 18.3334 5.31283 17.5376 4.21756C17.2806 3.86383 16.9695 3.55276 16.6158 3.29576C15.5205 2.5 13.9581 2.5 10.8334 2.5H9.16669C6.04191 2.5 4.47952 2.5 3.38425 3.29576C3.03052 3.55276 2.71945 3.86383 2.46245 4.21756ZM9.31168 10.367C9.16669 10.7243 9.16669 11.1773 9.16669 12.0833C9.16669 12.9893 9.16669 13.4423 9.31168 13.7997C9.505 14.2761 9.87581 14.6546 10.3425 14.852C10.6926 15 11.1363 15 12.0238 15H12.9762C13.8637 15 14.3075 15 14.6575 14.852C15.1242 14.6546 15.495 14.2761 15.6884 13.7997C15.8334 13.4423 15.8334 12.9893 15.8334 12.0833C15.8334 11.1773 15.8334 10.7243 15.6884 10.367C15.495 9.89056 15.1242 9.51203 14.6575 9.31468C14.3075 9.16667 13.8637 9.16667 12.9762 9.16667H12.0238C11.1363 9.16667 10.6926 9.16667 10.3425 9.31468C9.87581 9.51203 9.505 9.89056 9.31168 10.367Z",
          fill: "white"
        })
      })]
    })
  });
};
export default MiniPlayerToogle;