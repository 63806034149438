import { OTPLESS_APP_ID } from '@components/PhoneNumberLogin/constants';
import { Amplitude } from '@modules/analytics';
import { resetAmplitudeUser } from '@modules/analytics/getAmplitude';
import * as Sentry from '@sentry/nextjs';
import { streamStorage } from '@stores/localStreamStorage';
import { getCookie, removeCookie, setCookie } from '@utils/cookies';
import { LANGUAGE_PRESET } from 'src/i18n/constants_i18n';
import { IMeProfileInfo, IMeResponse, MeInterface, StreamInterface, STREAMTYPE } from '../types';
import { browserDetailDelection } from '../utils/browserDetailDetection';
import { getFingerprint } from '../utils/fingerprint';
import { fetchWithAllErrorHandle } from './apiWrapper';
import { getAppLanguage, getAppLocale } from './getLanguageLocale';
import { getProfileInfo } from './profile';
const baseServerUrl = process.env.BASE_SERVER_URL;
const clientID = process.env.CLIENT_ID;
const clientSecret = process.env.CLIENT_SECRET;
const serverUrl3 = process.env.SERVER_URL_V3;
const baseTaskCenterUrl = process.env.BASE_TASK_CENTER_URL;
const serverUrl1 = process.env.SERVER_URL;
const CHAT_URL_V2 = process.env.CHAT_URL_V2;
export const setAccessTokenToStoreAndLocal = (access_token: string) => {
  setCookie('access_token', access_token);
};
export const setRefreshTokenToStoreAndLocal = (refresh_token: string) => {
  setCookie('refresh_token', refresh_token);
};
export const removeAccessToken = () => {
  removeCookie('access_token');
  return;
};
export const removeRefreshToken = () => {
  removeCookie('refresh_token');
  return;
};
export const registerDeviceProfile = async () => {
  let fingerprint = window.localStorage.getItem('fingerprint');
  if (!fingerprint) {
    fingerprint = await getFingerprint();
    window.localStorage.setItem('fingerprint', fingerprint);
  }
  const params = {
    platform: 7,
    client_id: clientID,
    client_secret: clientSecret,
    model: window.navigator.appCodeName,
    os_ver: window.navigator.appVersion,
    os_name: window.navigator.platform,
    app_ver: window.navigator.appVersion
  };
  const result: any = await fetchWithAllErrorHandle.url(`${serverUrl3!}/user/device_profile/`).headers({
    'Content-Type': 'application/json;charset=utf-8',
    'DEVICE-ID': (fingerprint + 'live' as string),
    'X-CLIENT-ID': clientID!,
    'X-CLIENT-SECRET': clientSecret!,
    'X-PLATFORM': '7'
  }).post(params).json(json => {
    if (json && json.access_token && json.refresh_token) {
      setAccessTokenToStoreAndLocal(json.access_token);
      setRefreshTokenToStoreAndLocal(json.refresh_token);
    }
    setCookie('mode', 'non-logged-in');
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
export const sentOtpToNumber = async (phoneNumber: string, countryCode: string) => {
  const fingerprint = await getFingerprint();
  const params = {
    client_id: clientID,
    country: `${countryCode}`,
    client_secret: clientSecret,
    phone: `${phoneNumber}`
  };
  const result: any = await fetchWithAllErrorHandle.url(`${serverUrl3!}/user/send-otp/`).headers({
    'Content-Type': 'application/json;charset=utf-8',
    'DEVICE-ID': (fingerprint + 'live' as string),
    'X-PLATFORM': '7'
  }).post(params).json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
export const OTPVerify = async (phoneNumber: string, OTP: string) => {
  const fingerprint = await getFingerprint();
  const {
    browser,
    browserMajorVersion,
    os,
    osVersion
  } = browserDetailDelection();
  const params = {
    client_id: clientID,
    client_secret: clientSecret,
    phone: `${phoneNumber}`,
    code: `${OTP}`,
    device_info: {
      model: browser + browserMajorVersion,
      os_ver: osVersion,
      os_name: os,
      app_ver: window.navigator.appVersion,
      info_json: {
        browser_name: browser
      }
    }
  };
  const result: any = await fetchWithAllErrorHandle.url(`${serverUrl3!}/user/verify-otp/`).headers({
    'Content-Type': 'application/json;charset=utf-8',
    'DEVICE-ID': (fingerprint + 'live' as string),
    'X-PLATFORM': '7'
  }).post(params).json(json => {
    if (json && json.access_token && json.refresh_token) {
      setAccessTokenToStoreAndLocal(json.access_token);
      setRefreshTokenToStoreAndLocal(json.refresh_token);
    }
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
export const meApi = async (): Promise<any> => {
  const Authorization = getCookie('access_token');
  const result = await fetchWithAllErrorHandle.url(`${serverUrl1}/profile/me/`).auth(Authorization!).get().json(json => {
    const result = json.data;
    const currentMode = getCookie('mode');
    if (currentMode && currentMode !== 'logged-in') {
      const storedData = streamStorage.getStoredData();
      storedData.forEach(stream => {
        streamStorage.setUserConsent(stream.stream_uid, false);
      });
    }
    setCookie('mode', 'logged-in');
    return (result as IMeResponse);
  }).catch(error => {
    const errObj = {
      statusCode: 0,
      message: '',
      subMessage: ''
    };
    if (
    //CORS ERROR
    error instanceof TypeError && (error.message.includes('Failed to fetch') ||
    //for Chrome
    error.message.includes('Load failed') ||
    //for IOS
    error.message.includes('NetworkError when attempting to fetch resource.' //for Firefox
    ))) {
      errObj.statusCode = 403;
      errObj.message = 'CORS error';
      errObj.subMessage = error.message;
    } else {
      errObj.statusCode = 499;
      errObj.message = error.message;
    }
    return errObj;
  });
  return result;
};
export const fetchMeProfileApi = async (): Promise<any> => {
  const Authorization = getCookie('access_token');
  const result: any = await fetchWithAllErrorHandle.url(`${serverUrl3}/user/me-profile/`).auth(Authorization!).get().json(result => {
    setCookie('mode', 'logged-in');
    return (result as IMeProfileInfo);
  }).catch(error => {
    const errObj = {
      statusCode: 0,
      message: '',
      subMessage: ''
    };
    if (
    //CORS ERROR
    error instanceof TypeError && (error.message.includes('Failed to fetch') ||
    //for Chrome
    error.message.includes('Load failed') ||
    //for IOS
    error.message.includes('NetworkError when attempting to fetch resource.' //for Firefox
    ))) {
      errObj.statusCode = 403;
      errObj.message = 'CORS error';
      errObj.subMessage = error.message;
    } else {
      errObj.statusCode = 499;
      errObj.message = error.message;
    }
    return errObj;
  });
  if (result && result.user_id) {
    result.user_uid = result.user_id;
    result.avatar_url = result.avatar;
  }
  return result;
};
export const fetchAllMeProfileResponse = async () => {
  const [meProfileResp, profileResp, meResp] = await Promise.allSettled([fetchMeProfileApi(), getProfileInfo(), meApi()]);
  const meProfileResult: IMeProfileInfo = meProfileResp.status === 'fulfilled' ? meProfileResp.value : {};
  const profileResult = profileResp.status === 'fulfilled' ? profileResp.value : {};
  const meResult = meResp.status === 'fulfilled' ? meResp.value : {};
  if (!meProfileResult?.username) {
    return meProfileResp.status === 'fulfilled' ? meProfileResp.value : meProfileResp.reason;
  }
  const result: MeInterface = {
    ...meProfileResult,
    user_uid: meProfileResult.user_id,
    avatar_url: meProfileResult.avatar,
    is_username_changed: profileResult?.is_username_changed,
    social_link_info: profileResult?.social_link_info,
    can_stream: meResult?.can_stream,
    profile_tags: meResult?.profile_tags,
    game_ids: meResult?.game_ids,
    is_clip_upload_enabled: meResult?.is_clip_upload_enabled
  };
  return result;
};
export const refreshTokenApi = async ({
  original_url
}: {
  original_url: string;
}) => {
  const Authorization = getCookie('access_token');
  const refreshToken = getCookie('refresh_token');
  const fingerprint = await getFingerprint();
  const params = {
    //@ts-ignore
    refresh_token: refreshToken!
  };
  const _result: any = await fetchWithAllErrorHandle.url(`${serverUrl3}/user/refresh_token/`).headers({
    Authorization: Authorization!,
    'DEVICE-ID': fingerprint + 'live',
    'X-PLATFORM': '7',
    'X-CLIENT-ID': clientID!,
    'X-CLIENT-SECRET': clientSecret!
  }).post(params)
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  .badRequest(err => {
    try {
      const errObj = JSON.parse(err.message);
      return errObj;
    } catch (err_) {
      return err;
    }
  })
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  .unauthorized(err => {
    try {
      const errObj = JSON.parse(err.message);
      return errObj;
    } catch (err_) {
      return err;
    }
  }).json(json => {
    if (json.access_token && json.refresh_token) {
      setAccessTokenToStoreAndLocal(json.access_token);
      setRefreshTokenToStoreAndLocal(json.refresh_token);
    } else {
      removeAccessToken();
      removeRefreshToken();
    }
    return json;
  }).catch(error => {
    return error;
  });
  let result = _result;
  try {
    if (typeof _result === 'string') {
      result = JSON.parse(_result);
    } else {
      result = JSON.stringify(result);
      result = JSON.parse(result);
    }
    result.message = result.message || result.text;
  } catch (err) {
    if (typeof _result === 'string') {
      result = {
        message: _result
      };
    } else {
      result = _result;
    }
  }
  const isTokenPresent = typeof result === 'object' ? !!(result?.access_token && result?.refresh_token) : false;
  if (!isTokenPresent) {
    const logoutErr = new Error('No access token found');
    const bugData = {
      forced: true,
      api_failed: original_url,
      stack: result,
      via_refresh_token: true,
      reason: (typeof result === 'object' ? result?.message : result) || 'RefreshToken Failed'
    };
    Sentry.captureException(logoutErr, {
      extra: {
        statusCode: 401,
        message: 'No access token found',
        ...bugData
      }
    });
    Amplitude.logEvent('logout', bugData);
    resetAmplitudeUser();
  }
  return result;
};
export const logoutUser = async () => {
  const Authorization = getCookie('access_token');
  const refreshToken = getCookie('refresh_token');
  const fingerprint = await getFingerprint();
  const url = `${serverUrl3}/user/signout/`;
  const params = {
    refresh_token: refreshToken,
    old_token: Authorization
  };
  const result: any = await fetchWithAllErrorHandle.url(url).headers({
    Authorization: Authorization!,
    'DEVICE-ID': fingerprint + 'live',
    'X-PLATFORM': '7',
    'X-CLIENT-ID': clientID!,
    'X-CLIENT-SECRET': clientSecret!
  }).post(params).json(json => {
    removeAccessToken();
    removeRefreshToken();
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
export const getDataMapping = async () => {
  const url = '/api/mapping';
  const result = await fetchWithAllErrorHandle.url(url).headers({
    'Content-Type': 'application/json;charset=utf-8'
  }).get().json(json => json).catch(error => Promise.reject(error));
  return result;
};
export const getTokenFromCode = async (code: string) => {
  const params = {
    code
  };
  let url = '/api/auth/gettoken';
  if (typeof window !== 'undefined' && window.location.origin.search('localhost') >= 0) {
    url = 'https://stage1.loco.gg' + url;
  }
  const result = await fetchWithAllErrorHandle.url(url).headers({
    'Content-Type': 'application/json;charset=utf-8'
  }).post(params).json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
export const googleLoginApi = async (googletokenID: string) => {
  const params = {
    social_platform: 10,
    // 10 google, 20->Facebook
    country: 'IN',
    token: googletokenID
  };
  const fingerprint = await getFingerprint();
  const result = await fetchWithAllErrorHandle.url(`${serverUrl3}/user/signin-web/`).headers({
    'Content-Type': 'application/json;charset=utf-8',
    'DEVICE-ID': fingerprint + 'live',
    'X-PLATFORM': '7',
    'X-Client-Id': '' + clientID,
    'X-Client-Secret': '' + clientSecret
  }).post(params).json(json => {
    if (json?.access_token && json?.refresh_token) {
      setAccessTokenToStoreAndLocal(json.access_token);
      setRefreshTokenToStoreAndLocal(json.refresh_token);
    } else {
      removeAccessToken();
      removeRefreshToken();
    }
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
export const facebookLoginApi = async (facebookTokenId: string) => {
  const params = {
    social_platform: 20,
    // 10 google, 20->Facebook
    country: 'IN',
    token: facebookTokenId
  };
  const fingerprint = await getFingerprint();
  const result = await fetchWithAllErrorHandle.url(`${serverUrl3}/user/facebook/signin/`).headers({
    'Content-Type': 'application/json;charset=utf-8',
    'DEVICE-ID': fingerprint + 'live',
    'X-PLATFORM': '7',
    'X-Client-Id': '' + clientID,
    'X-Client-Secret': '' + clientSecret
  }).post(params).json(json => {
    if (json?.access_token && json?.refresh_token) {
      setAccessTokenToStoreAndLocal(json.access_token);
      setRefreshTokenToStoreAndLocal(json.refresh_token);
    } else {
      removeAccessToken();
      removeRefreshToken();
    }
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
export const isUsernameAvailable = async (username: string) => {
  const Authorization = getCookie('access_token');
  const result = await fetchWithAllErrorHandle.url(`${serverUrl3}/user/username_hai_kya/?username=${username}`).headers({
    Authorization: Authorization!,
    'Content-Type': 'application/json;charset=utf-8'
  }).get().json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
export interface updateProfileParams {
  username?: string;
  dob?: string;
  bio?: string;
  avatar_id?: number;
  tags?: string[];
  gender?: number;
  game_ids?: {
    '20097'?: string;
    '571890'?: string;
    '117645'?: string;
  };
  language?: LANGUAGE_PRESET;
}

//@ts-ignore
export const updateProfile = async (params: updateProfileParams): Promise<any> => {
  const Authorization = getCookie('access_token');
  const result = await fetchWithAllErrorHandle.url(`${serverUrl1}/profile/update/`).auth((Authorization as string)).post(params).json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
export const getAvatarList = async (): Promise<any> => {
  const url = `${serverUrl3}/user/upload_avatar/`;
  const Authorization = getCookie('access_token');
  const result = await fetchWithAllErrorHandle.url(url).headers({
    'Content-Type': 'application/json;charset=utf-8'
  }).auth((Authorization as string)).get().error(404, () => {
    return [];
  }).error(403, () => {
    return [];
  }).json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
interface updateAvatarParams {
  avatar_id: number;
}
export const updateAvatar = async (params: updateAvatarParams): Promise<any> => {
  const Authorization = getCookie('access_token');
  const result = await fetchWithAllErrorHandle.url(`${serverUrl3}/user/upload_avatar/`).auth(Authorization!).put(params).json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
export const getsuggestedVideo = async ({
  type,
  limit = '10',
  offset = '0',
  video_uid
}: {
  type: STREAMTYPE;
  limit?: string;
  offset?: string;
  video_uid: string;
}) => {
  const Authorization = getCookie('access_token');
  try {
    const url = new URL(`${serverUrl1}/suggestions/videos/`);
    //@ts-ignore
    url.search = new URLSearchParams({
      type,
      limit,
      offset,
      video_uid
    });

    //@ts-ignore
    const videoSuggestionsResponse = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: Authorization!,
        'X-APP-LANG': getAppLanguage(),
        'X-APP-LOCALE': getAppLocale()
      }
    });
    const suggestedVideos = await videoSuggestionsResponse.json();
    return suggestedVideos;
  } catch (error) {}
  //@ts-ignore
};

export const getStreamChatHistory = async (streamID: string, token: string, lang?: string) => {
  const url = `${CHAT_URL_V2}/streams/${streamID}/history/`;
  const result = await fetchWithAllErrorHandle.url(url).headers({
    'Content-Type': 'application/json;charset=utf-8',
    'X-CLIENT-ID': clientID!,
    'X-CLIENT-SECRET': clientSecret!,
    'X-PLATFORM': '7',
    'X-APP-LANG': lang || 'en'
  }).auth(token).get().error(404, () => {
    return [];
  }).error(403, () => {
    return [];
  }).json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
export const getChatData = async ({
  stream_id,
  type,
  acu_only
}: {
  stream_id: string;
  type?: string;
  acu_only?: boolean;
}) => {
  const url = new URL(`${process.env.CHAT_URL_V2}/streams/${stream_id}/${type ? `${type}/` : ''}chat/`);
  if (acu_only) {
    url.searchParams.append('disable_chat', 'true');
  }
  const token = getCookie('access_token');
  const result = await fetchWithAllErrorHandle.url(url.href).headers({
    'Content-Type': 'application/json;charset=utf-8',
    'X-CLIENT-ID': clientID!,
    'X-CLIENT-SECRET': clientSecret!,
    'X-PLATFORM': '7'
  }).auth(token!).get().error(404, () => {
    return [];
  }).error(403, () => {
    return [];
  }).json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
export const getOptimisticChatData = async (streamId: string) => {
  const authToken = getCookie('access_token');
  // Fetch chat config
  const chatConfigUrl = new URL(`${baseTaskCenterUrl}/reward/chat/config/v1/`);
  chatConfigUrl.searchParams.append('stream_id', streamId);
  const chatConfigResult = await fetchWithAllErrorHandle.url(chatConfigUrl.href).auth(authToken!).get().json(json => json).catch(error => error);

  // Fetch user chat profile
  const selfChatAttributeUrl = new URL(`${baseTaskCenterUrl}/reward/chat/profile/me/v1/`);
  selfChatAttributeUrl.searchParams.append('stream_id', streamId);
  const selfChatAttributeUrlResult = await fetchWithAllErrorHandle.url(selfChatAttributeUrl.href).auth(authToken!).get().json(json => json).catch(error => error);

  // Combine results if both requests were successful
  if (chatConfigResult?.mod_chat_attributes && selfChatAttributeUrlResult?.self_chat_attributes) {
    return {
      ...chatConfigResult,
      ...selfChatAttributeUrlResult
    };
  }

  // Return the successful result if only one request succeeded
  if (chatConfigResult.mod_chat_attributes) {
    return chatConfigResult;
  }
  if (selfChatAttributeUrlResult.self_chat_attributes) {
    return selfChatAttributeUrlResult;
  }

  // Return an empty object if both requests failed
  return {};
};
export const getUserProfileChatData = async (stream_id: string, user_id: string) => {
  const Authorization = getCookie('access_token');
  const url = new URL(`${baseServerUrl}/quests/reward/profile/v1/`);
  url.searchParams.append('stream_id', stream_id);
  url.searchParams.append('user_id', user_id);
  const result = await fetchWithAllErrorHandle.url(url.href).auth(Authorization!).get().json(json => json).catch(error => error);
  return result;
};
export const getFolloweeData = async (streamers: string) => {
  const Authorization = getCookie('access_token');
  const url = `${process.env.SERVER_URL}/profile/streamer_followee_lookup/?&streamers=${streamers}`;
  const result = await fetchWithAllErrorHandle.url(url).headers({
    'Content-Type': 'application/json;charset=utf-8',
    'X-PLATFORM': '7'
  }).auth(Authorization!).get().error(404, () => {
    return {};
  }).error(403, () => {
    return {};
  }).json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
export const getStreamData = async (id: string) => {
  const url = `${process.env.SERVER_URL_V2}/streams/${id}`;
  const token = getCookie('access_token');
  const result = await fetchWithAllErrorHandle.url(url).headers({
    'Content-Type': 'application/json;charset=utf-8',
    // 'X-CLIENT-ID': clientID!,
    // 'X-CLIENT-SECRET': clientSecret!,
    'X-PLATFORM': '7'
  }).auth(token!).get().error(404, () => {
    return {};
  }).error(403, () => {
    return {};
  }).json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return (result as StreamInterface | null);
};
export const getRegistrationCount = async () => {
  const Authorization = await getCookie('access_token');
  const result = await fetchWithAllErrorHandle.url(`${process.env.TASK_CENTER_URL}/quest/regd_user_count?quest_id=${process.env.FREE_FIRE_EVENT_ID}`).auth(Authorization!).get().json(json => json).catch(error => error);
  if (!result?.questId || result.questId !== process.env.FREE_FIRE_EVENT_ID) {
    throw new Error('Something went wrong');
  }
  return {
    count: result.count || 0
  };
};
export const setLocoggDomainCookie = async (data?: any) => {
  await fetch('/api/cookies/set', {
    method: 'POST',
    body: JSON.stringify(data)
  });
};
export const otplessSignIn = async (WaId: string) => {
  const params = {
    wa_id: WaId,
    app_id: OTPLESS_APP_ID
  };
  const fingerprint = await getFingerprint();
  const url = `${serverUrl3}/user/otpless/signin/`;
  const result = await fetchWithAllErrorHandle.url(url).headers({
    'Content-Type': 'application/json;charset=utf-8',
    'DEVICE-ID': fingerprint + 'live',
    'X-PLATFORM': '7',
    'X-CLIENT-ID': clientID!,
    'X-CLIENT-SECRET': clientSecret!
  }).post(params).json(json => {
    if (json?.access_token && json?.refresh_token) {
      setAccessTokenToStoreAndLocal(json.access_token);
      setRefreshTokenToStoreAndLocal(json.refresh_token);
    } else {
      removeAccessToken();
      removeRefreshToken();
    }
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
export const otplessLinkPhone = async (WaId: string) => {
  const params = {
    wa_id: WaId,
    app_id: OTPLESS_APP_ID
  };
  const fingerprint = await getFingerprint();
  const url = `${serverUrl3}/user/otpless/link_phone/`;
  const token = getCookie('access_token');
  const result = await fetchWithAllErrorHandle.url(url).auth(token!).headers({
    'Content-Type': 'application/json;charset=utf-8',
    'DEVICE-ID': fingerprint + 'live',
    'X-PLATFORM': '7',
    'X-CLIENT-ID': clientID!,
    'X-CLIENT-SECRET': clientSecret!
  }).post(params).json(json => json).catch(error => {
    return error;
  });
  return result;
};