import { check_device_isMobile } from '@hooks/react-use/useDeviceInfo';
import create, { SetState } from 'zustand';
import { CONTROLS_TIMEOUT_MOBILE, CONTROLS_TIMEOUT_PIP, CONTROLS_TIMEOUT_WEB } from '../constants';
import { usePlayerStore } from './usePlayerStore';
interface IControlDisplayStore {
  isHoverOnContainer: boolean;
  setIsHoverOnContainer: (value: boolean) => void;
  isControlSectionVisible: boolean;
  // setIsControlSectionVisible: (value: boolean) => void
  isSeekbarClicked: boolean;
  setIsSeekbarClicked: (value: boolean) => void;
  isSettingModalOpen: boolean;
  setIsSettingModalOpen: (value: boolean) => void;
  isSeekbarDisabled: boolean;
  setIsSeekbarDisabled: (value: boolean) => void;
  isMobileView: boolean;
  setMobileView: (value: boolean) => void;
  controlSectionVisibleTimer: number;
  setControlSectionVisibleTimer: (value: number) => void;
  resetControlSectionVisiblity: () => void;
}
const ControlDisplayStore = (set: SetState<IControlDisplayStore>): IControlDisplayStore => ({
  isSettingModalOpen: false,
  setIsSettingModalOpen: isSettingModalOpen => {
    set({
      isSettingModalOpen
    });
  },
  isMobileView: false,
  setMobileView: isMobileView => set({
    isMobileView
  }),
  isHoverOnContainer: false,
  setIsHoverOnContainer: isHoverOnContainer => {
    set({
      isHoverOnContainer
    });
  },
  controlSectionVisibleTimer: 3,
  setControlSectionVisibleTimer: controlSectionVisibleTimer => {
    if (controlSectionVisibleTimer < 0) {
      controlSectionVisibleTimer = 0;
    }
    if (controlSectionVisibleTimer === 0) {
      const {
        isPortalOpen
      } = usePlayerStore.getState();
      const isMobileView = check_device_isMobile();
      const timeoutDuration = (isPortalOpen ? CONTROLS_TIMEOUT_PIP : isMobileView ? CONTROLS_TIMEOUT_MOBILE : CONTROLS_TIMEOUT_WEB) || 3;
      set({
        controlSectionVisibleTimer: timeoutDuration,
        isControlSectionVisible: false
      });
    } else {
      set({
        controlSectionVisibleTimer
      });
    }
  },
  resetControlSectionVisiblity: () => {
    const {
      isPortalOpen
    } = usePlayerStore.getState();
    const isMobileView = check_device_isMobile();
    const timeoutDuration = (isPortalOpen ? CONTROLS_TIMEOUT_PIP : isMobileView ? CONTROLS_TIMEOUT_MOBILE : CONTROLS_TIMEOUT_WEB) || 3;
    set({
      controlSectionVisibleTimer: timeoutDuration,
      isControlSectionVisible: true
    });
  },
  isControlSectionVisible: false,
  // setIsControlSectionVisible: (isControlSectionVisible) => {
  //   set({ isControlSectionVisible })
  // },

  isSeekbarClicked: false,
  setIsSeekbarClicked: isSeekbarClicked => {
    set({
      isSeekbarClicked
    });
  },
  isSeekbarDisabled: false,
  setIsSeekbarDisabled: isSeekbarDisabled => {
    set({
      isSeekbarDisabled
    });
  }
});
export const useControlDisplayStore = create<IControlDisplayStore>(ControlDisplayStore);