import { useTranslation } from '../i18n';
import { PAGE_TITLES_MAP } from './helper';
export const usePageTitlesTranslation = () => {
  const {
    t
  } = useTranslation();
  const getTranslation = (key: string) => t(key);
  return (page_name: string) => {
    const page_name_lower = page_name.toLowerCase();
    const i18nKey = PAGE_TITLES_MAP[page_name_lower];
    if (i18nKey) {
      return getTranslation(i18nKey);
    } else {
      return page_name;
    }
  };
};