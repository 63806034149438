import { IProfileInfoResponse } from '@customTypes/MeInterfaceTypes';
import { getCookie } from '@utils/cookies';
import { fetchWithAllErrorHandle } from './apiWrapper';
const clientID = (process.env.CLIENT_ID as string);
const clientSecret = (process.env.CLIENT_SECRET as string);
const serverUrl1 = (process.env.SERVER_URL as string);
const serverUrl3 = (process.env.SERVER_URL_V3 as string);
// const storeUrl1 = process.env.STORE_URL_V1 as string
const yenServer = (process.env.YEN_SERVER as string);
export const getFollowingStreamers = async (userId: string): Promise<any> => {
  const url = `${serverUrl1}/profile/${userId}/followee/?offset=&limit=12&order_by=recent`;
  const Authorization = getCookie('access_token');
  const result = await fetchWithAllErrorHandle.url(url).headers({
    'Content-Type': 'application/json;charset=utf-8'
  }).auth((Authorization as string)).get().error(404, () => {
    return [];
  }).error(403, () => {
    return [];
  }).json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
export const getAllTags = async (): Promise<any> => {
  const url = `${serverUrl1}/profile/tags/?limit=100`;
  const Authorization = getCookie('access_token');
  const result = await fetchWithAllErrorHandle.url(url).headers({
    'Content-Type': 'application/json;charset=utf-8'
  }).auth((Authorization as string)).get().error(404, () => {
    return [];
  }).error(403, () => {
    return [];
  }).json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
export const linkSocialAccounts = async ({
  token,
  social_platform
}: {
  token: string;
  social_platform: number;
}): Promise<any> => {
  const authToken = getCookie('access_token');
  const res = await fetchWithAllErrorHandle.url(`${serverUrl3}/user/link_social/`).headers({
    'Content-Type': 'application/json',
    'X-CLIENT-ID': clientID,
    'X-CLIENT-SECRET': clientSecret,
    'X-PLATFORM': '7',
    Authorization: authToken!
  }).post(JSON.stringify({
    token,
    social_platform
  })).json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return res;
};
export const getWalletDetails = async (): Promise<any> => {
  const authToken = getCookie('access_token');
  const res = await fetchWithAllErrorHandle.url(`${yenServer}/wallet/all/`).headers({
    'Content-Type': 'application/json',
    Authorization: authToken!
  }).get().json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return res;
};
export const getWatchList = async (url?: string): Promise<any> => {
  const authToken = getCookie('access_token');
  let endPoint = `${serverUrl1}`;
  if (url) {
    endPoint += url.replace('/v1', '');
  } else {
    endPoint += '/profile/watch_list/?limit=20';
  }
  const res = await fetchWithAllErrorHandle.url(endPoint).headers({
    'Content-Type': 'application/json',
    Authorization: authToken!
  }).get().json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return res;
};
export const getProfileInfo = async (): Promise<any> => {
  const authToken = getCookie('access_token');
  const res = await fetchWithAllErrorHandle.url(`${serverUrl3}/user/profile_info/`).headers({
    'Content-Type': 'application/json',
    Authorization: authToken!,
    'X-PLATFORM': '7'
  }).get().json(json => {
    return (json as IProfileInfoResponse);
  }).catch(error => {
    return error;
  });
  return res;
};
export const unLinkSocialAccounts = async (social_platform: number): Promise<any> => {
  const authToken = getCookie('access_token');
  const res = await fetchWithAllErrorHandle.url(`${serverUrl3}/user/unlink_social/`).headers({
    'Content-Type': 'application/json',
    Authorization: authToken!
  }).post({
    social_platform
  }).json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return res;
};
export const linkPhoneNumberApi = async ({
  phone,
  country
}: {
  phone: string;
  country?: string;
}): Promise<any> => {
  const authToken = getCookie('access_token');
  const res = await fetchWithAllErrorHandle.url(`${serverUrl3}/user/link_phone_otp/`).headers({
    'Content-Type': 'application/json',
    Authorization: authToken!
  }).post(JSON.stringify({
    phone,
    country
  })).json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return res;
};
export const verifyPhoneOTP = async ({
  phone,
  code
}: {
  phone: string;
  code: string;
}): Promise<any> => {
  const url = `${serverUrl3}/user/link_phone/`;
  const Authorization = getCookie('access_token');
  const params = {
    phone: phone,
    code: code
  };
  const result = await fetchWithAllErrorHandle.url(url).auth((Authorization as string)).headers({
    'Content-Type': 'application/json;charset=utf-8'
  }).post(params).json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};

// type UpdateProfileType = {
//   bio: string
//   gender: number
//   dob: string
//   tags: string[]
//   game_ids: { [key: string]: string }[]
// }

// export const updateProfile = async ({
//   bio,
//   gender,
//   dob,
//   tags,
//   game_ids,
// }: UpdateProfileType): Promise<any> => {
//   const url = `${serverUrl3 as string}/user/update_profile/`
//   return await fetchHandler({
//     url,
//     method: 'POST',
//     body: { bio, gender, dob, tags, game_ids },
//   })
// }