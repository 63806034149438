import { useState } from 'react';
function useDisclouser(initialVal = false) {
  const [isOpen, setIsOpen] = useState<boolean>(initialVal);
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);
  const onToggle = () => setIsOpen(prevState => !prevState);
  return {
    isOpen,
    onOpen,
    onClose,
    onToggle
  };
}
export default useDisclouser;