import { load } from '@fingerprintjs/botd';
import { useAppStore } from '@stores/appStore';
enum BotKind {
  Unknown = 'unknown',
  HeadlessChrome = 'headless_chrome',
  PhantomJS = 'phantomjs',
  Nightmare = 'nightmare',
  Selenium = 'selenium',
  Electron = 'electron',
  NodeJS = 'nodejs',
  Rhino = 'rhino',
  CouchJS = 'couchjs',
  Sequentum = 'sequentum',
  SlimerJS = 'slimerjs',
  CefSharp = 'cefsharp',
}
export type BotDetectionResult = {
  bot: true;
  botKind: BotKind;
} | {
  bot: false;
};
export function checkIfBot() {
  const botdPromise = load({
    monitoring: false
  });
  useAppStore.setState({
    botInfoLoading: true
  });
  // Get detection results when you need them.
  botdPromise.then(botd => botd.detect())
  //@ts-ignore
  .then((result: BotDetectionResult) => {
    useAppStore.setState({
      botInfo: result,
      botInfoLoading: false
    });
  }).catch(error => {
    useAppStore.setState({
      botInfoLoading: false
    });
    console.error(error);
  });
}