import { StreamInterface } from '@customTypes/index';
import create, { StoreApi, UseBoundStore } from 'zustand';
type IPlayerStore = {
  __counter_value: number; // 60 seconds.. when it go to 0, event will triggered & it reset to 60 again
  // Counter Metric
  sent_message_chat_count: number;
  sent_message_comment_count: number;
  sent_message_reply_count: number;
  chat_section_collapsed_count: number;
  chat_section_reopen_count: number;
  player_report_content_count: number;
  player_play_pause_toggle_count: number;
  player_keyboard_seek_count: number;
  // player_button_seek_count: 0, No buttons on seekbar
  // gold_earned: 0, No in use now
  player_seek_bar_count: number;
  trick_play_mode_count: number;
  theatre_mode_toggle_count: number;
  fullscreen_mode_toggle_count: number;
  pip_mode_toggle_count: number;
  mute_toggle_count: number;
  times_buffered: number;
  watch_duration_without_seekbar_used: number;
  total_watch_duration: number;
  buffered_time_in_seconds: number;
  pip_mode_watch_duration: number;
  mute_duration: number;
  theatre_mode_watch_duration: number;
  trick_play_mode_duration: number;
  fullscreen_mode_watch_duration: number;

  // Non-Counter Metric
  playback_speed: number[];
  player_report_content: boolean;
  player_report_content_type: string[];
  manual_bitrate_change: string[];
  player_pip_close_button_clicked: boolean;
  player_pip_expand: boolean;

  // Contitions for metric
};

interface IOtherProps {
  // Non-Counter Metric
  web_perf_render_ui_time_ms: number; // similar to fcp
  web_perf_playback_api_time_ms: number; // drm api time to response
  web_perf_stream_start_time_ms: number; // after load api in player till first chunk is loaded [include some hardcoded time]
  stream_join_time: number;
  stream_join_time_in_epoch: number;
  initial_bitrate: string;
  stream_info: StreamInterface | null;
}
const otherDefaultValues: IOtherProps = {
  // Non-Counter Metric
  web_perf_render_ui_time_ms: 0,
  // similar to fcp
  web_perf_playback_api_time_ms: 0,
  // drm api time to response
  web_perf_stream_start_time_ms: 0,
  // after load api in player till first chunk is loaded [include some hardcoded time]

  stream_join_time: 0,
  stream_join_time_in_epoch: 0,
  initial_bitrate: '',
  stream_info: null
};
const defaultValuesToReset: IPlayerStore = {
  __counter_value: 60,
  // Counter Metric
  sent_message_chat_count: 0,
  sent_message_comment_count: 0,
  sent_message_reply_count: 0,
  player_report_content_count: 0,
  player_play_pause_toggle_count: 0,
  player_keyboard_seek_count: 0,
  player_seek_bar_count: 0,
  trick_play_mode_count: 0,
  theatre_mode_toggle_count: 0,
  fullscreen_mode_toggle_count: 0,
  pip_mode_toggle_count: 0,
  mute_toggle_count: 0,
  times_buffered: 0,
  chat_section_collapsed_count: 0,
  chat_section_reopen_count: 0,
  watch_duration_without_seekbar_used: 0,
  total_watch_duration: 0,
  buffered_time_in_seconds: 0,
  pip_mode_watch_duration: 0,
  mute_duration: 0,
  theatre_mode_watch_duration: 0,
  trick_play_mode_duration: 0,
  fullscreen_mode_watch_duration: 0,
  // Non-Counter Metric
  playback_speed: [],
  player_report_content: true,
  player_report_content_type: [],
  manual_bitrate_change: [],
  player_pip_close_button_clicked: false,
  player_pip_expand: false

  // Contitions for metric
};

const store = (): IPlayerStore & IOtherProps => ({
  ...defaultValuesToReset,
  ...otherDefaultValues
});
const InstancesMap = new Map<string, UseBoundStore<IPlayerStore & IOtherProps, StoreApi<IPlayerStore & IOtherProps>>>();
const ActiveStreamIdsMap = new Map<string, string>();
const onHandleVisitStreamToRemoveOlderData = (streamUid: string) => {
  const isHadCurrentStream = ActiveStreamIdsMap.has(streamUid);
  if (isHadCurrentStream) {
    ActiveStreamIdsMap.delete(streamUid);
  }
  ActiveStreamIdsMap.set(streamUid, '');
  const TotalEntries = [...ActiveStreamIdsMap.keys()];
  if (TotalEntries.length > 2) {
    // Always Only have 2 stream in active state, Current & previous
    const firstEntry = TotalEntries[0];
    ActiveStreamIdsMap.delete(firstEntry);
    InstancesMap.delete(firstEntry);
    InstancesMap.delete('watch_' + firstEntry);
    InstancesMap.delete('stats_' + firstEntry);
  }
};
const getStreamEvents = (streamUid: string) => {
  const currentEventComponent = InstancesMap.get(streamUid);
  // // Test Code::: Only for stage2, localhost etc
  // if (typeof window !== 'undefined') {
  //   window.__eventMap = () => InstancesMap
  // }
  if (!currentEventComponent) {
    const newComponent = create<IPlayerStore & IOtherProps>(store);
    InstancesMap.set(streamUid, newComponent);
    return newComponent;
  }
  return currentEventComponent;
};
export const usePlayerEventStore = (streamUid: string) => {
  const usePlayerEventStore_ = getStreamEvents(streamUid);
  return usePlayerEventStore_;
};
export const useWatchIntervalEventData = (streamUid: string) => {
  const usePlayerEventStore_ = getStreamEvents('watch_' + streamUid);
  return usePlayerEventStore_;
};
export const useStreamStatsEventData = (streamUid: string) => {
  const usePlayerEventStore_ = getStreamEvents('stats_' + streamUid);
  return usePlayerEventStore_;
};
export const fetchEventData = (streamUid: string) => {
  const usePlayerEventStore_ = getStreamEvents(streamUid);
  return usePlayerEventStore_.getState();
};
export const fetchWatchIntervalEventData = (streamUid: string) => {
  const usePlayerEventStore_ = getStreamEvents('watch_' + streamUid);
  return usePlayerEventStore_.getState();
};
export const fetchStreamStatsEventData = (streamUid: string) => {
  const usePlayerEventStore_ = getStreamEvents('stats_' + streamUid);
  return usePlayerEventStore_.getState();
};
function resetEvent(streamUid: string) {
  // Reset individually

  const playerEventStore = getStreamEvents(streamUid);
  playerEventStore.setState({
    ...defaultValuesToReset
  });
}
function resetStreamStatsEvent(streamUid: string) {
  // Reset individually

  const playerEventStore = getStreamEvents('stats_' + streamUid);
  playerEventStore.setState({
    ...defaultValuesToReset
  });
}
function resetWatchStremEvent(streamUid: string) {
  // Reset individually

  const playerEventStore = getStreamEvents('watch_' + streamUid);
  playerEventStore.setState({
    ...defaultValuesToReset
  });
}
function increaseStreamStatsCount(streamUid: string, name: keyof (IPlayerStore & IOtherProps), increment?: number) {
  increment = increment || 1; // default increase by 1

  const statsStreamStore = getStreamEvents('stats_' + streamUid);
  const cb = (prev: IPlayerStore & IOtherProps) => {
    if (name in prev) {
      const oldValue = prev[name];
      return {
        ...prev,
        // @ts-ignore
        [name]: oldValue + increment
      };
    } else {
      return {
        ...prev
      };
    }
  };
  statsStreamStore.setState(cb);
}
function increaseWatchStreamCount(streamUid: string, name: keyof (IPlayerStore & IOtherProps), increment?: number) {
  increment = increment || 1; // default increase by 1
  const watchStreamStore = getStreamEvents('watch_' + streamUid);
  const cb = (prev: IPlayerStore & IOtherProps) => {
    if (name in prev) {
      const oldValue = prev[name];
      return {
        ...prev,
        // @ts-ignore
        [name]: oldValue + increment
      };
    } else {
      return {
        ...prev
      };
    }
  };
  watchStreamStore.setState(cb);
}
function increaseCount(streamUid: string, name: keyof (IPlayerStore & IOtherProps), increment?: number) {
  increment = increment || 1; // default increase by 1
  const playerEventStore = getStreamEvents(streamUid);
  const watchStreamStore = getStreamEvents('watch_' + streamUid);
  const statsStreamStore = getStreamEvents('stats_' + streamUid);
  const cb = (prev: IPlayerStore & IOtherProps) => {
    if (name in prev) {
      const oldValue = prev[name];
      return {
        ...prev,
        // @ts-ignore
        [name]: oldValue + increment
      };
    } else {
      return {
        ...prev
      };
    }
  };
  playerEventStore.setState(cb);
  watchStreamStore.setState(cb);
  statsStreamStore.setState(cb);
}
function updateMetric(streamUid: string, name: keyof (IPlayerStore & IOtherProps), value: any) {
  const playerEventStore = getStreamEvents(streamUid);
  const watchStreamStore = getStreamEvents('watch_' + streamUid);
  const statsStreamStore = getStreamEvents('stats_' + streamUid);
  const cb = (prev: IPlayerStore & IOtherProps) => {
    if (name in prev) {
      return {
        ...prev,
        [name]: value
      };
    } else {
      return {
        ...prev
      };
    }
  };
  playerEventStore.setState(cb);
  watchStreamStore.setState(cb);
  statsStreamStore.setState(cb);
}
function appendMetric(streamUid: string, name: keyof (IPlayerStore & IOtherProps), value: any) {
  const playerEventStore = getStreamEvents(streamUid);
  const watchStreamStore = getStreamEvents('watch_' + streamUid);
  const statsStreamStore = getStreamEvents('stats_' + streamUid);
  const cb = (prev: IPlayerStore & IOtherProps) => {
    if (name in prev) {
      const oldValue = prev[name];
      return {
        ...prev,
        // @ts-ignore
        [name]: [...oldValue, value]
      };
    } else {
      return {
        ...prev
      };
    }
  };
  playerEventStore.setState(cb);
  watchStreamStore.setState(cb);
  statsStreamStore.setState(cb);
}
export { appendMetric, increaseCount, increaseStreamStatsCount, increaseWatchStreamCount, onHandleVisitStreamToRemoveOlderData, resetEvent, resetStreamStatsEvent, resetWatchStremEvent, updateMetric };