import { DEFAULT_LANGUAGE, LANGUAGE_COOKIE, LANGUAGE_PRESET, SUPPORTED_LANGUAGE } from './constants_i18n';
const parseSelectedLanguage = (language?: string): LANGUAGE_PRESET => {
  // Supported Languages are  ['en', 'pt-BR']
  if (language && SUPPORTED_LANGUAGE.includes(language)) {
    return (language as LANGUAGE_PRESET);
  } else {
    return DEFAULT_LANGUAGE;
  }
};
const parseCookie = (key: string, str: string) => {
  if (!str.includes(key)) {
    return {
      [key]: ''
    };
  }
  return (typeof str === 'string' ? str : '').split(';').map(v => (v || '').split('=')).reduce((acc, v) => {
    const keyName = typeof v[0] === 'string' ? v[0] : '';
    const valueName = typeof v[1] === 'string' ? v[1] : '';
    acc[decodeURIComponent(keyName.trim())] = decodeURIComponent(valueName.trim());
    return acc;
  }, ({} as {
    [key: string]: string;
  }));
};
const extractSelectedLanguage = (cookie: string, browserDefaultLang?: string) => {
  const cookieJSON = parseCookie(LANGUAGE_COOKIE, cookie);
  const languageFromCookie = cookieJSON[LANGUAGE_COOKIE];
  let firstKnownLanguage = '';
  if (browserDefaultLang) {
    const firstPart = browserDefaultLang.split(',')[0] || ''; // en-US,en;q=0.9,hi;q=0.8
    const languagePrefix = firstPart.split('-')[0];
    firstKnownLanguage = languagePrefix;
    if (firstPart.toLowerCase() === 'pt-br') {
      firstKnownLanguage = 'pt-BR';
    }
  }
  return parseSelectedLanguage(languageFromCookie || firstKnownLanguage);
};
const generateCookieResp = (value: string) => {
  value = parseSelectedLanguage(value);
  return `${LANGUAGE_COOKIE}=${value};Path=/;Max-Age=86400;`;
};
const checkIsFirstTimeBrowserLoaded = () => {
  if (typeof window === 'undefined') {
    return true;
  }
  const cookie = document.cookie || '';
  const DUMMY_COOKIE = 'i18n_init';
  const cookieJSON = parseCookie(DUMMY_COOKIE, cookie);
  const languageFromCookie = cookieJSON[DUMMY_COOKIE];
  document.cookie = `${DUMMY_COOKIE}=1;Path=/;Max-Age=86400;`;
  return languageFromCookie !== '1';
};
export const getRawLanguageFromCookie = (cookie: string) => {
  const cookieJSON = parseCookie(LANGUAGE_COOKIE, cookie);
  const languageFromCookie = cookieJSON[LANGUAGE_COOKIE];
  return languageFromCookie;
};
export { checkIsFirstTimeBrowserLoaded, extractSelectedLanguage, generateCookieResp, parseSelectedLanguage };