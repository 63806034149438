import type { Gauge, Histogram, Registry } from 'prom-client';
declare global {
  // eslint-disable-next-line no-var
  var promRegistry: Registry | undefined;
  // eslint-disable-next-line no-var
  var performanceMeasureHistogram: Histogram<string> | undefined;
  // eslint-disable-next-line no-var
  var requestsHistogram: Histogram<string> | undefined;
  // eslint-disable-next-line no-var
  var heapUsedGauge: Gauge<string> | undefined;
  // eslint-disable-next-line no-var
  var heapTotalGauge: Gauge<string> | undefined;
}
const REQ_HISTOGRAM_LABELS = ["pod_name", "pod_ip", "path", "method", "status_code"];
export const isServerSide = () => typeof window === 'undefined' && typeof process !== 'undefined';
export async function initializePrometheus() {
  if (isServerSide() && !global.promRegistry) {
    try {
      const {
        Registry,
        Histogram,
        Gauge
      } = await import('prom-client');
      if (!global.promRegistry) {
        global.promRegistry = new Registry();
      }
      if (!global.performanceMeasureHistogram) {
        global.performanceMeasureHistogram = new Histogram({
          name: 'example_measure',
          help: 'example_measure',
          labelNames: ['hash', 'metric'],
          registers: [global.promRegistry]
        });
      }
      if (!global.requestsHistogram) {
        global.requestsHistogram = new Histogram({
          name: "requests",
          help: "api calls to the service",
          labelNames: REQ_HISTOGRAM_LABELS,
          buckets: [10, 20, 30, 50, 70, 90, 100, 150, 200, 500, 1000, 2000],
          registers: [global.promRegistry]
        });
      }
      if (!global.heapUsedGauge) {
        global.heapUsedGauge = new Gauge({
          name: 'nodejs_heap_used_bytes',
          help: 'Used heap size in bytes',
          registers: [global.promRegistry]
        });
      }
      if (!global.heapTotalGauge) {
        global.heapTotalGauge = new Gauge({
          name: 'nodejs_heap_total_bytes',
          help: 'Total heap size in bytes',
          registers: [global.promRegistry]
        });
      }

      // Update memory metrics every 5 seconds
      setInterval(() => {
        const memoryUsage = process.memoryUsage();
        global.heapUsedGauge?.set(memoryUsage.heapUsed);
        global.heapTotalGauge?.set(memoryUsage.heapTotal);
      }, 5000);
    } catch (error) {
      console.error('Failed to initialize Prometheus:', error);
    }
  }
}
export const getPromRegistry = () => global.promRegistry || null;
export const getPerformanceMeasureHistogram = () => global.performanceMeasureHistogram || null;
export const observeRequest = (timeInMs: number, urlStr: string, method: string, statusCode: string) => {
  if (global.requestsHistogram) {
    global.requestsHistogram.labels(process.env.MY_POD_NAME || 'unknown', process.env.MY_POD_IP || 'unknown', urlStr, method, statusCode).observe(timeInMs);
  }
};