import useIsomorphicEffect from '@hooks/useIsomorphicEffect';
import { CheckIsIOS } from '@utils/browserDetailDetection';
import isMobile from 'is-mobile';
import { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import create, { SetState } from 'zustand';

/**
 * 
Mobile::
sm	640px	@media (min-width: 640px) { ... }
md	768px	@media (min-width: 768px) { ... }

Desktop::
lg	1024px	@media (min-width: 1024px) { ... }

// Large Devices
xl	1280px	@media (min-width: 1280px) { ... }
2xl	1536px

Mobile:::
sm_mob: { max: '639px' },
md_tab: { max: '767px' },

Desktop
lg_desktop: { min: '768px' },
tab: { min: '640px', max: '767px' },
xl_tab: { min: '768px', max: '1023px' },

// Large Device
'2xl_tab': { min: '1024px', max: '1279px' },
xl_desktop: { min: '1280px' },

*/

interface IDeviceStore {
  screen_isMobile: boolean;
  set_screen_isMobile: (screen_isMobile: boolean) => void;
  screen_isTablet: boolean;
  set_screen_isTablet: (screen_isTablet: boolean) => void;
  screen_isDesktop: boolean;
  set_screen_isDesktop: (screen_isDesktop: boolean) => void;
}
const useDeviceInfoStore = create((set: SetState<IDeviceStore>) => {
  return {
    screen_isMobile: false,
    set_screen_isMobile: (value: boolean) => {
      set({
        screen_isMobile: value
      });
    },
    screen_isTablet: false,
    set_screen_isTablet: (value: boolean) => {
      set({
        screen_isTablet: value
      });
    },
    screen_isDesktop: true,
    set_screen_isDesktop: (value: boolean) => {
      set({
        screen_isDesktop: value
      });
    }
  };
});

// 1. To send events values
export const check_device_isMobile = () => Boolean(typeof window !== 'undefined' && isMobile());
export const check_device_isiOS = () => Boolean(typeof window !== 'undefined' && CheckIsIOS());
export default function useDeviceInfo() {
  // 1. To show web design in landscape mode
  const {
    screen_isMobile,
    screen_isTablet,
    screen_isDesktop
  } = useDeviceInfoStore();
  const [a, b] = useState(false);
  useIsomorphicEffect(() => {
    b(true);
  }, []);
  return {
    height: typeof window !== 'undefined' && window.innerHeight,
    width: typeof window !== 'undefined' && window.innerWidth,
    device_isMobile: a ? check_device_isMobile() : false,
    device_isiOS: a ? check_device_isiOS() : false,
    screen_isMobile,
    screen_isTablet,
    screen_isDesktop
  };
}
const DeviceInfoInitialization = () => {
  const {
    width
  } = useWindowSize();
  useEffect(() => {
    const {
      set_screen_isMobile,
      // setMobile
      set_screen_isTablet,
      // setTablet
      set_screen_isDesktop // setDesktop
    } = useDeviceInfoStore.getState();
    // Tab is from 768, but we used sm: in tw for mobile thus using 639
    set_screen_isMobile(width < 768);
    set_screen_isTablet(width >= 768 && width < 1024);
    set_screen_isDesktop(width >= 1024);
  }, [width]);
};
useDeviceInfo.DeviceInfoInitialization = DeviceInfoInitialization;