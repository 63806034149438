import React, { useState } from 'react';
import { useEffect } from 'react';
import * as workerTimer from 'worker-timers';
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const HideCard = ({
  duration,
  children
}: React.PropsWithChildren<{
  duration?: number;
}>) => {
  const [isShowChild, setShowChild] = useState(true);
  useEffect(() => {
    setShowChild(true);
    const timer = workerTimer.setTimeout(() => {
      setShowChild(false);
    }, duration || 1500);
    return () => {
      workerTimer.clearTimeout(timer);
    };
  }, [duration]);
  return _jsx(_Fragment, {
    children: isShowChild && children
  });
};
export default HideCard;