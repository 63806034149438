export const setLoginInfo = (obj: {
  email?: string;
  phoneNumber?: string;
  userUid?: string;
}) => {
  localStorage.setItem('userUid', String(obj.userUid));
  localStorage.setItem('userlogin', JSON.stringify(obj));
};
export const getLoginInfo = () => {
  const userLoginMethod = localStorage.getItem('userlogin');
  return userLoginMethod ? JSON.parse(userLoginMethod) : null;
};
export const removeLoginInfo = () => {
  localStorage.removeItem('userUid');
  localStorage.removeItem('userlogin');
  return;
};