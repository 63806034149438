export const checkIfFatalError = ({
  type,
  code
}: {
  type: string;
  code: number;
}) => {
  return code === 404 || code === -1 && type !== 'Error' || code === 403 && type !== 'ErrorNetwork' || type === 'ErrorNetworkIO';
};
export const isiOS = () => {
  if (typeof navigator === 'undefined') {
    return false;
  }
  return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator?.platform) || navigator?.userAgent?.includes('Mac') && 'ontouchend' in document;
};
export const formatTime = (timeInSeconds = 0) => {
  let seconds: number | string = Math.floor(timeInSeconds % 60);
  let minutes: number | string = Math.floor(timeInSeconds / 60 % 60);
  let hours: number | string = Math.floor(timeInSeconds / (60 * 60));
  seconds = seconds.toString().padStart(2, '0');
  if (timeInSeconds >= 600) {
    minutes = minutes.toString().padStart(2, '0');
  }
  if (timeInSeconds >= 36000) {
    hours = hours.toString().padStart(2, '0');
  }
  return timeInSeconds >= 3600 ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`;
};
export const getFullScreen = () => {
  return !(!document.fullscreenElement &&
  // @ts-expect-error
  !document.mozFullScreenElement &&
  // @ts-expect-error
  !document.webkitFullscreenElement &&
  // @ts-expect-error
  !document.msFullscreenElement);
};
export const exitFullScreen = () => {
  if (!getFullScreen()) {
    return;
  }
  if (document.exitFullscreen) document.exitFullscreen();
  // @ts-expect-error
  else if (document.msExitFullscreen) document.msExitFullscreen();
  // @ts-expect-error
  else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
  // @ts-expect-error
  else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
};
export const enterFullScreen = (element: HTMLElement) => {
  if (getFullScreen()) {
    return;
  }
  if (element.requestFullscreen) element.requestFullscreen();
  // @ts-expect-error
  else if (element.webkitRequestFullscreen)
    // @ts-expect-error
    element.webkitRequestFullscreen();
    // @ts-expect-error
  else if (element.msRequestFullScreen)
    // @ts-expect-error
    element.msRequestFullScreen();
    // @ts-expect-error
  else if (element.mozRequestFullScreen)
    // @ts-expect-error
    element.mozRequestFullScreen();
};
export const isFullScreenApiAvailable = () => {
  return typeof document.exitFullscreen === 'function' ||
  // @ts-expect-error
  typeof document.msExitFullscreen === 'function' ||
  // @ts-expect-error
  typeof document.mozCancelFullScreen === 'function' ||
  // @ts-expect-error
  typeof document.webkitExitFullscreen === 'function';
};
export const checkIfDebugLogsNeeded = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  const urlHost = window.location.host;
  if (urlHost === 'stage2.loco.gg' || urlHost === 'stage1.loco.gg' || urlHost.includes('localhost')) {
    return window.localStorage.getItem('x-loco-debug') === 'true';
  } else {
    return false;
  }
};