function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { useStreamStore } from '@stores/streamStore';
import React, { useEffect } from 'react';
import * as workerTimers from 'worker-timers';
import Animate1 from './Helper/Animate1';
import { useTrickPlayStore } from './TrickPlayStore';
import TrickPlayThumbnail from './TrickPlayThumbnail';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const formatTime = (timeInSeconds = 0) => {
  let seconds: number | string = Math.floor(timeInSeconds % 60);
  let minutes: number | string = Math.floor(timeInSeconds / 60 % 60);
  let hours: number | string = Math.floor(timeInSeconds / (60 * 60));
  seconds = seconds.toString().padStart(2, '0');
  if (timeInSeconds >= 600) {
    minutes = minutes.toString().padStart(2, '0');
  }
  if (timeInSeconds >= 36000) {
    hours = hours.toString().padStart(2, '0');
  }
  return timeInSeconds >= 3600 ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`;
};
const {
  setLoading,
  setThumbnailResponse,
  setTrickUrlPrefix,
  setIsVisible
} = useTrickPlayStore.getState();
var _ref = process.env.NODE_ENV === "production" ? {
  name: "6m4acj",
  styles: "z-index:2;margin-bottom:0.125rem;background-color:rgba(0, 0, 0, 0.5);padding-top:0.5rem;padding-bottom:0.5rem;padding-left:0.75rem;padding-right:0.75rem"
} : {
  name: "skjotb-TrickPlay",
  styles: "z-index:2;margin-bottom:0.125rem;background-color:rgba(0, 0, 0, 0.5);padding-top:0.5rem;padding-bottom:0.5rem;padding-left:0.75rem;padding-right:0.75rem;label:TrickPlay;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "1didiox",
  styles: "position:absolute;bottom:0px;z-index:2;margin-bottom:0px;padding-top:0.25rem;padding-bottom:0.25rem;padding-left:0.5rem;padding-right:0.5rem"
} : {
  name: "vyk9ol-TrickPlay",
  styles: "position:absolute;bottom:0px;z-index:2;margin-bottom:0px;padding-top:0.25rem;padding-bottom:0.25rem;padding-left:0.5rem;padding-right:0.5rem;label:TrickPlay;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const TrickPlay = () => {
  const trick_play_url = useStreamStore(state => state.stream?.trick_play_url);
  const isLiveVideo = useStreamStore(state => state.stream?.status === 20);
  const {
    isVisible,
    currentDuration,
    thumbnailPrefix,
    thumbnailResponse
  } = useTrickPlayStore();
  useEffect(() => {
    setIsVisible(false);
    setThumbnailResponse(null);
  }, [thumbnailPrefix]);
  useEffect(() => {
    if (!trick_play_url) {
      setLoading(false);
      setTrickUrlPrefix('');
      setThumbnailResponse(null);
      return;
    }
    setLoading(true);
    {
      const _ = trick_play_url.split('/');
      _.pop();
      setTrickUrlPrefix(_.join('/') + '/');
    }
    // const make api call
    const MakeApiCall = async () => {
      setLoading(true);
      try {
        const data = await fetch(trick_play_url, {
          credentials: 'include'
        }).then(async res => {
          if (res.ok) {
            return res.json();
          }
          return Promise.reject({
            status: res.status,
            message: await res.text()
          });
        });
        setThumbnailResponse(data);
      } catch (err) {
        console.error('Unable to fetch trick_play_url:', err);
      } finally {
        setLoading(false);
      }
    };
    const falbackTimer = workerTimers.setTimeout(() => {
      MakeApiCall();
    }, 1000);
    let liveVideoTimerRef: null | number = null;
    if (isLiveVideo) {
      liveVideoTimerRef = workerTimers.setInterval(() => {
        MakeApiCall();
      }, 30 * 1000);
    }
    return () => {
      liveVideoTimerRef !== null && workerTimers.clearInterval(liveVideoTimerRef);
      workerTimers.clearTimeout(falbackTimer);
    };
  }, [isLiveVideo, trick_play_url]);
  return _jsx(_Fragment, {
    children: _jsx(Animate1, {
      isShown: isVisible,
      children: !trick_play_url || !thumbnailResponse ?
      // Just return time
      _jsx("div", {
        css: _ref,
        children: formatTime(currentDuration)
      }) : _jsxs(_Fragment, {
        children: [_jsx(TrickPlayThumbnail, {}), _jsx("div", {
          css: _ref2,
          children: formatTime(currentDuration)
        })]
      })
    })
  });
};
export default TrickPlay;