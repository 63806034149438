function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { useDeviceInfo } from '@hooks/react-use';
import { useStreamStore } from '@stores/streamStore';
import React, { useEffect, useState } from 'react';
import CacheImage from './Helper/CacheImage';
import { IThumbnailSegment, useTrickPlayStore } from './TrickPlayStore';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const BinarySearch = (segments: IThumbnailSegment[], duration_ms: number): IThumbnailSegment | null => {
  if (!segments.length) {
    return null;
  }
  const mid = Math.floor(segments.length / 2);
  const item = segments[mid];
  if (item.start_time <= duration_ms && item.end_time > duration_ms) {
    return item;
  }
  if (item.start_time > duration_ms) {
    return BinarySearch(segments.slice(0, mid), duration_ms);
  } else {
    return BinarySearch(segments.slice(mid + 1), duration_ms);
  }
};
var _ref = process.env.NODE_ENV === "production" ? {
  name: "sdaybv",
  styles: "z-index:1;margin-bottom:2.25rem;overflow:hidden;border-radius:0.25rem;border-width:2px;border-style:solid;--tw-border-opacity:1;border-color:rgb(255 255 255 / var(--tw-border-opacity));@media (max-width: 767px){margin-bottom:1.75rem;}"
} : {
  name: "ukbq4n-TrickPlayThumbnail",
  styles: "z-index:1;margin-bottom:2.25rem;overflow:hidden;border-radius:0.25rem;border-width:2px;border-style:solid;--tw-border-opacity:1;border-color:rgb(255 255 255 / var(--tw-border-opacity));@media (max-width: 767px){margin-bottom:1.75rem;};label:TrickPlayThumbnail;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "bjn8wh",
  styles: "position:relative"
} : {
  name: "bk4wuk-TrickPlayThumbnail",
  styles: "position:relative;label:TrickPlayThumbnail;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const TrickPlayThumbnail = () => {
  const {
    screen_isDesktop
  } = useDeviceInfo();
  const {
    isFullScreen
  } = useStreamStore(state => state);
  const {
    currentDuration,
    thumbnailResponse,
    thumbnailPrefix
  } = useTrickPlayStore();
  const [currentSegment, setCurrentSegment] = useState<IThumbnailSegment | null>(null);
  const duration_ms = Math.floor(currentDuration) * 1000;
  useEffect(() => {
    const thumbnailResponse = useTrickPlayStore.getState().thumbnailResponse;
    if (!thumbnailResponse) {
      setCurrentSegment(null);
      return;
    }
    setCurrentSegment(BinarySearch(thumbnailResponse.contents, duration_ms));
  }, [duration_ms]);
  if (!thumbnailResponse || !currentSegment) {
    return null;
  }
  const ImageH = thumbnailResponse.h || 90;
  const ImageW = thumbnailResponse.w || 160;
  const ImagePos = `-${currentSegment.x}px -${currentSegment.y}px`;
  return _jsx("div", {
    css: _ref,
    style: {
      marginBottom: screen_isDesktop ? isFullScreen ? '72px' : '36px' : '28px',
      transform: screen_isDesktop ? isFullScreen ? 'scale(2)' : 'scale(1.25)' : 'scale(1)'
    },
    children: _jsx(CacheImage, {
      css: _ref2,
      draggable: "false",
      style: {
        height: ImageH + 'px',
        width: ImageW + 'px',
        minHeight: ImageH + 'px',
        minWidth: ImageW + 'px',
        objectFit: 'none',
        objectPosition: ImagePos
      },
      src: thumbnailPrefix + currentSegment.sprite_sheet_url,
      alt: currentSegment.sprite_sheet_url || 'thumbnail.png'
    })
  });
};
export default TrickPlayThumbnail;