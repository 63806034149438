import useDelayEffect from '@hooks/useDelayEffect';
import React, { useEffect, useState } from 'react';
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const FadeEffect = ({
  isOpen,
  children
}: {
  isOpen: boolean;
  children: ({
    isVisible
  }: {
    isVisible: boolean;
  }) => React.ReactNode;
}) => {
  const [isDisplay, setIsDisplay] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    if (isOpen) {
      // Start Showing here
      setIsDisplay(true);
      setIsVisible(false);
    } else {
      // Hide starts here
      //   setIsDisplay(true)
      setIsVisible(false);
    }
  }, [isOpen]);
  useDelayEffect(() => {
    if (isOpen && isDisplay) {
      // Show visibility instant
      setIsVisible(true);
    }
  }, [isDisplay, isOpen], 50);
  useDelayEffect(() => {
    if (!isOpen && !isVisible) {
      // hide element here
      setIsDisplay(false);
    }
  }, [isVisible, isOpen], 200);
  if (!isDisplay) {
    return _jsx(_Fragment, {});
  }
  return _jsx(_Fragment, {
    children: children({
      isVisible
    })
  });
};
export default FadeEffect;