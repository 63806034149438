const getCategoryMapping = require('./categoryMapping') // only fetch data in server, client is []
// const LOCAL_MAPPING = {}

const convertCategoryIdIntoUsername = (categoryId) => {
  const ALL_CATEGORIES = getCategoryMapping()
  // if (LOCAL_MAPPING[categoryId]) {
  //   return LOCAL_MAPPING[categoryId]
  // }
  for (let category of ALL_CATEGORIES) {
    if (categoryId == category.category_id) {
      // LOCAL_MAPPING[categoryId] = category.category_name.toLowerCase()
      // return LOCAL_MAPPING[categoryId]
      return category.category_name.toLowerCase()
    }
  }
  return categoryId
}

module.exports = {
  converCategoryIdIntoUsername: convertCategoryIdIntoUsername,
}
