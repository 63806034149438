import { useTranslation } from '../i18n';
import { TAB_TITLES_MAP } from './helper';
export const useTabsTitleTranslation = () => {
  const {
    t
  } = useTranslation();
  const getTranslation = (key: string) => t(key);
  return (tab: string) => {
    const tab_lower = tab.toLowerCase();
    const i18nKey = TAB_TITLES_MAP[tab_lower];
    if (i18nKey) {
      return getTranslation(i18nKey);
    } else {
      return tab;
    }
  };
};