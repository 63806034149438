import { MINI_VIDEO_PLAYER_ID } from '@components/VideoPlayer/constants';
import React, { useEffect } from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const MoveMiniPlayer = () => {
  useEffect(() => {
    const MiniPlayerElement = (document.getElementById(MINI_VIDEO_PLAYER_ID) as HTMLDivElement);
    if (!MiniPlayerElement) return;
    const DragPosition = {
      current: {
        isClicked: false,
        currentX: 0,
        currentY: 0
      }
    };
    const handleMouseMove = (e: any) => {
      if (!DragPosition.current.isClicked) return;
      const isTouchEvent = (e.type || '').startsWith('touch');
      const position = isTouchEvent ? {
        clientX: e?.touches[0]?.pageX || e?.changedTouches[0]?.pageX,
        clientY: e?.touches[0]?.pageY || e?.changedTouches[0]?.pageY
      } : {
        clientX: e.clientX,
        clientY: e.clientY
      };
      // calculate the new cursor position:
      const diff_X = DragPosition.current.currentX - position.clientX;
      const diff_Y = DragPosition.current.currentY - position.clientY;
      DragPosition.current.currentX = position.clientX;
      DragPosition.current.currentY = position.clientY;

      // set the element's new position:
      // const { offsetLeft, offsetWidth, offsetTop, offsetHeight } = currentPortalRef
      const RightPosition = parseFloat(MiniPlayerElement.style.right); //window.innerWidth - offsetLeft - offsetWidth
      const BottomPosition = parseFloat(MiniPlayerElement.style.bottom); //window.innerHeight - offsetTop - offsetHeight

      MiniPlayerElement.style.right = RightPosition + diff_X + 'px';
      MiniPlayerElement.style.bottom = BottomPosition + diff_Y + 'px';
    };
    const handleMouseUp = () => {
      // get the mouse cursor position at startup:

      MiniPlayerElement.style.cursor = 'grab';
      MiniPlayerElement.style.transitionDuration = '300ms';
      DragPosition.current.isClicked = false;
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('touchend', handleMouseUp);
      document.removeEventListener('touchmove', handleMouseMove);
    };
    const handleMouseDown = (e: any) => {
      MiniPlayerElement.style.cursor = 'grabbing';
      MiniPlayerElement.style.transitionDuration = '0ms';
      const isTouchEvent = (e.type || '').startsWith('touch');
      const position = isTouchEvent ? {
        clientX: e?.touches[0]?.pageX || e?.changedTouches[0]?.pageX,
        clientY: e?.touches[0]?.pageY || e?.changedTouches[0]?.pageY
      } : {
        clientX: e.clientX,
        clientY: e.clientY
      };
      DragPosition.current.currentX = position.clientX;
      DragPosition.current.currentY = position.clientY;

      // get the mouse cursor position at startup:
      DragPosition.current.isClicked = true;
      document.addEventListener('mouseup', handleMouseUp);
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('touchend', handleMouseUp);
      document.addEventListener('touchmove', handleMouseMove);
    };
    MiniPlayerElement.addEventListener('touchend', handleMouseUp, {
      passive: true
    });
    MiniPlayerElement.addEventListener('mouseup', handleMouseUp);
    MiniPlayerElement.addEventListener('touchstart', handleMouseDown, {
      passive: true
    });
    MiniPlayerElement.addEventListener('mousedown', handleMouseDown);
    return () => {
      MiniPlayerElement.style.transitionDuration = '300ms';
      MiniPlayerElement.removeEventListener('touchend', handleMouseUp);
      MiniPlayerElement.removeEventListener('mouseup', handleMouseUp);
      MiniPlayerElement.removeEventListener('touchstart', handleMouseDown);
      MiniPlayerElement.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);
  return _jsx(React.Fragment, {});
};
export default MoveMiniPlayer;