import { PLAYER_TYPE } from '@components/VideoPlayer/constants';
import { usePlayerStore } from '@components/VideoPlayer/store/usePlayerStore';
import { useStreamStore } from '@stores/streamStore';
import { useEffect, useRef } from 'react';
import * as workerTimers from 'worker-timers';
import { IPlayerResponse } from './usePlayer';
const {
  setPlayerPosition,
  setPlayerDuration,
  setActivePlayerType
} = usePlayerStore.getState();
const {
  setIsPlaying
} = useStreamStore.getState();
const usePlayerPositionStuff = ({
  vodPlayerRef,
  livePlayerRef
}: {
  isFirstChunkLoaded: boolean;
  vodPlayerRef: IPlayerResponse;
  livePlayerRef: IPlayerResponse;
}) => {
  const isPlaying = useStreamStore(state => state.isPlaying);
  const {
    stream
  } = usePlayerStore(state => state.playerProps);
  const playerPosition = usePlayerStore(state => state.playerPosition);
  const playerDuration = usePlayerStore(state => state.playerDuration);
  const activePlayerType = usePlayerStore(state => state.activePlayerType);
  const isVodPlayerDisabled = usePlayerStore(state => state.isVodPlayerDisabled);
  const isLivePlayerActive = activePlayerType === PLAYER_TYPE.LIVE;
  const isLivePlayerAvailable = !livePlayerRef.error && livePlayerRef.isReady;
  const livePositionRef = useRef(livePlayerRef.position);
  const activePlayerRef = useRef(isLivePlayerActive ? livePlayerRef : vodPlayerRef);
  const inActivePlayerRef = useRef(!isLivePlayerActive ? livePlayerRef : vodPlayerRef);
  livePositionRef.current = livePlayerRef.position;
  activePlayerRef.current = isLivePlayerActive ? livePlayerRef : vodPlayerRef;
  inActivePlayerRef.current = !isLivePlayerActive ? livePlayerRef : vodPlayerRef;
  const streamRecordingStartTime = stream?.started_at;
  const isChannelLive = stream?.status === 20;
  const isPlayerDurationPresnet = isFinite(playerDuration) && playerDuration > 0;
  const actualVodPosition = vodPlayerRef.position;
  const actualVodDuration = vodPlayerRef.duration;
  useEffect(() => {
    // Handle Duration for VOD player
    let newDuration = actualVodDuration;
    const isInfiniteDuration = !isFinite(newDuration);
    if ((isInfiniteDuration || isVodPlayerDisabled) && isChannelLive && streamRecordingStartTime) {
      const rst = new Date(streamRecordingStartTime).getTime();
      const handleDuration = () => {
        newDuration = new Date().getTime() - rst;
        newDuration = Math.round(newDuration / 1000);
        setPlayerDuration(newDuration);
      };
      handleDuration();
      const timer = workerTimers.setInterval(handleDuration, 4000);
      return () => {
        workerTimers.clearInterval(timer);
      };
    } else {
      setPlayerDuration(newDuration);
    }
  }, [actualVodDuration, isChannelLive, streamRecordingStartTime, isVodPlayerDisabled]);
  useEffect(() => {
    // VOD video position
    if (isLivePlayerActive) {
      return;
    }
    const newPosition = actualVodPosition;
    setPlayerPosition(newPosition);
  }, [actualVodPosition, isLivePlayerActive]);
  useEffect(() => {
    // Check if VOD content is over & now we shift to live
    if (isLivePlayerActive || !isChannelLive || !isLivePlayerAvailable) {
      return;
    }
    const duration = usePlayerStore.getState().playerDuration;
    const isVodNearToEnd = Math.abs(duration - playerPosition) < 7;
    if (isVodNearToEnd && isChannelLive) {
      // Shift player to LIVE & return
      setActivePlayerType(PLAYER_TYPE.LIVE);
      setIsPlaying(true);
      //   here, because of isLivePlayerActive activePlayerRef is VOD & inActivePlayerRef is LIVE
      activePlayerRef.current.setPlaybackRate(1);
      activePlayerRef.current.setMuted(true);
      activePlayerRef.current.pause();

      // Handling new player audio state
      const {
        volume,
        isMuted
      } = usePlayerStore.getState();
      inActivePlayerRef.current.setVolume(volume);
      inActivePlayerRef.current.setMuted(isMuted);
      inActivePlayerRef.current.setPlaybackRate(1);
      inActivePlayerRef.current.play();
      return;
    }
  }, [actualVodPosition, isLivePlayerActive, isChannelLive, playerPosition, isLivePlayerAvailable]);
  useEffect(() => {
    // Live Channel Video Position
    if (!isLivePlayerActive) {
      return;
    }
    if (!isPlaying) return;
    // Hanlde live player timing here

    const startLivePosition = livePositionRef.current;
    let startPlayerDuration = usePlayerStore.getState().playerDuration;
    if (!isFinite(startPlayerDuration)) {
      // incase of infinite duration, set value to 0
      startPlayerDuration = 0;
    }
    const calculateLivePlayerPosition = () => {
      const currentLivePosition = livePositionRef.current;
      const differenceIsLivePlayer = currentLivePosition - startLivePosition;
      const livePlayerPosition = startPlayerDuration + differenceIsLivePlayer;
      setPlayerPosition(livePlayerPosition);
    };
    const timer = workerTimers.setInterval(calculateLivePlayerPosition, 500);
    calculateLivePlayerPosition();
    return () => {
      workerTimers.clearInterval(timer);
    };
  }, [isLivePlayerActive, isPlaying, isPlayerDurationPresnet]);
  return null;
};
export default usePlayerPositionStuff;