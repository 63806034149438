function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import React from 'react';
import PlayerSpinner from './PlayerSpinner';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1gbyw3n",
  styles: "position:absolute;z-index:-1;height:100%;width:100%;--tw-bg-opacity:1;background-color:rgb(0 0 0 / var(--tw-bg-opacity))"
} : {
  name: "1qza15-StreamStartingSoon",
  styles: "position:absolute;z-index:-1;height:100%;width:100%;--tw-bg-opacity:1;background-color:rgb(0 0 0 / var(--tw-bg-opacity));label:StreamStartingSoon;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const StreamStartingSoon = () => {
  return _jsxs(_Fragment, {
    children: [_jsx("div", {
      css: _ref
    }), _jsx(PlayerSpinner, {})]
  });
};
export default StreamStartingSoon;