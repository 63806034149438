import React from 'react';
import { PLAYER_TYPE, PLAYER_TYPE_ID } from '../constants';
import { usePlayerStore } from '../store/usePlayerStore';
import { checkIfDebugLogsNeeded } from '../Utils/utils';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const VideoElements = () => {
  const isPlayerInDebugMode = checkIfDebugLogsNeeded();
  const activePlayerType = usePlayerStore(state => state.activePlayerType);
  // const streamInfo = usePlayerStore((state) => state.playerProps.stream)
  const isLive = activePlayerType === PLAYER_TYPE.LIVE;
  // const poster = streamInfo?.thumbnail_url_small || ''
  return _jsxs(React.Fragment, {
    children: [_jsx("video", {
      // poster={(isLive && poster) || undefined}
      disableRemotePlayback: true,
      style: {
        ...(isPlayerInDebugMode ? {
          width: '50%',
          background: 'transparent',
          position: 'relative',
          border: '1px dashed ' + (isLive ? 'red' : 'blue')
        } : {}),
        pointerEvents: 'none'
      },
      id: PLAYER_TYPE_ID.LIVE,
      "data-attr-player-type": PLAYER_TYPE.LIVE,
      className: isPlayerInDebugMode ? 'loco-video active-player' : isLive ? 'loco-video active-player' : 'loco-video',
      controls: false,
      autoPlay: false,
      muted: true,
      playsInline: true
    }), _jsx("video", {
      // poster={(!isLive && poster) || undefined}
      disableRemotePlayback: true,
      style: {
        ...(isPlayerInDebugMode ? {
          width: '50%',
          background: 'transparent',
          position: 'relative',
          border: '1px dashed ' + (!isLive ? 'red' : 'blue')
        } : {}),
        pointerEvents: 'none'
      },
      id: PLAYER_TYPE_ID.VOD,
      "data-attr-player-type": PLAYER_TYPE.VOD,
      className: isPlayerInDebugMode ? 'loco-video active-player' : !isLive ? 'loco-video active-player' : 'loco-video',
      controls: false,
      autoPlay: false,
      muted: true,
      playsInline: true
    })]
  });
};
export default VideoElements;