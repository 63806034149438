function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import React from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "ecw5d0",
  styles: "--tw-rotate:180deg;transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
} : {
  name: "13yxmk8-MenuItem",
  styles: "--tw-rotate:180deg;transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));label:MenuItem;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "0",
  styles: ""
} : {
  name: "15c9hrr-MenuItem",
  styles: ";label:MenuItem;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "a4x8q1",
  styles: "display:flex;flex-direction:row;align-items:center;gap:0.25rem"
} : {
  name: "wnir9n-MenuItem",
  styles: "display:flex;flex-direction:row;align-items:center;gap:0.25rem;label:MenuItem;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "a4x8q1",
  styles: "display:flex;flex-direction:row;align-items:center;gap:0.25rem"
} : {
  name: "wnir9n-MenuItem",
  styles: "display:flex;flex-direction:row;align-items:center;gap:0.25rem;label:MenuItem;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "e16h5d",
  styles: "height:1.5rem;width:1.5rem;@media (min-width: 768px){height:1rem;width:1rem;}"
} : {
  name: "183qfjk-MenuItem",
  styles: "height:1.5rem;width:1.5rem;@media (min-width: 768px){height:1rem;width:1rem;};label:MenuItem;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const MenuItem = ({
  title,
  isSubmenuTitle,
  icon,
  value,
  hideArrow,
  showBackArrow,
  className,
  onClick,
  isSubMenu
}: {
  title: React.ReactNode;
  isSubmenuTitle?: boolean;
  onClick?: () => void;
  icon?: React.ReactNode;
  value?: React.ReactNode;
  className?: string;
  hideArrow?: boolean;
  showBackArrow?: boolean;
  isSubMenu?: boolean;
}) => {
  return _jsx(React.Fragment, {
    children: _jsxs("button", {
      className: ['setting-menu-item', className || '', isSubMenu ? 'sub-menu-item' : ''].join(' '),
      onClick: onClick,
      children: [_jsxs("span", {
        className: "setting-item-label",
        children: [showBackArrow && isSubmenuTitle ? _jsx("svg", {
          width: "18",
          height: "19",
          viewBox: "0 0 18 19",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
          children: _jsx("path", {
            d: "M11.25 4.25L6.75 9.5L11.25 14.75",
            stroke: "white",
            "stroke-width": "1.5",
            "stroke-linecap": "round",
            "stroke-linejoin": "round"
          })
        }) : showBackArrow ? _jsx("svg", {
          css: _ref,
          width: "18",
          height: "18",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
          children: _jsx("path", {
            d: "M9 5L15 12L9 19",
            stroke: "white",
            strokeWidth: "1.5",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          })
        }) : null, icon ? _jsx("span", {
          css: _ref2,
          children: icon
        }) : null, _jsx("span", {
          css: _ref3,
          className: isSubmenuTitle ? 'sub-title' : '',
          children: title
        })]
      }, "title-component"), value || !hideArrow ? _jsxs("div", {
        className: "setting-item-value",
        children: [_jsx("span", {
          css: _ref4,
          children: value || null
        }), hideArrow ? _jsx("div", {
          className: ""
        }) : _jsx("svg", {
          css: _ref5,
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
          children: _jsx("path", {
            d: "M9 5L15 12L9 19",
            stroke: "white",
            strokeWidth: "1.5",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          })
        })]
      }, "value-component") : null]
    })
  });
};
export default MenuItem;