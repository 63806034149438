import { StreamerProfileApiInterface, StreamInterface } from '@customTypes/index';
import { IchatMessage } from '@layouts/stream/components/ChatMessage';
import create, { SetState } from 'zustand';
import { ISourceTrackingIngo } from './appStore';
type IStreamStore = {
  unicornSourceTrackingInfoWhenVisitEventFired: ISourceTrackingIngo;
  setUnicornSourceTrackingInfoWhenVisitEventFired: (data: ISourceTrackingIngo) => void;
  unicornSourceTrackingInfo: ISourceTrackingIngo;
  setUnicornSourceTrackingInfo: (data: ISourceTrackingIngo) => void;
  streamerProfile: null | StreamerProfileApiInterface;
  setStreamerProfile: (streamerProfile: null | StreamerProfileApiInterface) => void;
  isRedirectUserToProfileTab: boolean;
  liveViews: number;
  setLiveViews: (value: number) => void;
  isLive: boolean;
  setIsLive: (value: boolean) => void;
  isPlaying: boolean;
  setIsPlaying: (value: boolean) => void;
  isFollowing: boolean;
  setIsFollowing: (value: boolean) => void;
  hideCommentSection: boolean;
  setHideCommentSection: (value: boolean) => void;
  isFullScreen: boolean;
  setIsFullScreen: (value: boolean) => void;
  isSendStickerModalOpen: boolean;
  onSendStickerModalOpen: () => void;
  onSendStickerModalClose: () => void;
  allStickersTabs: IStickerTab[];
  setAllStickersTabs: (value: IStickerTab[]) => void;
  allStickers: ISticker[];
  setAllStickers: (value: ISticker[]) => void;
  diamondStickers: ISticker[];
  setDiamondStickers: (value: ISticker[]) => void;
  vipStickers: ISticker[];
  setVipStickers: (value: ISticker[]) => void;
  exclusiveStickers: ISticker[];
  setExclusiveStickers: (value: ISticker[]) => void;
  streamerStickers: ISticker[];
  setStreamerStickers: (value: ISticker[]) => void;
  diamondTicker: IDiamondTicker;
  setDiamondTicker: (value: IDiamondTicker) => void;
  showGuide: boolean;
  setShowGuide: (value: boolean) => void;
  stream: StreamInterface | undefined;
  streamID: string | null;
  setStream: (value: StreamInterface | undefined) => void;
  theatreMode: boolean;
  toggleTheatreMode: (value: boolean) => void;
  showModeratorLogs: boolean;
  setShowModeratorLogs: (value: boolean) => void;
  isModerator: number;
  setIsModerator: (value: number) => void;
  isBuffering: boolean;
  setIsBuffering: (value: boolean) => void;
  playerDescriptionExpandCount: number;
  increasePlayerDescriptionExpandCount: () => void;
  resetPlayerDescriptionExpandCount: () => void;
  isBlocked: boolean;
  setIsBlocked: (value: boolean) => void;
  isMuted: number;
  setIsMuted: (value: number) => void;
  shouldUpdatePlaybackUrl: boolean;
  updatePlaybackUrl: (value: boolean) => void;
  updateHasMatureContent: (value: {
    has_mature_content: boolean;
    last_stream_config_change_time: number;
  }) => void;
  isChatTimeStamps: boolean;
  setIsChatTimeStamps: (value: boolean) => void;
  resetOnStreamChange: () => void;
};
export type IStickerTab = {
  tab_id: string;
  tab_icon: string;
  tab_icon_inactive: string;
  tab_key: number;
  tab_name: string;
  created_at: string;
  is_active: boolean;
};
export type ISticker = {
  amount: number;
  background_color: string;
  bg_attributes?: {
    border: {
      color: string;
      fill_type: string;
      gradient: {
        end_color: string;
        start_color: string;
      };
    };
    shadow: {
      color: string;
      fill_type: string;
      gradient: {
        end_color: string;
        start_color: string;
      };
    };
    content_box: {
      color: string;
      fill_type: string;
      gradient: {
        end_color: string;
        start_color: string;
      };
    };
  };
  collapse_after: number;
  created_at: string;
  currency_type: 10 | 20;
  image_url: string;
  is_active?: number;
  is_animated: boolean;
  primary_id?: string;
  sort_order?: string;
  sticker_id: string;
  tab_key: number;
  type?: string;
  name?: string;
  updated_at: string;
  sticker_property_type: string;
};
type IDiamondTicker = {
  history: IchatMessage[];
  current: IchatMessage[];
};
const store = (set: SetState<IStreamStore>, get: () => IStreamStore): IStreamStore => ({
  streamerProfile: null,
  setStreamerProfile: value => set({
    streamerProfile: value
  }),
  streamID: null,
  stream: undefined,
  setStream: value => {
    set(() => ({
      stream: value,
      streamID: value?.uid || null
    }));
    if (typeof window !== 'undefined' && value?.uid) {
      const storedPreferences = JSON.parse(localStorage.getItem('streamChatTimeStamps') || '{}');
      const streamPreference = storedPreferences[value.uid] ?? false;
      set({
        isChatTimeStamps: streamPreference
      });
    }
  },
  isLive: false,
  isFollowing: false,
  hideCommentSection: false,
  liveViews: 0,
  isRedirectUserToProfileTab: false,
  setIsLive: value => set(() => ({
    isLive: value
  })),
  setIsFollowing: value => set(() => ({
    isFollowing: value
  })),
  setHideCommentSection: value => set(() => ({
    hideCommentSection: value
  })),
  setLiveViews: value => set(() => ({
    liveViews: value
  })),
  isSendStickerModalOpen: false,
  onSendStickerModalOpen: () => set(() => ({
    isSendStickerModalOpen: true
  })),
  onSendStickerModalClose: () => set(() => ({
    isSendStickerModalOpen: false
  })),
  allStickersTabs: [],
  setAllStickersTabs: value => set(() => ({
    allStickersTabs: value
  })),
  allStickers: [],
  setAllStickers: value => set(() => ({
    allStickers: value
  })),
  diamondStickers: [],
  setDiamondStickers: value => set(() => ({
    diamondStickers: value
  })),
  vipStickers: [],
  setVipStickers: value => set(() => ({
    vipStickers: value
  })),
  exclusiveStickers: [],
  setExclusiveStickers: value => set(() => ({
    exclusiveStickers: value
  })),
  streamerStickers: [],
  setStreamerStickers: value => set(() => ({
    streamerStickers: value
  })),
  diamondTicker: {
    history: [],
    current: []
  },
  setDiamondTicker: value => set(() => ({
    diamondTicker: value
  })),
  showGuide: false,
  setShowGuide: value => set(() => ({
    showGuide: value
  })),
  theatreMode: false,
  toggleTheatreMode: value => set(() => ({
    theatreMode: value
  })),
  showModeratorLogs: true,
  setShowModeratorLogs: value => set(() => ({
    showModeratorLogs: value
  })),
  isBlocked: false,
  setIsBlocked: value => set({
    isBlocked: value
  }),
  isModerator: 0,
  setIsModerator: value => set(() => ({
    isModerator: value
  })),
  playerDescriptionExpandCount: 0,
  increasePlayerDescriptionExpandCount: () => {
    const currentValue = get()['playerDescriptionExpandCount'];
    set({
      ['playerDescriptionExpandCount']: currentValue + 1
    });
  },
  resetPlayerDescriptionExpandCount: () => {
    set({
      ['playerDescriptionExpandCount']: 0
    });
  },
  shouldUpdatePlaybackUrl: false,
  updatePlaybackUrl: value => set({
    shouldUpdatePlaybackUrl: value
  }),
  isMuted: 0,
  // this is not mute/unmute volume of player
  setIsMuted: value => set({
    isMuted: value
  }),
  // For To Update Player Events
  isBuffering: false,
  setIsBuffering: (value: boolean) => set(() => ({
    isBuffering: value
  })),
  isPlaying: false,
  setIsPlaying: value => set(() => ({
    isPlaying: value
  })),
  isFullScreen: false,
  setIsFullScreen: value => set(() => ({
    isFullScreen: value
  })),
  unicornSourceTrackingInfoWhenVisitEventFired: {},
  setUnicornSourceTrackingInfoWhenVisitEventFired: unicornSourceTrackingInfoWhenVisitEventFired => set({
    unicornSourceTrackingInfoWhenVisitEventFired
  }),
  unicornSourceTrackingInfo: {},
  setUnicornSourceTrackingInfo: unicornSourceTrackingInfo => set({
    unicornSourceTrackingInfo
  }),
  updateHasMatureContent: (value: {
    has_mature_content: boolean;
    last_stream_config_change_time: number;
  }) => set(state => ({
    stream: state.stream ? {
      ...state.stream,
      has_mature_content: value.has_mature_content,
      last_stream_config_change_time: value.last_stream_config_change_time
    } : undefined
  })),
  isChatTimeStamps: false,
  setIsChatTimeStamps: value => {
    set({
      isChatTimeStamps: value
    });
    const {
      streamID
    } = get();
    if (typeof window !== 'undefined' && streamID) {
      const storedPreferences = JSON.parse(localStorage.getItem('streamChatTimeStamps') || '{}');
      storedPreferences[streamID] = value;
      localStorage.setItem('streamChatTimeStamps', JSON.stringify(storedPreferences));
    }
  },
  // Above For To Update Player Events
  resetOnStreamChange: () => {
    set({
      isModerator: 0,
      isBlocked: false,
      showGuide: false,
      diamondTicker: {
        history: [],
        current: []
      },
      isSendStickerModalOpen: false
    });
  }
});
export const useStreamStore = create<IStreamStore>(store);