export const utmParamsMapping: Record<string, string> = {
  pl_wb: 'web',
  pl_io: 'ios',
  pl_an: 'android',
  ct_ls: 'live_stream',
  ct_vd: 'vod',
  ct_pf: 'streamer_profile',
  ct_cp: 'clips',
  ct_ct: 'categories',
  sr_da: 'dashboard'
};
const getUtmParamsFromWindow = () => {
  const params = window.location.search;
  const urlParams = new URLSearchParams(params);
  return {
    share_platform: utmParamsMapping[(urlParams.get('sr_pl') as string)],
    share_screen: utmParamsMapping[(urlParams.get('sr_sc') as string)],
    share_userid: urlParams.get('sr_id'),
    is_share: urlParams.get('sr_pl') ? true : false
  };
};
export const getUtmCampaign = () => {
  const defaultUtmParams = {
    share_platform: null,
    share_screen: null,
    share_userid: null,
    is_share: false
  };
  if (typeof window !== 'undefined') {
    // Try to read from sessionStorage first
    if (typeof sessionStorage !== 'undefined' && typeof window !== 'undefined') {
      const utmParamsFromWindow = getUtmParamsFromWindow();
      const utmParamsFromSessionStorage = JSON.parse(sessionStorage.getItem('utmParams') || '{}');
      return {
        share_platform: utmParamsFromWindow.share_platform || utmParamsFromSessionStorage.share_platform,
        share_screen: utmParamsFromWindow.share_screen || utmParamsFromSessionStorage.share_screen,
        share_userid: utmParamsFromWindow.share_userid || utmParamsFromSessionStorage.share_userid,
        is_share: utmParamsFromWindow.is_share || utmParamsFromSessionStorage.is_share || false
      };
    }
  }
  return defaultUtmParams;
};
export const setUtmCampaignInSessionStorage = () => {
  const params = getUtmParamsFromWindow();
  if (!params.share_platform && !params.share_screen && !params.share_userid) {
    return;
  }
  if (params.share_platform) {
    sessionStorage.setItem('utmParams', JSON.stringify(params));
  }
};