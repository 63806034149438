import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
interface IosStoreProps {
  width: string;
  height: string;
  className: string;
  isHovered: boolean;
}
const IosStore = (props: IosStoreProps) => {
  const {
    ...leftProps
  } = props;
  return _jsxs("svg", {
    viewBox: "0 0 32 32",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    ...leftProps,
    children: [_jsx("rect", {
      width: "32",
      height: "32",
      rx: "8",
      fill: "white"
    }), _jsx("path", {
      d: "M15.9559 7.31247L16.6257 6.1141C17.0377 5.36527 17.9571 5.11362 18.6788 5.54118C19.4004 5.96873 19.6429 6.92279 19.2309 7.67162L12.7927 19.2388H17.4497C18.9578 19.2388 19.8042 21.0785 19.1497 22.3538H5.50096C4.66984 22.3538 4 21.6587 4 20.7963C4 19.9339 4.66984 19.2388 5.50096 19.2388H9.32692L14.2265 10.4275L12.6961 7.67162C12.2841 6.92279 12.5266 5.97606 13.2482 5.54118C13.9687 5.11362 14.8811 5.36527 15.3013 6.1141L15.9559 7.31247ZM10.1651 23.9565L8.72301 26.5524C8.31098 27.3012 7.39157 27.5529 6.66994 27.1253C5.9483 26.6978 5.70579 25.7437 6.11782 24.9949L7.19262 23.0709C8.39927 22.6812 9.38578 22.9793 10.1651 23.9565ZM22.5918 19.2461H26.499C27.3302 19.2461 28 19.9412 28 20.8036C28 21.6661 27.3302 22.3612 26.499 22.3612H24.3282L25.7927 24.9949C26.2047 25.7437 25.9611 26.6892 25.2406 27.1253C24.519 27.5529 23.6066 27.3012 23.1875 26.5524C20.7224 22.1168 18.8683 18.7954 17.6405 16.5818C16.382 14.3292 17.2802 12.0693 18.1702 11.3058C19.1555 13.0625 20.6271 15.7109 22.5918 19.2461Z",
      fill: "black"
    })]
  });
};
export default IosStore;