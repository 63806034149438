import { resetAmplitudeUser } from '@modules/analytics/getAmplitude';
import wretch, { Wretcher, WretcherError } from 'wretch';
import { refreshTokenApi } from './api';
import { getAppLanguage, getAppLocale } from './getLanguageLocale';
const urlsToNotCallRefreshTokenApi = ['/user/google/signin/', '/user/signin-web', '/user/signin/', 'api/v3/sidenav', '/api/v3/sidenav/', '/report/actions', '/reward/chat/profile/v1/', '/quests/reward/profile/me/v1/', '/reward/chat/config/v1/', '/replaychat/', '/signout'];
const urlsToReplayRequest = ['/wallet/all/', '/profile/me/', '/profile/me/permissions/', '/user/me-profile', '/user/profile_info/', '/streams/playback/'];
const UNAUTHORIZE_401 = 'Unauthorized';
const FAILED_FETCH_MESSAGE = 'Failed to Fetch Api';
const TIMEOUT_MESSAGE = 'Timeout due to bad internet';
const TOO_MANY_REQUESTS_MESSAGE = 'Too many Requests. Please try after some time.';
async function handle401Error(_err: WretcherError, originalRequest: Wretcher) {
  const original_url = originalRequest?._url;
  // do not call refresh_token api for these apis
  if (urlsToNotCallRefreshTokenApi.some(url => original_url.includes(url)) || original_url.includes('/v2/streams') && (original_url.includes('/chat') ||
  // this /chat will work for send_sticker, send_message, delete_chat_by_moderater & chat_messages
  original_url.includes('/history'))) {
    // Amplitude.logEvent('logout', {
    //   forced: true,
    //   api_failed: original_url,
    //   stack: _err,
    //   reason: '401 Error',
    // })
    resetAmplitudeUser();
    // We're not refreshing token here,
    // So if 401, means user direct logout.
    return handleCommonError(401, _err, UNAUTHORIZE_401);
  }
  // Try replaying these request one time wihtout making refresh_token
  if (urlsToReplayRequest.some(url => original_url.includes(url))) {
    const originalRequestResult = await originalRequest.replay().unauthorized(async original_url_error => {
      const refreshResult = await refreshTokenApi({
        original_url
      });
      if (refreshResult?.access_token) {
        return originalRequest.auth(refreshResult.access_token).replay().unauthorized(error => handleCommonError(401, error, UNAUTHORIZE_401)).json();
      }
      return handleCommonError(401, original_url_error, UNAUTHORIZE_401);
    }).json(res => res);
    return originalRequestResult;
  }

  // call refresh api apart from about case
  const result = await refreshTokenApi({
    original_url
  });
  if (result?.access_token) {
    return originalRequest.auth(result.access_token).replay().unauthorized(newErr => {
      return handleCommonError(401, newErr, UNAUTHORIZE_401);
    }).json(res => res);
  }
  return handleCommonError(401, _err, UNAUTHORIZE_401);
}
const handleCommonError = (statusCode: number, err: WretcherError, defaultErrorMessage?: string) => {
  const default_response = {
    message: defaultErrorMessage || FAILED_FETCH_MESSAGE,
    _original_err: err,
    _parsed_err: undefined
  };
  let obj: {
    [key: string]: string | number | undefined;
  } = {};
  if (err.message) {
    try {
      const parsed_message = JSON.parse(err.message);
      if (parsed_message?.message) {
        obj = Object.assign(obj, {
          message: parsed_message.message
        });
      }
      default_response._parsed_err = parsed_message;
      if (parsed_message?.error_code) {
        obj = Object.assign(obj, {
          error_code: parsed_message.error_code
        });
      } else if (parsed_message?.code) {
        obj = Object.assign(obj, {
          error_code: parsed_message.code
        });
      }
      if (parsed_message?.yen_response) {
        // handle all possible yen error
        obj = Object.assign(obj, parsed_message.yen_response);
      }
    } catch {}
  }
  return Object.assign(default_response, obj, {
    statusCode: statusCode
  });
};
export const fetchWithAllErrorHandle = wretch().middlewares([next => (url, opts) => {
  const language = getAppLanguage();
  const locale = getAppLocale();
  const headers = {
    'X-APP-LANG': language,
    'X-APP-LOCALE': locale,
    ...(opts.headers || {}) // Merge existing headers if any
  };

  return next(url, {
    ...opts,
    headers
  });
}]).catcher(400, err => handleCommonError(400, err)).catcher(401, handle401Error).catcher(404, err => handleCommonError(404, err)).catcher(403, err => handleCommonError(403, err)).catcher(408, err => handleCommonError(408, err, TIMEOUT_MESSAGE)).catcher(418, err => handleCommonError(418, err, '418 Tea cup error')).catcher(422, err => handleCommonError(422, err)).catcher(429, err => handleCommonError(429, err, TOO_MANY_REQUESTS_MESSAGE)).catcher(500, err => handleCommonError(500, err)).catcher(502, err => handleCommonError(502, err)).catcher(503, err => handleCommonError(503, err));