const path = require('path')

const HttpBackend = require('i18next-http-backend/cjs')
const ChainedBackend = require('i18next-chained-backend').default
const LocalStorageBackend = require('i18next-localstorage-backend').default
const localePath = path.resolve(__dirname, './public/locales')

const isProd = process.env.NODE_ENV === 'production'
const isClient = typeof window !== 'undefined'
const one_minute = 60 * 1000
// Prod Cache  -> upto 10 minutes
/** @type {import('next-i18next').UserConfig} */
module.exports = {
  // debug: true,
  // browserLanguageDetection: false,
  // serverLanguageDetection: false,
  partialBundledLanguages: isClient && true,
  backend: {
    backendOptions: [
      // 3 minutes backup on prod
      // 10 second on localhost
      { expirationTime: isProd ? 10 * one_minute : 10 * 1000 },
      // 1 hour : 60 * 60 * 1000
      {
        loadPath: '/locales/{{lng}}/{{ns}}.json', // because it's in public folder
      },
    ],
    backends: isClient ? [LocalStorageBackend, HttpBackend] : [],
  },
  serializeConfig: false,
  use: isClient ? [ChainedBackend] : [],
  i18n: {
    /**
     * When **localeDetection** is set to false Next.js will no longer automatically redirect
     * based on the user's preferred locale and will only provide locale information
     * detected from either the locale based domain or locale path as described above.
     */
    localeDetection: false,
    defaultLocale: 'en',
    locales: ['en', 'pt-BR'],
  },
  fallbackLng: false, // 'en', // for testing
  localeStructure: '{{lng}}/{{ns}}',
  localeExtension: 'json',
  localePath: isProd ? localePath : './public/locales',
  // ns: ['tr_common', 'common'], // it will automatically look into locales folder & pick ns
  defaultNS: 'common',
  fallbackNS: 'common',
  load: 'currentOnly',
}
