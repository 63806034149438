import { openLoginLinkingModal } from '@components/PhoneNumberLogin';
import { HomeFeedInterface } from '@customTypes/index';
import { BotDetectionResult } from '@utils/checkIfBot';
import { DeviceInfo } from '@utils/deviceInfo';
import { LANGUAGE_PRESET } from 'src/i18n/constants_i18n';
import create, { SetState } from 'zustand';
export enum LoginScreen {
  LOGINOPTIONSSCREEN = 'LOGINOPTIONSSCREEN',
  PHONENUMBERSCREEN = 'PHONENUMBERSCREEN',
  OTPSCREEN = 'OTPSCREEN',
  OTPOPTIONSSCREEN = 'OTPOPTIONSSCREEN',
  UPDATEPROFILE = 'UPDATEPROFILE',
}
export type pageType = 'webpage_category' | 'webpage_home' | 'webpage_streamers' | 'webpage_categories' | 'webpage_livenow' | 'webpage_videos' | 'webpage_streams' | 'webpage_videos_in_focus' | 'webpage_trending_videos' | 'webpage_aboutus' | 'webpage_trending' | 'webpage_stream' | 'webpage_vod' | 'webpage_activate_tv' | 'webpage_streamer' | 'webpage_search' | 'webpage_clip' | 'webpage_trending_bgmi_videos' | 'webpage_trending_gta_v_videos' | 'webpage_trending_freefire_videos' | 'webpage_trending_valorant_videos' | 'vcsa_2023' | 'scs_2023' | 'upthrust_v2' | 'upthrust_ranbhoomi2' | 'webpage_skychampionship' | 'webpage_skychampionship_highlights' | 'webpage_skychampionship_streams' | 'webpage_skychampionship_videos' | 'web_tv_app_launch' | 'webpage_vip';
export type IPageInfo = {
  page?: pageType;
  categoryId?: string;
  categoryName?: string;
  streamerId?: string;
  streamerName?: string;
  videoId?: string;
  videoType?: 'live' | 'vod';
  categoryImage?: string;
  streamerAvatar?: string;
  streamThumbnail?: string;
  streamerType?: number;
  streamerCategory?: string;
};
export type IAutoSuggestionInfo = {
  autosuggest_shown: boolean;
  autosuggest_clicked: boolean;
  autosuggest_position: number | 'none';
  clicked_query_type: 'streamer' | 'category' | 'term';
  section_title?: string;
};
type ISearchInfo = IPageInfo & Partial<IAutoSuggestionInfo>;
export type ISourceTrackingIngo = {
  sourcePageName?: string;
  sourceSidenav?: boolean;
  sourcePageStreamerName?: string;
  sourcePageStreamerId?: string;
  sourcePageCategoryName?: string;
  sourcePageCategoryId?: string;
  sourcePageVideoId?: string;
  source_component_name?: string;
  source_component_type?: string;
  source_component_v_position?: number;
  source_component_h_position?: number;
  source_component_size?: number;
  source_component_highlighted?: boolean;
  source_position_in_source_component?: number;
  source_view_all_in_source_component?: string;
  source_ingredient_name?: string;
  source_ingredient_position?: number;
  source_ingredient_size?: number;
  source_position_in_ingredient?: number;
  is_suggested?: boolean;
  share_platform?: string | null;
  share_screen?: string | null;
  share_userid?: string | null;
  is_share?: boolean;
};
export interface IFollowing {
  user_uid: string;
  is_following: boolean;
}
type IAppStore = {
  appLanguage: LANGUAGE_PRESET | null;
  setAppLanguage: (value: LANGUAGE_PRESET) => void;
  isShowLanguageChangeModal: LANGUAGE_PRESET | null;
  setIsShowLanguageChangeModal: (value: LANGUAGE_PRESET | null) => void;
  isLanguageSelectionOpen: boolean;
  setIsLanguageSelectionOpen: (isMounted: boolean) => void;
  deviceInfo: DeviceInfo;
  setDeviceInfo: (value: DeviceInfo) => void;
  isMounted: boolean;
  setMounted: (isMounted: boolean) => void;
  initialApiLoadingState: boolean;
  botInfo: BotDetectionResult | null;
  botInfoLoading: boolean;
  pageInfo: IPageInfo;
  setInitialApiLoadingState: (value: boolean) => void;
  setPageInfo: (value: IPageInfo) => void;
  eventTrigger: string | null;
  handleEventTrigger: (value: string) => void;
  login_screen: LoginScreen;
  set_login_screen: (login_screen: LoginScreen) => void;
  isLoginModalOpen: boolean;
  onLoginModalOpen: () => void;
  onLoginModalClose: () => void;
  isSuspendedModalOpen: boolean;
  onSuspendedModalOpen: () => void;
  onSuspendedModalClose: () => void;
  isDownloadModalOpen: boolean;
  onDownloadModalOpen: () => void;
  onToggleDownloadModal: () => void;
  onDownloadModalClose: () => void;
  searchInput: string;
  searchTrigger: 'keyboard' | 'trending' | null;
  setSearchTrigger: (value: 'keyboard' | 'trending') => void;
  searchTriggerPageInfo: null | ISearchInfo;
  setSearchTriggerPageInfo: (value: ISearchInfo) => void;
  handleSearchInputChange: (value: string) => void;
  searchData: {
    [tab: string]: any;
  };
  setSearchData: (value: {
    [tab: string]: any;
  }) => void;
  homeFeedData: HomeFeedInterface | null;
  setHomeFeedData: (value: HomeFeedInterface) => void;
  sourceTrackingInfo: ISourceTrackingIngo;
  setSourceTrackingInfo: (value: ISourceTrackingIngo) => void;
  prevSourceTrackingInfo: ISourceTrackingIngo;
  setPrevSourceTrackingInfo: (value: ISourceTrackingIngo) => void;
  followingData: IFollowing[];
  setFollowingData: (value: IFollowing[]) => void;
  setBotInfo: (value: BotDetectionResult) => void;
  setBotInfoLoading: (value: boolean) => void;
  streamerMapping: {
    streamer_id: string;
    streamer_username: string;
    streamer_previous_username?: string;
    games?: string;
  }[];
  categoryMapping: {
    category_id: string;
    category_name: string;
    category_previous_name?: string;
  }[];
};
const store = (set: SetState<IAppStore>): IAppStore => ({
  appLanguage: null,
  setAppLanguage: value => set(() => ({
    appLanguage: value
  })),
  isShowLanguageChangeModal: null,
  setIsShowLanguageChangeModal: value => set(() => ({
    isShowLanguageChangeModal: value
  })),
  isLanguageSelectionOpen: false,
  setIsLanguageSelectionOpen: isLanguageSelectionOpen => set({
    isLanguageSelectionOpen
  }),
  isMounted: false,
  setMounted: isMounted => set({
    isMounted
  }),
  botInfo: null,
  botInfoLoading: false,
  setBotInfo: value => set(() => ({
    botInfo: value
  })),
  setBotInfoLoading: value => set(() => ({
    botInfoLoading: value
  })),
  initialApiLoadingState: true,
  pageInfo: ({} as IPageInfo),
  setInitialApiLoadingState: value => set(() => ({
    initialApiLoadingState: value
  })),
  setPageInfo: value => set(() => ({
    pageInfo: value
  })),
  login_screen: LoginScreen.LOGINOPTIONSSCREEN,
  set_login_screen: (login_screen: LoginScreen) => set(() => ({
    login_screen
  })),
  isLoginModalOpen: false,
  onLoginModalOpen: () => {
    // Since, we just open it, So no otpless modal as well, only choose option screen is there
    openLoginLinkingModal({
      modal_type: null
    });
    set(() => ({
      isLoginModalOpen: true
    }));
  },
  onLoginModalClose: () => {
    // No login modal, no otpless modal
    openLoginLinkingModal({
      modal_type: null
    });
    set(() => ({
      isLoginModalOpen: false
    }));
  },
  isSuspendedModalOpen: false,
  onSuspendedModalOpen: () => set(() => ({
    isSuspendedModalOpen: true
  })),
  onSuspendedModalClose: () => set(() => ({
    isSuspendedModalOpen: false
  })),
  isDownloadModalOpen: false,
  onDownloadModalOpen: () => set(() => ({
    isDownloadModalOpen: true
  })),
  onDownloadModalClose: () => set(() => ({
    isDownloadModalOpen: false
  })),
  onToggleDownloadModal: () => set(state => ({
    isDownloadModalOpen: !state.isDownloadModalOpen
  })),
  eventTrigger: null,
  handleEventTrigger: value => set(() => ({
    eventTrigger: value
  })),
  searchInput: '',
  handleSearchInputChange: value => set(() => ({
    searchInput: value
  })),
  searchData: {
    Top: [],
    Videos: [],
    Streamers: [],
    Categories: [],
    searchQuery: ''
  },
  setSearchData: value => set(() => ({
    searchData: value
  })),
  searchTrigger: null,
  setSearchTrigger: value => set(() => ({
    searchTrigger: value
  })),
  searchTriggerPageInfo: null,
  setSearchTriggerPageInfo: value => set(() => ({
    searchTriggerPageInfo: value
  })),
  homeFeedData: null,
  setHomeFeedData: value => set(() => ({
    homeFeedData: value
  })),
  sourceTrackingInfo: ({} as ISourceTrackingIngo),
  setSourceTrackingInfo: value => set(() => ({
    sourceTrackingInfo: value
  })),
  prevSourceTrackingInfo: ({} as ISourceTrackingIngo),
  setPrevSourceTrackingInfo: value => set(() => ({
    prevSourceTrackingInfo: value
  })),
  followingData: [],
  setFollowingData: value => set(() => ({
    followingData: value
  })),
  streamerMapping: [],
  categoryMapping: [],
  deviceInfo: {},
  setDeviceInfo: value => set(() => ({
    deviceInfo: value
  }))
});
export const useAppStore = create<IAppStore>(store);