function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { PLAYER_TYPE } from '@components/VideoPlayer/constants';
import { usePlayerStore } from '@components/VideoPlayer/store/usePlayerStore';
import React from 'react';
import { useTranslation } from 'src/i18n';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
interface IProps {
  onClickLive: () => void;
}
var _ref = process.env.NODE_ENV === "production" ? {
  name: "50zrmy",
  styles: "text-transform:uppercase"
} : {
  name: "10zd2e5-BackToLive",
  styles: "text-transform:uppercase;label:BackToLive;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const BackToLive = ({
  onClickLive
}: IProps) => {
  const {
    t
  } = useTranslation();
  const activePlayerType = usePlayerStore(state => state.activePlayerType);
  const isLivePlayerActive = activePlayerType === PLAYER_TYPE.LIVE;
  const live_text = t('video_player.player_controls.live_indicator');
  const go_live_text = t('video_player.player_controls.go_live');
  return _jsxs("div", {
    "aria-disabled": isLivePlayerActive,
    className: 'player-controls-btn back-to-live control-tooltip-parent ' + (isLivePlayerActive ? 'player_live' : 'player_vod'),
    children: [_jsx("span", {
      className: "control-tooltip",
      children: isLivePlayerActive ? live_text : go_live_text
    }), !isLivePlayerActive ? _jsx("button", {
      "aria-hidden": true,
      className: "live-text go-live",
      onPointerDown: onClickLive,
      children: go_live_text
    }) : _jsx("div", {
      className: "live-text",
      css: _ref,
      children: live_text
    })]
  });
};
export default BackToLive;