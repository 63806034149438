import useInfiniteScrollHook from 'react-infinite-scroll-hook';
import { IntersectionObserverHookRefCallback, IntersectionObserverHookRootRefCallback } from './types';
type NewInfiniteScrollsType = {
  loading: boolean;
  hasNextPage: boolean;
  onLoadMore: () => void;
  error?: undefined | boolean;
  rootMargin?: string;
};
function useInfiniteScroll({
  loading,
  hasNextPage,
  onLoadMore,
  error,
  rootMargin
}: NewInfiniteScrollsType): {
  ref: IntersectionObserverHookRefCallback;
  rootRef: IntersectionObserverHookRootRefCallback;
} {
  const [ref, {
    rootRef
  }] = useInfiniteScrollHook({
    loading,
    hasNextPage,
    onLoadMore: onLoadMore,
    disabled: !!error,
    rootMargin: rootMargin
  });
  return {
    ref,
    rootRef
  };
}
export default useInfiniteScroll;