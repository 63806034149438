export const TAB_TITLES_MAP: {
  [key: string]: string | undefined;
} = ({
  top: 'key_words.top',
  videos: 'key_words.videos',
  stream: 'key_words.stream',
  streamers: 'key_words.streamers',
  categories: 'key_words.categories',
  live: 'common.live_text',
  'about us': 'key_words.about_us',
  // partners: '',
  // careers: '',
  // press: '',
  daily: 'leaderboard.leaderboard_type.daily',
  weekly: 'leaderboard.leaderboard_type.weekly',
  monthly: 'leaderboard.leaderboard_type.monthly',
  'you might also like': 'stream_page.chat_container_section.you_might_also_like',
  'chat replay': 'stream_page.chat_container_section.chat_replay',
  suggested: 'stream_page.chat_container_section.suggested_text',
  leaderboard: 'vip_leaderboard.leaderboard',
  guidelines: 'vip_leaderboard.guidelines',
  details: 'vip_leaderboard.details',
  home: 'streamer_profile.profile_tabs.home',
  streams: 'streamer_profile.profile_tabs.streams',
  // videos: 'streamer_profile.profile_tabs.videos',
  // clips: 'streamer_profile.profile_tabs.clips',
  vip_leaderboard: 'streamer_profile.profile_tabs.vip_leaderboard',
  // leaderboard: 'streamer_profile.profile_tabs.leaderboard',
  profile: 'page_titles.profile',
  wallet: 'page_titles.wallet',
  'watch history': 'page_titles.watch_history',
  following: 'page_titles.following',
  achievements: 'page_titles.achievements',
  clips: 'page_titles.clips'
} as const);
export const PAGE_TITLES_MAP: {
  [key: string]: string | undefined;
} = ({
  'recent searches': 'page_titles.recent_searches',
  'trending searches': 'page_titles.trending_searches',
  'trending categories': 'page_titles.trending_categories',
  'now streaming': 'page_titles.now_streaming',
  trending: 'page_titles.trending',
  'trending videos in bgmi': 'page_titles.trending_videos_in_bgmi',
  'trending now': 'page_titles.trending_now',
  'most watched streams': 'page_titles.most_watched_streams',
  bgmi: 'page_titles.bgmi',
  'free fire': 'page_titles.free_fire',
  valorant: 'page_titles.valorant',
  'gta v': 'page_titles.gta_v',
  'trending videos': 'page_titles.trending_videos',
  videos: 'page_titles.videos',
  'gearing up for finals': 'page_titles.gearing_up_for_finals',
  'trending gta v': 'page_titles.trending_gta_v',
  'trending free fire': 'page_titles.trending_free_fire',
  'best gaming videos': 'page_titles.best_gaming_videos',
  'videos in focus': 'page_titles.videos_in_focus',
  'trending valorant videos': 'page_titles.trending_valorant_videos',
  'trending gta v videos': 'page_titles.trending_gta_v_videos',
  'trending free fire videos': 'page_titles.trending_free_fire_videos',
  'trending bgmi live streams': 'page_titles.trending_bgmi_live_streams',
  'trending free fire live streams': 'page_titles.trending_free_fire_live_streams',
  'trending bgmi videos': 'page_titles.trending_bgmi_videos',
  'rewarded streams': 'page_titles.rewarded_streams',
  'all categories': 'page_titles.all_categories',
  profile: 'page_titles.profile',
  wallet: 'page_titles.wallet',
  'watch history': 'page_titles.watch_history',
  following: 'page_titles.following',
  achievements: 'page_titles.achievements',
  clips: 'page_titles.clips',
  'recent streams': 'streamer_profile.content_sections.recent_streams.section_title',
  'latest videos': 'streamer_profile.content_sections.latest_videos.title',
  'most popular streams': 'streamer_profile.content_sections.popular_streams.title',
  'top categories': 'footer.top_categories',
  'top streamers': 'footer.top_streamers',
  'about streamer': 'streamer_profile.content_sections.about_streamer',
  'you might also like': 'stream_page.chat_container_section.you_might_also_like',
  'popular categories': 'search_modal.popular_categories',
  'see more': 'common.see_more',
  'view all': 'common.view_all'
} as const);