import { useAuthStore } from '@stores/authStore';
import { getCookie } from '@utils/cookies';
import { fetchWithAllErrorHandle } from '../../functions/apiWrapper';
import { SearchSuggestionResType, TSearchTrending } from './common/type';
const serverUrlV2 = process.env.SERVER_URL_V2;
const baseIvoryUrl = process.env.BASE_SERVER_URL;
export const getTrendingSearch = async () => {
  const Authorization = getCookie('access_token');
  //   const fingerprint = await getFingerprint()
  const result = await fetchWithAllErrorHandle.url(`${serverUrlV2}/search/?type=50&query=123&offset=0&limit=10`).headers({
    Authorization: Authorization!,
    'Content-Type': 'application/json;charset=utf-8',
    //   'DEVICE-ID': fingerprint + 'live',
    'X-PLATFORM': '7'
  }).get().json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
export const getSearchedData = async (type: number, query: string) => {
  const Authorization = getCookie('access_token');
  // const fingerprint = await getFingerprint()
  const result = await fetchWithAllErrorHandle.url(`${serverUrlV2}/search/?type=${type}&query=${query}&offset=0&limit=8`).headers({
    Authorization: Authorization!,
    'Content-Type': 'application/json;charset=utf-8',
    // 'DEVICE-ID': fingerprint + 'live',
    'X-PLATFORM': '7'
  }).get().json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
export const getAutoSuggestionData = async ({
  query,
  offset
}: {
  query: string;
  offset: string | null;
}) => {
  const Authorization = getCookie('access_token');
  // const fingerprint = await getFingerprint()
  const autoSuggestionData = await fetchWithAllErrorHandle.url(`${baseIvoryUrl}/v4/search/?type=80&query=${query}${offset ? `&offset=${offset}` : ''}`).headers({
    Authorization: Authorization!,
    'Content-Type': 'application/json;charset=utf-8',
    // 'DEVICE-ID': fingerprint + 'live',
    'X-PLATFORM': '7'
  }).get().json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  const offsetString = autoSuggestionData.next ?? '';
  const url = new URL('https://test.com' + offsetString); //using test as offsetString is an incomplete url
  const offsetValue = url.searchParams.get('offset');
  return {
    results: (autoSuggestionData.results as SearchSuggestionResType) ?? [],
    next: offsetValue
  };
};
export const getNewTrendingSearch = async ({
  offset
}: {
  offset?: string | null;
}) => {
  const Authorization = getCookie('access_token');
  // const fingerprint = await getFingerprint()
  const trendingSearchRes = await fetchWithAllErrorHandle.url(`${baseIvoryUrl}/v4/search/?type=50&query=s${offset ? `&offset=${offset}` : ''}`).headers({
    Authorization: Authorization!,
    'Content-Type': 'application/json;charset=utf-8',
    //   'DEVICE-ID': fingerprint + 'live',
    'X-PLATFORM': '7'
  }).get().json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  const offsetString = trendingSearchRes.next ?? '';
  const url = new URL('https://test.com' + offsetString);
  const offsetValue = url.searchParams.get('offset');
  return {
    results: (trendingSearchRes.results as TSearchTrending[]) ?? [],
    next: offsetValue
  };
};
export const getUserSearchActivity = async () => {
  const Authorization = getCookie('access_token');
  const isUserPresent = !!useAuthStore.getState().me?.user_uid;
  if (!isUserPresent) return {
    results: [],
    next: null
  };
  // const fingerprint = await getFingerprint()
  const res = await fetchWithAllErrorHandle.url(`${baseIvoryUrl}/v4/search/user/activity/`).headers({
    Authorization: Authorization!,
    'Content-Type': 'application/json;charset=utf-8',
    //   'DEVICE-ID': fingerprint + 'live',
    'X-PLATFORM': '7'
  }).get().json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return {
    results: (res.results as TSearchTrending[]) ?? [],
    next: res.next
  };
};
export const postUserSearchActivity = async ({
  type,
  search_query
}: {
  type: string;
  search_query: string;
}) => {
  const Authorization = getCookie('access_token');
  const isUserPresent = !!useAuthStore.getState().me?.user_uid;
  if (!isUserPresent) return {
    results: [],
    next: null
  };
  // const fingerprint = await getFingerprint()
  const res = await fetchWithAllErrorHandle.url(`${baseIvoryUrl}/v4/search/user/activity/`).headers({
    Authorization: Authorization!,
    'Content-Type': 'application/json;charset=utf-8',
    //   'DEVICE-ID': fingerprint + 'live',
    'X-PLATFORM': '7'
  }).post({
    type,
    search_query
  }).json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return res;
};
export const deleteUserSearchActivity = async ({
  type,
  search_query
}: {
  type: string;
  search_query: string;
}) => {
  const Authorization = getCookie('access_token');
  // const fingerprint = await getFingerprint()
  const res = await fetchWithAllErrorHandle.url(`${baseIvoryUrl}/v4/search/user/activity/delete/`).headers({
    Authorization: Authorization!,
    'Content-Type': 'application/json;charset=utf-8',
    //   'DEVICE-ID': fingerprint + 'live',
    'X-PLATFORM': '7'
  }).post({
    type,
    search_query
  }).json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return res;
};